<template>
  <div class="page-blanc">
    <img class="center-shape" src="../images/shapes/shape-center.png" />
    
    <img 
    class="logo-enffic-pdf"
    src="../images/logo-eneffic.png"
    />
    <p class="page-title">
      <span>Synthèse de financière de mon projet</span>
    </p>

    <div class="content">
      <div class="section-header">
        <div class="icon-wrapper">
          <img src="../images/icons/money-bg.png" />
        </div>
        <p class="section-title">Gains apportés par votre projet</p>
      </div>
      <div class="chart-wrapper">
        <VueApexCharts 
        type="bar" 
        height="350px" 
        :options="gainsChartOptions" 
        :series="gainsChartSeries">
        </VueApexCharts>
      </div>
      <div class="section-header">
        <div class="icon-wrapper">
          <img src="../images/icons/budget-bg.png" />
        </div>
        <p class="section-title">Temps d'amortissement de votre projet</p>
      </div>
      <div class="chart-wrapper">
        <VueApexCharts 
        type="bar" 
        height="350px" 
        :options="amortissementChartOptions" 
        :series="amortissementChartSeries">
        </VueApexCharts>
      </div>
    </div>
    <div class="footer">
      <ul>
        <li>ENEFFIC - 2 Aliée du Levant 34970 Lattes</li>
        <li>Téléphone: +33 (0)4 65 84 38 76 - E-mail: contact@eneffic.fr</li>
        <li>SIREN: 951 979 798</li>
      </ul>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "SyntheseFinanciereChart",
  components: {
    VueApexCharts,
  },
  props: ["seriesGains","seriesAmortissement"],
  watch: {
    seriesGains: {
      handler(newVal) {
        if (newVal) {
          this.gainsChartSeries = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
    seriesAmortissement: {
      handler(newVal) {
        if (newVal) {
          this.amortissementChartSeries = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      gainsChartSeries: [{
        name: 'ECONOMIES SUR FACTURES',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
      }, {
        name: 'VENTE DE SURPLUS',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
      }],
      gainsChartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
          },
          election: {
            enabled: false,
          }
        },
        colors: ['#00f7bd', '#14DAF5'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          type: "numeric",
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(2) + " €";
            }
          }
        },
        fill: {
          opacity: 1
        },
      },


      amortissementChartSeries: [{
        name: 'Amortissement',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
      }],
      amortissementChartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
          },
          election: {
            enabled: false,
          }
        },
        colors: ['#14DAF5', '#00f7bd'],
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: -100,
                to: -46,
              }, {
                from: -45,
                to: 0,
              }]
            },
            columnWidth: '80%',
          }
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(2);
            }
          }
        },
        xaxis: {
          type: "numeric",
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            }
          }
        }
      },
    }
  },
};
</script>

<style lang="scss" scoped>
.page-blanc{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 32px;

  .center-shape{
    position: absolute;
    width: 450px;
    height: 450px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .logo-enffic-pdf{
    height: 24px;
    margin-top: 40px;
    margin-left: 40px;
  }
  
  .page-title{
    width: fit-content;
    position: relative;
    font-size: 18pt;
    font-weight: 600;
    margin: 20px auto 60px;
    span{
      position: relative;
    }
    &::before{
      content: "";
      position: absolute;
      display: block;
      width: 50%;
      height: 16px;
      top: 60%;
      left: 0;
      background: #00f7bd;
    }
  }

  .content{
    position: relative;
    width: 100%;
    padding: 0px 15px;

    .section-header{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0 40px;
      .icon-wrapper{
        width: 85px;
        height: 85px;
        background-color: #00f7bd;
        margin-bottom: 20px;
        margin-right: 20px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .section-title{
      font-size: 16pt;
      font-weight: 600;
      color: #03396d;
      text-align: left;
    }
    .chart-wrapper{
      background: #fff;
      margin-bottom: 40px;
    }
  }
  .footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 2;
    font-size: 10pt;
    color: #14324F;
    font-weight: 400;
    text-align: center;
    ul{
      list-style-type: none;
    }
  }
}
</style>