<template>
  <v-card style="box-shadow: none">
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <div class="ma-3">
          <h5 class="mb-1 ms-3 text-end">{{ titre }}</h5>
          <p
            class="mb-2 pb-1 ms-3 my-2 text-end"
            :style="{ color: $colors[0], fontSize: 'xx-large' }"
          >
            {{
              Number(
                valueMesure == "consommationSolaire"
                  ? allDataCourbe.total_value
                  : allDataCourbe[valueMesure].total
              ).toLocaleString("fr-FR")
            }}
            <span>Kwh</span>
          </p>
          <v-row class="rounded-3 mx-1" style="background-color: #f7f7f7">
            <v-col
              cols="4"
              class="pa-2 text-center"
              v-if="
                PVGISdata.parametreCompteur == 'C4' ||
                PVGISdata.parametreCompteur == 'C5C4' ||
                PVGISdata.parametreCompteur == 'C3'
              "
            >
              <p class="small text-muted mb-1">HPH</p>
              <p class="mb-0">
                {{
                  Number(
                    allDataCourbe[valueMesure].hph != null
                      ? allDataCourbe[valueMesure].hph
                      : allDataCourbe[valueMesure].hph
                  ).toLocaleString("fr-FR")
                }}
              </p>
            </v-col>
            <v-col
              cols="4"
              class="pa-2 text-center"
              v-if="PVGISdata.parametreCompteur == 'C5HP'"
            >
              <p class="small text-muted mb-1">HP</p>
              <p class="mb-0">
                {{
                  Number(
                    allDataCourbe[valueMesure].hp != null
                      ? allDataCourbe[valueMesure].hp
                      : allDataCourbe[valueMesure].hp
                  ).toLocaleString("fr-FR")
                }}
              </p>
            </v-col>
            <v-col
              cols="4"
              class="pa-2 text-center"
              v-if="PVGISdata.parametreCompteur == 'C5BASE'"
            >
              <p class="small text-muted mb-1">BASE</p>
              <p class="mb-0">
                {{
                  Number(
                    allDataCourbe[valueMesure].base != null
                      ? allDataCourbe[valueMesure].base
                      : allDataCourbe[valueMesure].base
                  ).toLocaleString("fr-FR")
                }}
              </p>
            </v-col>

            <v-col
              cols="4"
              class="pa-2 text-center"
              v-if="PVGISdata.parametreCompteur == 'C5HP'"
            >
              <p class="small text-muted mb-1">HC</p>
              <p class="mb-0">
                {{
                  Number(
                    valueMesure == "consommationSolaire"
                      ? allDataCourbe[valueMesure].HC
                      : allDataCourbe[valueMesure].hc
                  ).toLocaleString("fr-FR")
                }}
              </p>
            </v-col>

            <v-col
              cols="4"
              class="pa-2 text-center"
              v-if="
                PVGISdata.parametreCompteur == 'C4' ||
                PVGISdata.parametreCompteur == 'C5C4' ||
                PVGISdata.parametreCompteur == 'C3'
              "
            >
              <p class="small text-muted mb-1">HCH</p>
              <p class="mb-0">
                {{
                  Number(
                    allDataCourbe[valueMesure].hch != null
                      ? allDataCourbe[valueMesure].hch
                      : allDataCourbe[valueMesure].hch
                  ).toLocaleString("fr-FR")
                }}
              </p>
            </v-col>

            <v-col
              cols="4"
              class="pa-2 text-center"
              v-if="
                PVGISdata.parametreCompteur == 'C4' ||
                PVGISdata.parametreCompteur == 'C5C4' ||
                PVGISdata.parametreCompteur == 'C3'
              "
            >
              <p class="small text-muted mb-1">HPE</p>
              <p class="mb-0">
                {{
                  Number(
                    allDataCourbe[valueMesure].hpe != null
                      ? allDataCourbe[valueMesure].hpe
                      : allDataCourbe[valueMesure].hpe
                  ).toLocaleString("fr-FR")
                }}
              </p>
            </v-col>

            <v-col
              cols="4"
              class="pa-2 text-center"
              v-if="
                PVGISdata.parametreCompteur == 'C4' ||
                PVGISdata.parametreCompteur == 'C5C4' ||
                PVGISdata.parametreCompteur == 'C3'
              "
            >
              <p class="small text-muted mb-1">HCE</p>
              <p class="mb-0">
                {{
                  Number(
                    allDataCourbe[valueMesure].hce != null
                      ? allDataCourbe[valueMesure].hce
                      : allDataCourbe[valueMesure].hce
                  ).toLocaleString("fr-FR")
                }}
              </p>
            </v-col>

            <v-col
              cols="4"
              class="pa-2 text-center"
              v-if="PVGISdata.parametreCompteur == 'C3'"
            >
              <p class="small text-muted mb-1">POINTE</p>
              <p class="mb-0">
                {{
                  Number(
                    allDataCourbe[valueMesure].pointe != null
                      ? allDataCourbe[valueMesure].pointe
                      : allDataCourbe[valueMesure].pointe
                  ).toLocaleString("fr-FR")
                }}
              </p>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: "cardMesures",
  props: ["allDataCourbe", "titre", "valueMesure", "PVGISdata"],
};
</script>
