<template>
  <div class="card mb-3">
    <div class="card-header">
      <div class="card-title">
        <h5>Liste des visites techniques</h5>
      </div>
    </div>
    <div class="card-body">
      <datatable
        id="table-validation-cotation"
        :columns="columns"
        :sortKey="sortKey"
        :sortOrders="sortOrders"
        @sort="sortBy"
        @search="rechercheData"
      >
        <template v-slot:header>
          <div style="width: 200px" class="float-end container-div mr-2">
            <select
              v-model="filterStatus"
              class="form-control"
              id="sel1"
              @change="filterByStatus"
            >
              <option value="tout">Toutes les demandes</option>
              <option value="1">acceptée</option>
              <option value="0">en attente</option>
              <option value="2">refusée</option>
            </select>
          </div>
        </template>
        <template v-slot:body>
          <tbody class="text-center" v-if="rowData.length > 0 && loadingTable == false">
            <tr v-for="row in rowData" :key="row?.id">
              <td>
                <router-link :to="`/visite-technique/profil/${row?.id}`">
                  {{ row?.title }}
                </router-link>
              </td>
              <td>{{ row?.dateDebut }}</td>
              <td>{{ row?.dateFin }}</td>
              <td>{{ row?.timeStart }}</td>
              <td>{{ row?.timeEnd }}</td>
              <td>
                <v-tooltip v-if="row?.description.length >= 20" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <small class="td-table" v-bind="attrs" v-on="on">
                      {{ row?.description.substring(0, 20) + "..." }}
                    </small>
                  </template>
                  <span>{{ row?.description.substring(0, 80) }}</span>
                </v-tooltip>
                <small v-else class="td-table">{{
                  row?.description.substring(0, 40)
                }}</small>
              </td>
              <td>{{ row?.technician?.nom }}</td>

              <td>
                <div class="d-flex flex-column btn-group">
                  <div class="d-flex flex-row">
                    <small class="small-status" v-if="row?.etat == 'refusee'"
                      ><v-icon dense style="color: #ff0000">mdi-close-circle</v-icon
                      ><b class="ml-2">refusée</b></small
                    >
                    <small class="small-status" v-if="row?.etat == 'annuler'"
                      ><v-icon dense style="color: #ff0000">mdi-cancel</v-icon
                      ><b class="ml-2">annulé</b></small
                    >
                    <small class="small-status" v-if="row?.etat == 'en_attend'"
                      ><v-icon dense style="color: #2080ee">mdi-clock-outline</v-icon
                      ><b class="ml-2">en attente</b></small
                    >
                    <small class="small-status" v-if="row?.etat == 'en_cours'"
                      ><v-icon dense style="color: #ffa500">mdi-progress-clock</v-icon
                      ><b class="ml-2">en cours</b></small
                    >
                    <small class="small-status" v-if="row?.etat == 'terminer'"
                      ><v-icon dense style="color: #00e676"
                        >mdi-check-circle-outline</v-icon
                      ><b class="ml-2">Terminé</b></small
                    >
                    <small class="small-status" v-if="row?.etat == 'pre_valider'"
                      ><v-icon dense style="color: #9c27b0">mdi-eye-check</v-icon
                      ><b class="ml-2">pré validé</b></small
                    >
                    <small class="small-status" v-if="row?.etat == 'valider'"
                      ><v-icon dense style="color: #00e676"
                        >mdi-checkbox-marked-circle-auto-outline</v-icon
                      ><b class="ml-2" style="word-wrap: normal">validé</b></small
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <noDataTable class="ma-4" v-if="rowData.length == 0 && loadingTable == false" />
        </template>
      </datatable>
      <div
        class="d-flex flex-row justify-content-center justify-sm-start align-items-center"
      >
        <pagination
          v-if="rowData.length > 0"
          :pagination="pagination"
          @selectNumberOfRecords="rechercheData"
          @first="getData(pagination.firstPage)"
          @last="getData(pagination.lastPage)"
          @prev="getData(pagination.prevPageUrl)"
          @current="getData(pagination.currentPage)"
          @next="getData(pagination.nextPageUrl)"
        >
        </pagination>
      </div>
    </div>
    <!-- END DATA TABLE FOR VISITE -->
  </div>
</template>

<script>
import axios from "@/plugins/axios";
//import LineChart from "./chartComponent/LineChart.vue";
import Datatable from "../../../components/dataTable/Datatable.vue";
import Pagination from "../../../components/dataTable/Pagination.vue";
import noDataTable from "../../../components/dataTable/no-data-table.vue";
import progressCircular from "../../../components/dataTable/progress-circular.vue";

export default {
  components: {
    datatable: Datatable,
    pagination: Pagination,
    noDataTable,
    progressCircular,
  },
  name: "VisiteTechniqueSociete",
  props: ["id"],
  data() {
    let sortOrders = {};
    let columns = [
      { width: "12vw", label: "Visite Technique", name: "vt", sortable: true },
      { width: "12vw", label: "Date Debut", name: "dateDebut", sortable: true },
      { width: "12vw", label: "Date Fin", name: "dateFin", sortable: true },
      { width: "12vw", label: "Heure de Debut", sortable: true },
      { width: "12vw", label: "Heure de Fin", sortable: true },
      { width: "12vw", label: "Description", sortable: true },
      { width: "12vw", label: "Technicien", name: "tech", sortable: true },
      { width: "12vw", label: "Statut", name: "etat", sortable: true },
    ];

    // columns names
    columns.forEach((key) => {
      sortOrders[key.name] = -1;
    });

    return {
      loadingTable: false,

      columns: columns,
      filterStatus: "tout",

      sortKey: "date",

      sortOrders: sortOrders,

      // dataTable
      dataTable: {
        statusFilter: "",
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
      },
      // pagination
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      rowData: [],
      loaded: false,
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    filterByStatus() {},
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    // get Cotation data
    async getData(pageNumber = 1) {
      this.dataTable.draw++;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getVisiteTechniqueBySocieteId",
        method: "POST",
        data: {
          id: this.id,
          token: this.$cookies.get("token"),
          pageNumber: pageNumber,
          dataTable: this.dataTable,
        },
      })
        .then((response) => {
          
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.dataTable.draw == data.draw) {
              this.rowData = data.data.data;
              this.configPagination(data.data);
              console.log("rowData", this.rowData);
            }
          }
        })
        .catch((error) => {
          
        });
    },
    // recherche cotation data
    rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 1;
        this.dataTable.search = key;
      } else if (type == "nbrRecords") {
        this.dataTable.length = key;
      }
      this.dataTable.draw++;
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getVisiteTechniqueBySocieteId",
        method: "POST",
        data: {
          id: this.id,
          token: this.$cookies.get("token"),
          pageNumber: pageNumber,
          dataTable: this.dataTable,
        },
      })
        .then((response) => {
          
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.dataTable.draw == data.draw) {
              this.rowData = data.data.data;
              this.configPagination(data.data);
              console.log("rowData", this.rowData);
            }
          }
        })
        .catch((error) => {
          
        });
    },
    // sort by
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.dataTable.column = this.getIndex(this.columns, "name", key);
      this.dataTable.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getData();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
  },
};
</script>

<style scoped>
.breadcrumb {
  background-color: #ffffff !important;
}

.containe_comp {
  background-color: #f2f2f2 !important;
  border-radius: 10px;
  padding: 50px 70px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: "tnum";
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
}

.body-header {
  color: #192946;
}

.icon {
  width: 58px;
  height: 58px;
  top: 0;
  bottom: 0;
  right: 16px;
  margin: auto;
  background: linear-gradient(45deg, rgb(0, 121, 145) 0%, rgb(24, 174, 162) 68%)
    rgb(0, 121, 145);
  border-radius: 7px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.12);
  justify-content: center;
  align-items: center;
  padding: 1px 5px;
}

.text-button {
  color: #192946;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 10px;
}

.text-button:hover {
  color: #1e3766;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 10px;
  text-decoration: underline;
}

.bgc-primary {
  background-color: #396c99;
}

.bgc-primary:hover {
  background-color: #1e4c66;
}

.bgc-success {
  background-color: #2fb8ac;
}

.bgc-warning {
  background-color: #868685;
}

.card-title {
  padding: 0.45rem 0.825rem;
  margin-bottom: 10;
  background-color: #ffffff;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.02em;
  font-size: 1.25rem;
  color: #1d3258;
}

.card-header {
  background-color: #ffffff !important;
  border-bottom: 1px solid #e6e6f2 !important;
}

#table-validation-cotation th {
  background-color: #103a5e;
  color: white;
  font-weight: bold;
}

.v-application ul {
  padding-left: 10px;
}

.info-title {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}

@media only screen and (max-width: 400px) {
  .container-div {
    width: 125px !important;
    padding: 0px;
    margin: 0;
  }
}

.small-status {
  width: max-content;
}
</style>
