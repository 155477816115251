<template>
  <div class="mx-auto">
    <venteForm
      :Popup="venteForm"
      :compteurProp="PVGISdata"
      :updatePrixProp="PVGISdata.lesPrix"
      @closePopup="handleCloseEmit()"
      @updateListPrix="handleListUpdatePrix($event)"
    />
    <v-row v-if="loading || errorModal">
      <loadingComponent
        @closeDialog="closeLoading($event)"
        :loadingProps="loading"
        :messageDialogeProps="messageDialoge"
        :dialogProps="dialog"
        :errorProps="errorModal"
      />
    </v-row>
    <div style="width: 100%" class="d-flex justify-center mb-2 mt-5">
      <v-card width="100%" class="ma-5 pa-4 pt-0">
        <v-form ref="formGis">
          <v-row class="pb-4 pt-0 mt-0 px-0">
            <titleComponent
              style="width: 100%"
              :color="$colors[0]"
              title="Informations Société"
              icon="mdi-domain"
            />
            <v-col class="pb-0 pr-0" cols="12" sm="12" md="12" lg="12" xl="12">
              <div class="d-flex flex-row">
                <v-form style="width: 50%" ref="formSiret" class="d-flex flex-row">
                  <v-combobox
                    dense
                    :search-input.sync="PVGISdata.PDL"
                    v-model="PVGISdata.PDL"
                    outlined
                    hide-details="auto"
                    :items="listCompteur"
                    :rules="[$rules.required('un PDL valide')]"
                    @update:search-input="getPropositionCompteur()"
                    label="PDL "
                  ></v-combobox>
                  <!-- icon button -->
                  <v-btn
                    class="ml-4"
                    outlined
                    @click="getAdressePDL()"
                    style="border: solid 2px #8aa7b4"
                  >
                    <v-icon :color="$colors[0]">mdi-map-marker-outline</v-icon>
                  </v-btn>
                </v-form>
                <v-btn
                  class="ml-4"
                  v-if="(PVGISdata.Latitude && PVGISdata.Longitude) != ''"
                  style="color: rgb(233, 245, 255)"
                  @click="venteForm = true"
                  :style="{ backgroundColor: $colors[0] }"
                >
                  <span>Fournisseur</span>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col class="pb-0 pr-0" cols="12" sm="6" md="6" lg="6" xl="6">
              <div class="d-flex flex-row">
                <v-text-field
                  outlined
                  dense
                  label="Nom d'installation"
                  v-model="nom_installation"
                >
                </v-text-field>
              </div>
            </v-col>
            <template v-if="showAddressInput">
              <v-col class="pb-0 pr-0" cols="12" sm="12" md="12" lg="12" xl="12">
                <div style="width: 50%" class="d-flex flex-row">
                  <v-text-field
                    outlined
                    dense
                    label="Adresse"
                    :rules="[$rules.required('Adresse')]"
                    v-model="PVGISdata.adresse"
                  >
                    <template v-slot:append>
                      <v-icon :color="$colors[0]">mdi-map-marker-radius-outline</v-icon>
                    </template>
                  </v-text-field>
                  <v-btn
                    class="ml-4"
                    outlined
                    @click="getPosition"
                    style="border: solid 2px #8aa7b4"
                  >
                    <v-icon :color="$colors[0]">mdi-map-marker-outline</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <!-- icon button -->
            </template>
          </v-row>

          
          <div v-if="showAddressInput" class="pt-4">
            <template v-if="roleUser == 'admin'">
              <v-checkbox 
              v-model="showAdminPrix" 
              label="Afficher Formulaire de prix"
              ></v-checkbox>
              <v-row v-if="showAdminPrix">
                <v-col class="mb-4" cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-row class="px-0">
                    <titleComponent
                      style="width: 100%"
                      :color="$colors[0]"
                      title="Prix"
                      icon="mdi-calculator-variant-outline"
                    />
                    <v-col cols="12">
                      <h6 class="pl-4 Poppins-Bold" :style="{ color: $colors[1] }">
                        Prix de Vente
                      </h6>
                      <v-row class="px-4">
                        <v-col style="height: fit-content" class="pb-0" cols="12" sm="12" md="4" lg="4" xl="4">
                          <v-text-field
                            outlined
                            dense
                            hide-details="auto"
                            label="Prix vente total"
                            v-model="prix_vente_installation.prix_total"
                            :rules="[$rules.required('prix vente total'), $rules.isFloat]"
                          >
                            <template v-slot:append>
                              <v-icon :color="$colors[0]">mdi-angle-acute</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col style="height: fit-content" class="pb-0" cols="12" sm="12" md="4" lg="4" xl="4">
                          <v-text-field
                            outlined
                            dense
                            hide-details="auto"
                            label="Prix vente auto"
                            v-model="prix_vente_installation.prix_auto"
                            :rules="[$rules.required('prix vente auto'), $rules.isFloat]"
                          >
                            <template v-slot:append>
                              <v-icon :color="$colors[0]">mdi-angle-acute</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <h6 class="pl-4 Poppins-Bold" :style="{ color: $colors[1] }">
                        Prix d'installations
                      </h6>
                      <v-row class="px-4">
                        <v-col style="height: fit-content" class="pb-0" cols="12" sm="12" md="4" lg="4" xl="4">
                          <v-text-field
                            outlined
                            dense
                            hide-details="auto"
                            label="Prix d'installations"
                            v-model="prix_vente_installation.prix_installation"
                            :rules="[$rules.required(`Prix d'installations`), $rules.isFloat]"
                          >
                            <template v-slot:append>
                              <v-icon :color="$colors[0]">mdi-angle-acute</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>

            <template v-if="showCordonnerInput">
              <v-row class="py-4 px-0 pb-0">
                <titleComponent
                  style="width: 100%"
                  :color="$colors[0]"
                  title="Point Géographique"
                  icon="mdi-earth"
                />
                <v-col class="pb-0 pr-0" cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    outlined
                    dense
                    label="Latitude"
                    hide-details="auto"
                    :rules="[$rules.required('une Latitude valide'), $rules.isFloat]"
                    v-model="PVGISdata.Latitude"
                  >
                    <template v-slot:append>
                      <v-icon :color="$colors[0]">mdi-latitude</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col class="pb-0" cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    outlined
                    dense
                    label="Longitude"
                    :rules="[$rules.required('une Longitude valide'), $rules.isFloat]"
                    v-model="PVGISdata.Longitude"
                  >
                    <template v-slot:append>
                      <v-icon :color="$colors[0]">mdi-longitude</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-if="showCordonnerInput">
              <v-row class="pa-4 pt-2">
                <v-col class="pa-0" cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    min="1"
                    max="3"
                    step="1"
                    label="N° installation"
                    :rules="[
                      $rules.required('nombre valide'),
                      $rules.isNumber,
                      $rules.rangeValideValue(PVGISdata.nbrInstallation, 1, 3),
                    ]"
                    v-model="PVGISdata.nbrInstallation"
                    @change="changeTailleInstallation"
                  >
                    <template v-slot:append>
                      <v-icon :color="$colors[0]">mdi-toolbox-outline</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </template>

            <template v-if="showInfoInput">
              <v-row>
                <v-col class="mt-4" cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-row class="py-4 px-0">
                    <titleComponent
                      v-if="PVGISdata.Installations.length > 0"
                      style="width: 100%"
                      :color="$colors[0]"
                      title="Calcul"
                      icon="mdi-calculator-variant-outline"
                    />
                    <v-row
                      class="px-4"
                      v-for="(item, index) in PVGISdata.Installations"
                      :key="'installation' + index"
                    >
                      <h6 class="pl-4 Poppins-Bold" :style="{ color: $colors[1] }">
                        INSTALLATION N° {{ index + 1 }}
                      </h6>
                      <v-col class="py-0 pr-0" cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-radio-group
                          class="mt-2"
                          :style="{ color: $colors[0] }"
                          :rules="[$rules.required('Calculez les angles')]"
                          v-model="PVGISdata.Installations[index].angleOption"
                          row
                          ><template v-slot:label
                            ><span :style="{ color: $colors[0] }"
                              >Calculez les angles d'inclinaison et d'orientation
                              optimaux</span
                            ></template
                          >
                          <v-radio :color="$colors[0]" label="oui" value="oui" />
                          <v-radio :color="$colors[0]" label="non" value="non" />
                        </v-radio-group>
                      </v-col>
                      <v-col
                        style="height: fit-content"
                        class="pb-0"
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        v-if="PVGISdata.Installations[index].angleOption == 'non'"
                      >
                        <v-text-field
                          outlined
                          dense
                          hide-details="auto"
                          label="inclinaison"
                          type="number"
                          v-model="PVGISdata.Installations[index].incliner"
                          :rules="[$rules.requiredNumber('inclinée')]"
                        >
                          <template v-slot:append>
                            <v-icon :color="$colors[0]">mdi-angle-acute</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col
                        style="height: fit-content"
                        class="pb-0"
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          label="Angle d'orientation du système PV fixe (0 - 360°)"
                          v-model="PVGISdata.Installations[index].orientation"
                          :rules="[$rules.requiredNumber('Angle d orientation'), rules.validateOrientation]"
                        >
                          <template v-slot:append>
                            <v-icon :color="$colors[0]">mdi-ruler-square-compass</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col
                        style="height: fit-content"
                        class="pb-0"
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <v-text-field
                          outlined
                          dense
                          label="Taille d'installation"
                          v-model="PVGISdata.Installations[index].tailleInstalation"
                          :rules="[
                            $rules.required('taille installation'),
                            $rules.isFloat,
                          ]"
                          ><template v-slot:append>
                            <span class="Poppins-Bold mt-1" :style="{ color: $colors[0] }"
                              >m²</span
                            >
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col
                        style="height: fit-content"
                        class="pt-0"
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <v-text-field
                          outlined
                          dense
                          label="Nombre des panneaux"
                          type="number"
                          :style="{
                            paddingTop: `${
                              PVGISdata.Installations[index].angleOption == 'oui'
                                ? '12px'
                                : '0px'
                            }`,
                          }"
                          v-model="PVGISdata.Installations[index].nbrPanaux"
                          :rules="[
                            $rules.required('Nombre des panneaux'),
                            $rules.isNumber,
                          ]"
                        >
                          <template v-slot:append>
                            <v-icon :color="$colors[0]">mdi-counter</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col
                        style="height: fit-content"
                        class="pt-0"
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <v-text-field
                          outlined
                          dense
                          label="Pourcentage de perte"
                          v-model="PVGISdata.Installations[index].loss"
                          :rules="[
                            $rules.requiredNumber('un pourcentage de perte valide'),
                           
                            rules.validatePercentage
                          ]"
                          ><template v-slot:append>
                            <span class="Poppins-Bold mt-1" :style="{ color: $colors[0] }"
                              >%</span
                            >
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="pt-0"
                        style="padding-bottom: 2px !important; height: fit-content"
                      >
                        <v-text-field
                          outlined
                          dense
                          label="Puissance de crête"
                          :rules="[
                            $rules.required('Puissance de crête'),
                            $rules.isFloat,
                            rules.validatePuissance,
                          ]"
                          v-model="PVGISdata.Installations[index].puissanceKWc"
                        >
                          <template v-slot:append>
                            <v-icon :color="$colors[0]">mdi-solar-power</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-row>
                  <!--<v-row>
                    <titleComponent
                      style="width: 100%"
                      :color="$colors[0]"
                      title="Presentation"
                      icon="mdi-presentation"
                    />
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <div
                        class="d-flex flex-row align-items-center"
                        :style="{ color: $colors[0], fontSize: 'small' }"
                      >
                        <span class="mr-4" style="font-size: 15px">Format : </span>
                        <div class="d-flex flex-column justify-center align-items-center">
                          <div class="toggleContainer">
                            <label class="toggleSwitch nolabel mb-0">
                              <input type="checkbox" @click="checkDaily" />
                              <a></a>
                              <span>
                                <span class="right-span">Auto</span>
                                <span class="left-span">Total</span>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>-->
                </v-col>
              </v-row>
            </template>
            <v-row v-if="!!PVGISdata.lesPrix.ABO && listupdatePrix != null">
              <v-col class="px-0 pt-4" cols="12" sm="12" md="12" lg="12" xl="12">
                <!--v-expansion panel-->
                <v-expansion-panels flat :style="{ border: `2px solid ${$colors[0]}` }">
                  <v-expansion-panel v-if="listupdatePrix != null">
                    <v-expansion-panel-header
                      class="px-2 py-0"
                      :style="{ background: $colors[0] }"
                    >
                      <v-toolbar elevation="0" :style="{ background: $colors[0] }" dark>
                        <span class="pl-0 Poppins-Bold">Prix d'achat</span>
                        <v-btn
                          small
                          :style="{ border: `2px solid white`, zIndex: 10 }"
                          class="rounded ml-4"
                          outlined
                          icon
                          @click.stop="updatePrix"
                        >
                          <v-icon small color="white">mdi-pencil-outline</v-icon>
                        </v-btn>
                      </v-toolbar>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content v-if="listupdatePrix != null">
                      <v-row class="pt-0" v-if="listupdatePrix != null">
                        <expansionColumn
                          title="Informations Générales"
                          :listExpansion="[
                            'Fournisseur',
                            'TURPE INCLUS',
                            'Durée de contrat',
                            'ABO',
                            'CEE',
                          ]"
                          :listupdatePrix="listupdatePrix"
                        />
                        <expansionColumn
                          title="Prix d'achat"
                          :listExpansion="[
                            'HPH',
                            'HP',
                            'BASE',
                            'HCH',
                            'HC',
                            'HPE',
                            'HCE',
                          ]"
                          :listupdatePrix="listupdatePrix"
                        />
                        <expansionColumn
                          title="CAPA"
                          :listExpansion="[
                            'HPH',
                            'HP',
                            'BASE',
                            'HCH',
                            'HC',
                            'HPE',
                            'HCE',
                            'Pointe',
                          ]"
                          :listupdatePrix="listupdatePrix"
                        />
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-col
              v-if="listupdatePrix != null"
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="d-flex justify-end align-items-center"
            >
              <v-btn
                class=""
                @click="getPVGISdata()"
                style="color: antiquewhite"
                :color="$colors[0]"
                v-show="showValidateBtn"
                >Valider</v-btn
              >
              <div v-if="resulat">
                <EnefficPresentation
                :compteur="PVGISdata.PDL"
                :pdfData="{
                  pageDeGardeTitle: PVGISdata?.typeClient == 'Particulier' ? `Offre ${dataProfileParticulier?.nom} ${dataProfileParticulier?.prenom}` : `OFFRE ${dataSociete?.raison}`,
                  RespName: `${dataCompte?.nom} ${dataCompte?.prenom}`,
                  RespNom: dataCompte?.nom,
                  RespPrenom: dataCompte?.prenom,
                  RespFonction: `Directeur administratif et financier`,
                  RespEmail: dataCompte?.email,
                  RespTel: dataCompte?.telephone,
                  ClientName: PVGISdata?.typeClient == 'Particulier' ? `${dataProfileParticulier?.nom} ${dataProfileParticulier?.prenom}` : `${dataSociete?.nom} ${dataSociete?.prenom}`,
                  ClientNom: PVGISdata?.typeClient == 'Particulier' ? dataProfileParticulier?.nom : dataSociete?.nom,
                  ClientPrenom: PVGISdata?.typeClient == 'Particulier' ? dataProfileParticulier?.prenom : dataSociete?.prenom,
                  ClientSociete: PVGISdata?.typeClient == 'Particulier' ? dataProfileParticulier?.adresseEmail : dataSociete?.raison,
                  ClientTel: PVGISdata?.typeClient == 'Particulier' ? dataProfileParticulier?.numTele : dataSociete?.siret,
                  PuissanceProjetKwc: puissance_kw_c,
                  ProductionAttendueKWh: productionTotal,
                  NombreDePannaux: laResultat.autoConsommation.nombrePanneaux,
                  address: PVGISdata?.adresse,
                  addressImage: null,
                  CoutTotalInstallation: laResultat.autoConsommation.coutInstallation,
                  RevenusExploitationMoyenAnnuelAttendu: laResultat.autoConsommation.revenueExploitationAnnuelle,
                  ChargeExploitation: chargesExploitation,
                  TempsDeRetour: laResultat.autoConsommation.nbrAnneePourCommencerAGagne,
                  BudgetPhotovoltaique: budgetPhotovoltaique,
                  PrimeInvestissement: primeInvestissement,
                  GainAnnuelMoyen: laResultat.autoConsommation.gainAnnuelMoyen,
                  totalEconomie: laResultat.autoConsommation.totalEconomie,
                  totalVenteSurplus: laResultat.autoConsommation.totalSurplus,
                  totalGains: laResultat.autoConsommation.totalGain,
                  tableAmortissement: laResultat.autoConsommation.tableauAmourtisement,
                  consoProdChartSeries: seriesConsoProd,
                  gainsChartSeries: seriesGains,
                  amortissementChartSeries: seriesAmortissement,
                  gainsChartSeriesTotal: seriesGainsTotal,
                  amortissementChartSeriesTotal: seriesAmortissementTotal,
                }"
                  />
                </div>
            </v-col>
          </div>
        </v-form>
      </v-card>
    </div>
    <v-row id="pagePDF">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-row class="my-5 mx-2">
          <v-col
            v-if="resulatCdc == true || resulatMesures == true"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <cardMesures
              :allDataCourbe="allDataCourbe"
              titre="Consommation"
              valueMesure="consommationMesures"
              :PVGISdata="PVGISdata"
              :condition="resulatCdc"
            />
          </v-col>
          <v-col
            v-if="allDataCourbe.consommationApresVente"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <cardMesures
              :allDataCourbe="allDataCourbe"
              titre="Apres production"
              :valueMesure="'consommationApresVente'"
              :PVGISdata="PVGISdata"
            />
          </v-col>
          <v-col v-if="resulat == true" cols="12" sm="12" md="6" lg="6" xl="6">
            <cardMesures
              :allDataCourbe="allDataCourbe"
              titre="Production"
              valueMesure="consommationSolaire"
              :PVGISdata="PVGISdata"
            />
          </v-col>
          <div class="row my-3 mx-1" v-if="validateCourbes">
            <v-row>
              <v-col
                v-if="courbe_charge_status == 1"
                cols="12"
                md="12"
                sm="12"
                lg="12"
                xl="12"
              >
                <v-row>
                  <v-col cols="12" md="12" sm="12" lg="12" xl="12">
                    <h3>Revente total</h3>
                  </v-col>
                  <v-col cols="12" md="4" sm="4" lg="4" xl="4">
                    <cardCustom
                      icon="mdi mdi-solar-power-variant"
                      title="Budget production"
                      :unite="'€/an'"
                      :color="$colors[0]"
                      :total="totalBudgetProduction"
                    />
                  </v-col>
                  <v-col cols="12" md="4" sm="4" lg="4" xl="4">
                    <cardCustom
                      icon="mdi mdi-finance"
                      title="Budget consommation"
                      :unite="'€/an'"
                      :color="$colors[0]"
                      :total="totalBudgetConsommation"
                    />
                  </v-col>
                  <v-col cols="12" md="4" sm="4" lg="4" xl="4">
                    <cardCustom
                      icon="mdi mdi-chart-pie"
                      title="Différence production consommation"
                      :unite="'€/an'"
                      :color="$colors[0]"
                      :total="Math.abs(totalBudgetConsommation - totalBudgetProduction)"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col v-if="resulatDiff" cols="12" md="12" sm="12" lg="12" xl="12">
                <v-row>
                  <v-col cols="12" md="12" sm="12" lg="12" xl="12">
                    <h3>Auto consommation</h3>
                  </v-col>
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom
                      icon="mdi mdi-chart-bell-curve-cumulative"
                      title="Production a Vendre"
                      :unite="'KWh'"
                      :color="$colors[0]"
                      :total="laResultat.autoConsommation.totalProdctionDiffVente"
                    />
                  </v-col>
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom
                      icon="mdi mdi-shopping"
                      title="Budget vente"
                      :unite="'€/an'"
                      :color="$colors[0]"
                      :total="laResultat.autoConsommation.totalBudgetDiffVente"
                    />
                  </v-col>
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom
                      icon="mdi mdi-currency-eur"
                      title="Budget achat"
                      :unite="'€/an'"
                      :color="$colors[0]"
                      :total="laResultat.autoConsommation.totalBudgetDiffAchat"
                    />
                  </v-col>
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom
                      icon="mdi mdi-solar-power-variant"
                      title="Économies sur les Factures Énergétique"
                      :unite="'€/an'"
                      :color="$colors[0]"
                      :total="laResultat.autoConsommation.econnomie"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="resulatDiff" cols="12" md="12" sm="12" lg="12" xl="12">
                <v-row>
                  <v-col cols="12" md="12" sm="12" lg="12" xl="12">
                    <h3>Etude</h3>
                  </v-col>
                  
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom2Values
                      icon="mdi mdi-finance"
                      title="Revenus d'exploitation annuel"

                      :subTitle1="'Auto'"
                      :unite1="'€/an'"
                      :color1="$colors[0]"
                      :total1="laResultat.autoConsommation.revenueExploitationAnnuelle"
                      
                      :subTitle2="'Total'"
                      :unite2="'€/an'"
                      :color2="$colors[0]"
                      :total2="laResultat.reventeTotale.revenueExploitationAnnuelle"
                    />
                  </v-col>
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom
                      icon="mdi mdi-chart-pie"
                      title="Prix d'installation par Kw crete"
                      :unite="'€'"
                      :color="$colors[0]"
                      :total="laResultat.autoConsommation.prixInstallation"
                    />
                  </v-col>
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom
                      icon="mdi mdi-chart-pie"
                      title="Coût Total de l'installation"
                      :unite="'€'"
                      :color="$colors[0]"
                      :total="laResultat.autoConsommation.coutInstallation"
                    />
                  </v-col>
                  <v-col cols="12" md="3" sm="3" lg="3" xl="3">
                    <cardCustom2Values
                      icon="mdi mdi-chart-pie"
                      title="Temps de retour"

                      :subTitle1="'Auto'"
                      :unite1="''"
                      :color1="$colors[0]"
                      :total1="laResultat.autoConsommation.nbrAnneePourCommencerAGagne"
                      
                      :subTitle2="'Total'"
                      :unite2="''"
                      :color2="$colors[0]"
                      :total2="laResultat.reventeTotale.nbrAnneePourCommencerAGagne"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-row>
        <div class="ma-2" v-if="validateCourbes && courbe_charge_status == 1">
          <v-row>
            <v-col cols="6">
              <v-card
                id="cardProConsoHeure"
                style="box-shadow: none; margin-bottom: 2rem"
              >
                <v-card-title>
                  <h6 class="Poppins-Bold" :style="{ color: $colors[0] }">
                    Courbe de production et de consommation par heure
                  </h6>
                </v-card-title>
                <VueApexCharts
                  id="chartProdConsoHeure"
                  v-if="resulat == true"
                  type="line"
                  ref="chartRefHoraire"
                  :options="chartOptionsKW"
                  :series="seriesGlob"
                  :height="350"
                />
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card id="cardProConsoMois" style="box-shadow: none">
                <v-card-title>
                  <h6 class="Poppins-Bold" :style="{ color: $colors[0] }">
                    Courbe de production et de consommation par mois
                  </h6>
                </v-card-title>
                <VueApexCharts
                  id="chartProdConsoMois"
                  v-if="resulat == true"
                  type="bar"
                  ref="chartRefBar"
                  :options="chartOptionsBarKW"
                  :series="seriesGlobMonth"
                  :height="350"
                />
              </v-card>
            </v-col>

            <v-col cols="6" style="padding-top: 10px">
              <v-card style="box-shadow: none" class="m-2">
                <v-card-title>
                  <h6 class="Poppins-Bold" :style="{ color: $colors[0] }">
                    Courbe de différence entre production et consommation par heure
                  </h6>
                </v-card-title>
                <VueApexCharts
                  v-if="resulatDiff == true"
                  type="line"
                  ref="chartRefHoraire1"
                  :options="chartOptionsKW"
                  :series="seriesGlobDiff"
                  :height="350"
                />
              </v-card>
            </v-col>
            <v-col cols="6" style="padding-top: 10px">
              <v-card style="box-shadow: none" class="m-2">
                <v-card-title>
                  <h6 class="Poppins-Bold" :style="{ color: $colors[0] }">
                    Courbe de différence entre production et consommation par mois
                  </h6>
                </v-card-title>
                <VueApexCharts
                  v-if="resulatDiff == true"
                  type="bar"
                  ref="chartRefBar1"
                  :options="chartOptionsBarKW"
                  :series="seriesGlobDiffMonth"
                  :height="350"
                />
              </v-card>
            </v-col>

            <v-col cols="12" style="padding-top: 10px">
              <v-card id="chartBudgetHeure" style="box-shadow: none">
                <v-card-title>
                  <h6 class="Poppins-Bold" :style="{ color: $colors[0] }">
                    Courbe de budget par heure
                  </h6>
                </v-card-title>
                <VueApexCharts
                  v-if="resulat == true"
                  type="line"
                  ref="chartRefHoraireBudget"
                  :options="chartOptions"
                  :series="seriesBudget"
                  :height="350"
                />
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card id="cardDifferentiel" style="box-shadow: none" class="m-2">
                <v-card-title>
                  <h6 class="Poppins-Bold" :style="{ color: $colors[0] }">
                    Courbe ventes/achats par heure
                  </h6>
                </v-card-title>
                <VueApexCharts
                  v-if="resulatDiff == true"
                  type="line"
                  ref="chartRefHoraireBudget"
                  :options="chartOptionsMultiColor"
                  :series="seriesGlobDiffBudget"
                  :height="350"
                />
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import venteForm from "./components/venteForm.vue";
import VueApexCharts from "vue-apexcharts";
import loadingComponent from "@/components/Loading";
import titleComponent from "./components/titleComponent.vue";
import cardCustom from "./components/cardCustom.vue";
import cardCustom2Values from "./components/cardCustom2Values.vue";
import cardMesures from "./components/cardMesures.vue";
import expansionColumn from "./components/expansionColumn.vue";
import EnefficPresentation from "./pdfTemplates/EnefficPresentation.vue";
export default {
  name: "PVGISformOld",
  components: {
    VueApexCharts,
    venteForm,
    loadingComponent,
    titleComponent,
    cardCustom,
    cardCustom2Values,
    cardMesures,
    expansionColumn,
    EnefficPresentation,
  },

  data() {
    return {
      nom_installation:"",
      // valeur d'economie
      courbe_charge_status: 0,
      chargesExploitation: 0,
      puissance_kw_c: 0,
      productionTotal: 0,
      budgetPhotovoltaique: 0,
      primeInvestissement: 0,
      seriesConsoProd: null,
      seriesGains: null,
      seriesAmortissement: null,
      seriesGainsTotal:null,
      seriesAmortissementTotal:null,
      
      laResultat: {
          reventeTotale:{
            econnomie: 0,
            venteSurplus: 0,
            nombrePanneaux: 0,
            revenueExploitationAnnuelle: 0,
            gainAnnuelMoyen: 0,
            tableauAmourtisement: [],
            totalEconomie: 0,
            totalSurplus: 0,
            totalGain: 0,
            nbrAnneePourCommencerAGagne: "",
            coutInstallation: 0,
            prixInstallation: 0,

          },
          autoConsommation:{
            econnomie: 0,
            venteSurplus: 0,
            nombrePanneaux: 0,
            revenueExploitationAnnuelle: 0,
            gainAnnuelMoyen: 0,
            tableauAmourtisement: [],
            totalEconomie: 0,
            totalSurplus: 0,
            totalGain: 0,
            nbrAnneePourCommencerAGagne: "",
            coutInstallation: 0,
            prixInstallation: 0,
            totalBudgetDiffVente: 0,
            totalProdctionDiffVente: 0,
            totalBudgetDiff: 0,
            totalBudgetDiffAchat: 0,
          }
      },

      dataSociete: {},
      dataCompte: {},
      dataProfileParticulier: {},
      infosSocieteList: [
        { icon: "mdi mdi-meter-electric", titre: "PDL", valeur: this.PVGISdata?.PDL },
        {
          icon: "mdi mdi-map-marker-radius",
          titre: "Adresse",
          valeur: this.PVGISdata?.adresse,
        },
      ],
      infosInstallationsList: [
        { icon: "mdi mdi-angle-acute", titre: "Inclinaison", resultat: "incliner" },
        { icon: "mdi mdi-ruler-square-compass", titre: "Angle", resultat: "orientation" },
        { icon: "m²", titre: "Taille", resultat: "tailleInstalation" },
        { icon: "mdi mdi-counter", titre: "Panneaux", resultat: "nbrPanaux" },
        { icon: "%", titre: "Pourcentage de perte", resultat: "loss" },
        { icon: "mdi mdi-solar-power", titre: "Puissance", resultat: "puissanceKWc" },
      ],
      infosConsoProdList: [
        { icon: "mdi mdi-solar-power-variant", titre: "consommation", resultat: 0 },
        { icon: "mdi mdi-finance", titre: "production", resultat: 0 },
        { icon: "mdi mdi-chart-pie", titre: "différence", resultat: 0 },
      ],
      oldPVGIS: {},
      venteForm: false,
      listupdatePrix: null,
      PVGISdata: {
        typeClient: "",
        PDL: "",
        adresse: "",
        parametreCompteur: "",
        segment: "",
        optionTarifaire: "",
        adresse: "",
        Longitude: "",
        Latitude: "",
        nbrInstallation: 1,
        Installations: [
          {
            angleOption: "non",
            tailleInstalation: null,
            nbrPanaux: null,
            incliner: 30,
            inclinOptimal: "0",
            orientation: 90,
            loss: 11,
            puissanceKWc: null,
          },
        ],
        prixVenteHph: "10",
        periode: false,
        lesPrix: {
          prixAchatHPH: null,
          prixAchatHCH: null,
          prixAchatHPE: null,
          prixAchatHCE: null,
          prixAchatPointe: null,
          prixAchatHP: null,
          prixAchatHC: null,
          prixAchatBASE: null,
          prixAchatCapaHPH: null,
          prixAchatCapaHCH: null,
          prixAchatCapaHPE: null,
          prixAchatCapaHCE: null,
          prixAchatCapaPointe: null,
          prixAchatCapaHP: null,
          prixAchatCapaHC: null,
          prixAchatCapaBASE: null,
          typeCapa: "CAPA",
          typeTurpe: "non",
          ABO: "0",
          CEE: "0",
          typeFournisseur: "Fournisseur",
          parametreCompteur: null,
        },
      },
      listCompteur: [],
      // true = heure , false = jour
      isDayChecked: false,
      // modal
      dialog: false,
      loading: false,
      messageDialoge: "",
      errorModal: false,

      resulat: false,
      resulatDiff: false,
      resulatCdc: false,
      resulatMesures: false,
      allDataCourbe: {},
      // true = heur , false = jour
      isChecked: true,

      chartOptions: {
        noData: {
          text: "Loading data...", // The custom "No Data" message or loader
          align: "center", // Position of the message ('left', 'center', or 'right')
          verticalAlign: "middle", // Vertical position of the message ('top', 'middle', or 'bottom')
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#000",
            fontSize: "14px",
            fontFamily: "Arial",
          },
        },
        chart: {
          id: "line-chart",
          animations: {
            enabled: false, // Disable animations for better performance with large data
          },
        },
        xaxis: {
          type: "datetime",// Use datetime type for x-axis
          labels: {
            datetimeFormatter: {
              year: "MMM",
              month: "MMM",
              day: "dd MMM",
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return `${Number(value).toLocaleString("fr-FR")} €`;
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: "straight",
        },
        legend: {
          height: 50,
          horizontalAlign: "center",
          tooltipHoverFormatter: function (val, opts) {
            return (
              val +
              " - " +
              Number(
                opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]
              ).toLocaleString("fr-FR") +
              " €"
            );
          },
        },
        tooltip: {
          shared: false,
          x: {
            format: "dd MMM HH:mm", // Format the date-time display in the tooltip
          },
          y: {
            formatter: function (val) {
              return "";
            },
            title: {
              formatter: function (val, opts) {
                return (
                  val +
                  " " +
                  Number(
                    opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]
                  ).toLocaleString("fr-FR") +
                  "€"
                );
              },
            },
          },
        },
        grid: {
          show: false,
          //borderColor: "#f1f1f1",
        },
      },
      chartOptionsMultiColor: {},
      chartOptionsBar: {
        grid: {
          show: false,
        },
        chart: {
          type: "bar",
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "datetime", // Use datetime type for x-axis
          labels: {
            datetimeFormatter: {
              year: "MMM",
              month: "MMM",
              day: "dd MMM",
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return Number(value).toLocaleString("fr-FR");
            },
          },
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return "";
            },
            title: {
              formatter: function (val, opts) {
                return (
                  Number(
                    opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]
                  ).toLocaleString("fr-FR") + " €"
                );
              },
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            barWidth: "10", // Set to true for horizontal bars, false for vertical bars
            dataLabels: {
              background: "transparent", // Set to 'transparent' to remove the background texture on the bars
            },
          },
        },
      },
      chartOptionsBarKW: {},
      chartOptionsKW: {},
      chartSeriesHoraire: null,
      chartSeriesMonth: null,
      dataCdcHours: null,
      seriesGlob: null,
      seriesGlobMonth: null,
      seriesBudget: null,
      seriesBudgetMonth: null,

      dataProdectionMonths: null,
      dataConsommationnMonths: null,
      //diff
      showAdminPrix: false,
      prix_vente_installation: {
        prix_total: null,
        prix_auto: null,
        prix_installation: null,
      },
      chartSeriesDiffHoraire: null,
      chartSeriesDiffMonth: null,
      diffirenceHours: null,
      diffirenceMonths: null,
      seriesGlobDiff: null,
      seriesGlobDiffMonth: null,
      showValidateBtn: false,
      showInfoInput: false,
      showCordonnerInput: false,
      showAddressInput: false,
      validateCourbes: false,
      chartSeriesBudgetHoraire: null,
      chartSeriesBudgetMonth: null,
      totalBudgetConsommation: null,
      totalBudgetProduction: null,
      chartSeriesDiffHoraireBudget: null,
      seriesGlobDiffBudget: null,
      consommationApresVente: null,
      rules: {
        validatePuissance: value => {
          if (this.PVGISdata.typeClient == "Particulier" && parseFloat(value) > 9) {
            return "La puissance ne doit pas dépasser 9 pour les clients particuliers.";
          }
          return true;
        },
        validatePercentage: value => {
          if (parseFloat(value) < 0 || parseFloat(value) > 100) {
            return "Le Pourcentage doit être entre 0 et 100";
          }
          return true;
        },
        validateOrientation: value => {
          if (parseFloat(value) < 0 || parseFloat(value) > 360) {
            return "Orientation doit être entre 0 et 360";
          }
          return true;
        },
      }
    };
  },
  mounted() {
    this.chartOptionsKW = JSON.parse(JSON.stringify(this.chartOptions));
    this.chartOptionsBarKW = JSON.parse(JSON.stringify(this.chartOptionsBar));
    this.chartOptionsMultiColor = JSON.parse(JSON.stringify(this.chartOptions));
    this.chartOptionsMultiColor.colors = ["#FF0000", "#00FF00"];
    this.chartOptionsMultiColor.yaxis.labels.formatter = function (value) {
      return `${Number(value).toLocaleString("fr-FR")} €`;
    };
    this.chartOptionsBarKW.yaxis.labels.formatter = function (value) {
      return `${Number(value).toLocaleString("fr-FR")} KWH`;
    };
    this.chartOptionsKW.yaxis.labels.formatter = this.chartOptionsBarKW.yaxis.labels.formatter;
  },
  computed: {
    roleUser() {
      return this.$store.getters.getUserRole;
    },
    puissanceKvaTotal: function () {
      let total = 0;
      this.PVGISdata.Installations.forEach((element) => {
        total += Number(element.puissanceKWc);
      });
      return total;
    },
    NombrePanneauxTotal: function () {
      let total = 0;
      this.PVGISdata.Installations.forEach((element) => {
        total += Number(element.nbrPanaux);
      });
      return total;
    },
    keyAllData: function () {
      return this.resulatMesures
        ? "consommationMesures"
        : this.resulatCdc
        ? "ConsomationCdc"
        : "";
    },
  },
  methods: {
    changeTailleInstallation() {
      if (this.PVGISdata.nbrInstallation != this.PVGISdata.Installations.length) {
        if (this.PVGISdata.nbrInstallation < this.PVGISdata.Installations.length) {
          while (
            this.PVGISdata.nbrInstallation < this.PVGISdata.Installations.length &&
            this.PVGISdata.nbrInstallation >= 1
          ) {
            this.PVGISdata.Installations.splice(
              this.PVGISdata.Installations.length - 1,
              1
            );
          }
        } else {
          while (
            this.PVGISdata.nbrInstallation > this.PVGISdata.Installations.length &&
            this.PVGISdata.nbrInstallation <= 3
          ) {
            this.PVGISdata.Installations.push({
              angleOption: "non",
              tailleInstalation: null,
              nbrPanaux: null,
              incliner: 30,
              inclinOptimal: "0",
              orientation: 0,
              loss: 11,
              puissanceKWc: null,
            });
          }
        }
      }
    },
    to_fixed2(num) {
      return parseFloat(num).toFixed(2);
    },
    async initGraphs() {
      console.log("INIT GRAPH ------------------------------------------------------");
      this.chartSeriesHoraire = [
        {
          name: "Production Solaire",
          data: this.dataProdectionHours.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.value),
          })),
        },
        {
          name: "Consommation Énergétique",
          data: this.dataConsommationHours.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.value),
          })),
        },
      ];

      this.chartSeriesMonth = [
        {
          name: "Production Solaire",
          data: this.dataProdectionMonths.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.value),
          })),
        },
        {
          name: "Consommation Énergétique",
          data: this.dataConsommationnMonths.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.value),
          })),
        },
      ];
      this.seriesGlob = this.chartSeriesHoraire;
      this.seriesGlobMonth = this.chartSeriesMonth;
      console.log("INIT GRAPH ------------------------------------------------------");
    },
    async initGraphsDiff() {
      this.chartSeriesDiffHoraire = [
        {
          name: "Diff",
          data: this.diffirenceHours.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.value),
          })),
        },
      ];
      this.chartSeriesDiffMonth = [
        {
          name: "Diff Months",
          data: this.diffirenceMonths.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.value),
          })),
        },
      ];
      this.chartSeriesDiffHoraireBudget = [
        {
          name: "Achats",
          data: this.diffirenceHours.map((item) => {
            if (item.vente_ou_achat == "achat") {
              return { x: new Date(item.date).getTime(), y: this.to_fixed2(item.Budget) };
            } else {
              return { x: new Date(item.date).getTime(), y: 0 };
            }
          }),
        },
        {
          name: "Ventes",
          data: this.diffirenceHours.map((item, index) => {
            if (item.vente_ou_achat == "vente") {
              //this.chartSeriesDiffHoraireBudget[1]["data"][index-1] = this.chartSeriesDiffHoraireBudget[0]["data"][index-1];
              return { x: new Date(item.date).getTime(), y: this.to_fixed2(item.Budget) };
            } else {
              return { x: new Date(item.date).getTime(), y: 0 };
            }
          }),
        },
      ];
      let skip = false;
      this.diffirenceHours.map((item, index) => {
        if (skip) {
          skip = false;
          return;
        }
        if (index == 0 || index == this.diffirenceHours.lenght) {
          return;
        }
        if (
          this.chartSeriesDiffHoraireBudget[0]["data"][index].y == null &&
          this.chartSeriesDiffHoraireBudget[0]["data"][index - 1].y != null
        ) {
          this.chartSeriesDiffHoraireBudget[0]["data"][
            index
          ] = this.chartSeriesDiffHoraireBudget[1]["data"][index];
          skip = true;
        }
        if (
          this.chartSeriesDiffHoraireBudget[1]["data"][index].y == null &&
          this.chartSeriesDiffHoraireBudget[1]["data"][index - 1].y != null
        ) {
          this.chartSeriesDiffHoraireBudget[1]["data"][
            index
          ] = this.chartSeriesDiffHoraireBudget[0]["data"][index];
          skip = true;
        }
        return;
      });
      //Date(item.date).getTime()
      this.seriesGlobDiffBudget = this.chartSeriesDiffHoraireBudget;
      this.seriesGlobDiff = this.chartSeriesDiffHoraire;
      this.seriesGlobDiffMonth = this.chartSeriesDiffMonth;
    },
    async initGraphsBudget() {
      console.log("INIT GRAPH ------------------------------------------");
      console.log(this.dataProdectionHours);
      console.log(this.dataConsommationHours);
      this.chartSeriesBudgetHoraire = [
        {
          name: "Budget production solaire",
          data: this.dataProdectionHours.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.budget_total),
          })),
        },
        {
          name: "Budget consommation énergétique",
          data: this.dataConsommationHours.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.budget),
          })),
        },
      ];
      console.log(this.dataProdectionMonths);
      console.log(this.dataConsommationnMonths);
      this.chartSeriesBudgetMonth = [
        {
          name: "Budget production solaire",
          data: this.dataProdectionMonths.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.budget_total),
          })),
        },
        {
          name: "Budget consommation énergétique",
          data: this.dataConsommationnMonths.map((item) => ({
            x: new Date(item.date).getTime(),
            y: this.to_fixed2(item.budget),
          })),
        },
      ];
      this.seriesBudget = this.chartSeriesBudgetHoraire;
      this.seriesBudgetMonth = this.chartSeriesBudgetMonth;
      console.log("INIT GRAPH ------------------------------------------");
    },
    transformInstallation(installations) { 
      let transformedInstallations = [];
      for (let i = 0; i < installations.length; i++) {
        let item = installations[i];
        let transformedInstallation = {
          ...item,
          angleOption: item.angleOption,
          orientation: (parseInt(item.orientation) + 360) % 360,
        };
        transformedInstallations.push(transformedInstallation);
      }
      return transformedInstallations;
    },
    async getAdressePDL() {
      if (this.$refs.formSiret.validate()) {
        this.oldPVGIS = JSON.parse(JSON.stringify(this.PVGISdata));
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + `getAdressePDL/`,
          method: "POST",
          data: {
            PDL: this.PVGISdata.PDL,
          },
        })
          .then((response) => {
            if (response.data.resultat == false) {
              this.dialog = true;
              this.errorModal = true;
              this.messageDialoge = response.data.message;
            }
            this.dataSociete = response.data.dataSociete;
            this.dataCompte = response.data.dataCompte;
            this.dataProfileParticulier = response.data.dataProfileParticulier;
            this.PVGISdata.typeClient = response.data.typeClient;
            this.PVGISdata.adresse = response.data.adresse;
            this.PVGISdata.parametreCompteur = response.data.parametreCompteur;
            this.PVGISdata.segment = response.data.segment;
            this.PVGISdata.optionTarifaire = response.data.optionTarifaire;
            if (
              !(
                this.PVGISdata.adresse === undefined ||
                this.PVGISdata.adresse === null ||
                this.PVGISdata.adresse === ""
              )
            ) {
              this.showAddressInput = true;
              if (response.data.pvgis_status) {
                let transformedInstallations = this.transformInstallation(response.data.pvgis.installation);
                this.$set(this.PVGISdata, "Installations", transformedInstallations);
                this.PVGISdata.nbrInstallation = this.PVGISdata.Installations.length;
                this.PVGISdata.Longitude = response.data.pvgis.longitude;
                this.PVGISdata.Latitude = response.data.pvgis.latitude;
                let lesPrix = {
                  prixAchatHPH: response.data.pvgis.prixAchatHph,
                  prixAchatHCH: response.data.pvgis.prixAchatHch,
                  prixAchatHPE: response.data.pvgis.prixAchatHpe,
                  prixAchatHCE: response.data.pvgis.prixAchatHce,
                  prixAchatPointe: response.data.pvgis.prixAchatPointe,
                  prixAchatHP: response.data.pvgis.prixAchatHp,
                  prixAchatHC: response.data.pvgis.prixAchatHc,
                  prixAchatBASE: response.data.pvgis.prixAchatBase,
                  prixAchatCapaHPH: response.data.pvgis.prixAchatCapaHph,
                  prixAchatCapaHCH: response.data.pvgis.prixAchatCapaHch,
                  prixAchatCapaHPE: response.data.pvgis.prixAchatCapaHpe,
                  prixAchatCapaHCE: response.data.pvgis.prixAchatCapaHce,
                  prixAchatCapaPointe: response.data.pvgis.prixAchatCapaPointe,
                  prixAchatCapaHP: response.data.pvgis.prixAchatCapaHp,
                  prixAchatCapaHC: response.data.pvgis.prixAchatCapaHc,
                  prixAchatCapaBASE: response.data.pvgis.prixAchatCapaBase,
                  typeCapa: response.data.pvgis.typeCapa,
                  typeTurpe: response.data.pvgis.turpe,
                  ABO: response.data.pvgis.abo,
                  CEE: response.data.pvgis.cee,
                  typeFournisseur: "Fournisseur",
                  parametreCompteur: response.data.parametreCompteur,
                };
                this.PVGISdata.lesPrix = lesPrix;

                this.showAddressInput = true;
                this.showCordonnerInput = true;
                this.showInfoInput = true;
              }
            } else {
              this.showAddressInput = false;
            }
          })
          .catch((errors) => {
            console.log(errors);
          });
        this.infosSocieteList[0].valeur = this.PVGISdata.PDL;
        this.infosSocieteList[1].valeur = this.PVGISdata.adresse;
      }
    },
    async getPosition() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + `getMapLocation/`,
        method: "POST",
        data: {
          adresse: this.PVGISdata.adresse,
        },
      })
        .then((response) => {
          this.PVGISdata.Longitude =
            response.data.resultat.features[0].geometry.coordinates[0];
          this.PVGISdata.Latitude =
            response.data.resultat.features[0].geometry.coordinates[1];
          this.showCordonnerInput = true;
          if (this.PVGISdata.Longitude !== "" && this.PVGISdata.Latitude !== "") {
            this.showCordonnerInput = true;
            this.showInfoInput = true;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    async getPVGISdata() {
      if (this.$refs.formGis.validate()) {
        this.dialog = true;
        this.loading = true;
        this.messageDialoge = "";
        this.errorModal = false;
        const transformedPvgisData = {
          ...this.PVGISdata,
          Installations: this.PVGISdata.Installations.map((installation) => {
            return {
              ...this.transformStringNumbersToNumeric(installation),
              orientation: parseInt(installation.orientation) > 180 ? parseInt(installation.orientation) - 360 : parseInt(installation.orientation),
              Longitude: this.PVGISdata.Longitude,
              Latitude: this.PVGISdata.Latitude,
            };
          }),
        };
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + `getCourbeData/`,
          method: "POST",
          data: {
            token: this.$store.getters.getUserToken,
            ...transformedPvgisData,
            nom_installation: this.nom_installation,
            isPriceEdited: this.showAdminPrix,
            prix_vente_installation: this.prix_vente_installation,
          },
        })
          .then(async (response) => {
            if (response.data.result == true) {
              this.allDataCourbe = response.data;
              this.courbe_charge_status = response.data.Courbe_De_Charge_status;
              if (response.data.Courbe_De_Charge_status == 1) {
                this.productionTotal = this.allDataCourbe.total_value;
                this.resulatCdc = true;
                this.resulatMesures = true;
                this.dataProdectionHours = response.data.solar_data.SolarHours_as_json;
                this.dataConsommationHours = response.data.consommation_data.ConsoHours_as_json;
                this.dataProdectionMonths = response.data.solar_data.SolarMonths_as_json;
                this.dataConsommationnMonths = response.data.consommation_data.ConsoMonths_as_json;
                this.totalBudgetConsommation = response.data.consommation_data.totalBudgetConsommation;
                this.totalBudgetProduction = response.data.solar_data.totalBudgetSolar;

                // pdf charts
                const dataChartConsommation = response.data.consommation_data.ConsoMonths_as_json.map(
                  (item) => item.value
                );
                const dataChartProduction = response.data.solar_data.SolarMonths_as_json.map(
                  (item) => item.value
                );
                this.seriesConsoProd = [
                  {
                    name: "Consommation Energitique",
                    type: "column",
                    data: dataChartConsommation,
                  },
                  {
                    name: "Production solaire",
                    type: "area",
                    data: dataChartProduction,
                  },
                ];
                console.log("BEFORE INIT GRAPH");
                await this.initGraphs();
                await this.initGraphsBudget();
                this.resulat = true;
              } else if (response.data.Courbe_De_Charge_status == 0) {
                this.resulatCdc = false;
                this.resulatMesures = true;
              } else if (response.data.Courbe_De_Charge_status == -1) {
                this.resulatCdc = false;
                this.resulatMesures = false;
              }


              //get les donner des diffirences

              
              this.resulatDiff = true;
              this.diffirenceHours = response.data.diff.hours.data;
              this.diffirenceMonths = response.data.diff.months.data;
              this.laResultat.autoConsommation.nombrePanneaux = response.data.diff.nbrPanaux;
              this.laResultat.autoConsommation.totalBudgetDiffAchat = response.data.diff.hours.sum_buy;
              this.laResultat.autoConsommation.econnomie = response.data.diff.econnomie;
              this.laResultat.autoConsommation.venteSurplus = response.data.diff.venteSurplus;
              this.laResultat.autoConsommation.revenueExploitationAnnuelle = response.data.diff.revenueExploitationAnnuelle;
              this.laResultat.autoConsommation.nbrAnneePourCommencerAGagne =response.data.diff.nbrAnneePourCommencerAGagne;
              this.laResultat.autoConsommation.coutInstallation = response.data.diff.coutInstallation;
              this.laResultat.autoConsommation.prixInstallation = response.data.diff.prixInstallation;
              this.laResultat.autoConsommation.tableauAmourtisement = response.data.diff.tableauAmourtisement;
              this.laResultat.autoConsommation.gainAnnuelMoyen = response.data.diff.gainTotalMoyenne;
              this.laResultat.autoConsommation.totalEconomie = response.data.diff.leTotaleDeEnconomie;
              this.laResultat.autoConsommation.totalSurplus = response.data.diff.leTotaleDeSurplus;
              this.laResultat.autoConsommation.totalGain = response.data.diff.leTotaleDeGain;
              this.laResultat.autoConsommation.totalBudgetDiffVente = response.data.diff.hours.sum_sell;
              this.laResultat.autoConsommation.totalProdctionDiffVente = response.data.diff.hours.sum_sell_Conso;
              this.laResultat.autoConsommation.totalBudgetDiff = response.data.diff.totalBudgetDiff;
              this.laResultat.reventeTotale.revenueExploitationAnnuelle = response.data.revenueExploitationAnnuelleReventeTotal;
              this.laResultat.reventeTotale.nbrAnneePourCommencerAGagne = response.data.nbrAnneePourCommencerAGagneReventeTotal;
              this.puissance_kw_c = response.data.diff.puissance_kw_c;
              this.chargesExploitation = 0;
              this.seriesGains = [
                {
                  name: "ECONOMIES SUR FACTURES",
                  data: response.data.diff.tableauAmourtisement.map((item) => item.enconomie),
                },
                {
                  name: "VENTE DE SURPLUS",
                  data: response.data.diff.tableauAmourtisement.map((item) => item.surplus),
                },
              ];
              this.seriesAmortissement = [
                {
                  name: "Amortissement",
                  data: response.data.diff.tableauAmourtisement.map(
                    (item) => item.amortisementTempsGraph
                  ),
                },
              ];
              this.seriesGainsTotal = [
                {
                  name: "ECONOMIES SUR FACTURES",
                  data: response.data.tableauAmourtisementReventeTotal.map((item) => item.enconomie),
                },
                {
                  name: "VENTE DE SURPLUS",
                  data: response.data.tableauAmourtisementReventeTotal.map((item) => item.surplus),
                },
              ];
              this.seriesAmortissementTotal = [
                {
                  name: "Amortissement",
                  data: response.data.tableauAmourtisementReventeTotal.map(
                    (item) => item.amortisementTempsGraph
                  ),
                },
              ];
              this.allDataCourbe["consommationApresVente"] = response.data.diff.hours.consommationApresVente;
              await this.initGraphsDiff();
              this.resulat = true;
              this.validateCourbes = true;
            }
          })
          .catch((errors) => {
            console.log(errors);
            this.loading = false;
          });
        /*if (this.resulat == true) {
          await this.getDiffirence();
        }*/
        this.loading = false;
        this.dialog = false;
      }
    },
    transformStringNumbersToNumeric(data) {
      return {
        ...data,
        incliner: parseInt(data.incliner),
        loss: parseInt(data.loss),
        nbrPanaux: parseInt(data.nbrPanaux),
        orientation: parseInt(data.orientation),
        angleOption: data.angleOption,
        // from int to string
        inclinOptimal: data.inclinOptimal.toString(),
      
       
      };
    },
    checkDaily() {
      this.isDayChecked = !this.isDayChecked;
      this.PVGISdata.periode = this.isDayChecked;
    },
    async getPropositionCompteur() {
      this.showAddressInput = false;
      this.validateCourbes = false;
      this.resulat = false;
      this.resulatCdc = false;
      this.resulatMesures = false;
      if (this.PVGISdata.PDL === null) {
        this.listCompteur = [];
        this.listCompteurValider = [];
        this.PVGISdata.PDL = "";
      } else if (!!this.PVGISdata.PDL) {
        if (true) {
          await axios({
            url:
              process.env.VUE_APP_URL_API_CLIENT + "getPropositionNumCompteurByCompte/",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify({
              token: this.$store.getters.getUserToken,
              numeroCompteur: this.$DOMPurify.sanitize(this.PVGISdata.PDL),
            }),
          }).then((res) => {
            this.listCompteur = res.data.result;
          });
        }
      }
    },
    handleCloseEmit() {
      this.venteForm = false;
    },
    handleListUpdatePrix(list) {
      this.PVGISdata.lesPrix = list;
      this.listupdatePrix = { ...list };

      if (this.PVGISdata.lesPrix !== null) {
        this.showValidateBtn = true;
      } else {
        this.showValidateBtn = false;
      }
    },
    closeLoading(event) {
      this.dialog = event;
      this.errorModal = event;
      if (this.responseStatus === 401) {
        this.$store.dispatch("logout");
      }
    },
    updatePrix() {
      // send this.PVGISdata.lesPrix to venteForm component:
      this.venteForm = true;
      //this.$refs.venteForm.updatePrix = this.PVGISdata.lesPrix;
    },
  },
};
</script>
<style scoped>
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("../../assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("../../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

.Poppins-Bold {
  font-family: "Poppins-Bold";
}

::v-deep
  .theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color: white !important;
}
::v-deep .v-input--selection-controls .v-radio > .v-label {
  margin: 0px !important;
}

::v-deep .v-sheet.theme--dark.elevation-0.v-toolbar {
  height: fit-content !important;
}

::v-deep .v-sheet.theme--dark.elevation-0.v-toolbar > .v-toolbar__content {
  height: fit-content !important;
}
</style>

<style scoped>
::v-deep .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
  margin-bottom: 0px;
}
</style>
