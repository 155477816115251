<template>
  <!--<div v-if="$cookies.get('token')" style="height:80vh" class='d-flex flex-row justify-center align-center'>-->
  <div style="height: 100%" class="d-flex flex-row justify-center align-center">
    <loadingComponent
      style="position: absolute"
      v-if="dialogLoading"
      :dialogProps="dialogLoading"
      :messageDialogeProps="messageError"
      :loadingProps="loadingEnedis"
      :errorProps="errorDialog"
      @closeDialog="close()"
    />
    <v-card
      v-if="!loadingEnedis && !infosCourbe"
      class="ma-4 rounded-lg"
      style="width: 40%"
    >
      <div id="rowConnected" v-if="infosCourbe == false">
        <v-toolbar class="rounded-t-lg" :style="{ background: $styles.background }" dark>
          <span class="pl-0">Formulaire de Calcul</span>
        </v-toolbar>
        <div class="pa-4">
          <v-form ref="formACD">
            <v-form ref="formSiret" class="d-flex flex-row">
              <v-combobox
                dense
                :search-input.sync="societeRechercheModel"
                v-model="societeRechercheModel"
                outlined
                :items="listSociete"
                :rules="[
                  $rules.required('un Siret/Raison Social valide'),
                  $rules.isSanitize,
                  $rules.injection_sql,
                ]"
                @update:search-input="getPropositionSociete()"
                label="Siret ou Raison sociale"
              ></v-combobox>
              <v-btn
                :color="$colors[0]"
                class="ml-4"
                @click="getNumCompteurBySociete"
                :loading="loadingEnedis"
              >
                <v-icon class="white--text">mdi-magnify</v-icon>
              </v-btn>
            </v-form>
            <div class="d-flex flex-row" v-if="listnumCompteurACD.length > 0">
              <v-autocomplete
                outlined
                dense
                @change="getInfosCompteur"
                :rules="[
                  $rules.required('un numéro de compteur'),
                  $rules.isSanitize,
                  $rules.isNumber,
                ]"
                v-model="compteur.numCompteurACD"
                :items="listnumCompteurACD"
                label="Compteur"
              ></v-autocomplete>
            </div>
            <v-row v-if="listnumCompteurACD.length > 0" class="px-3">
              <v-col
                cols="6"
                class="pa-0 px-1"
                v-for="(value, key, index) in compteur"
                :key="index"
                v-if="listnumCompteurACD.length > 0"
              >
                <v-text-field
                  v-if="key !== 'numCompteurACD'"
                  disabled
                  :label="key"
                  outlined
                  dense
                  v-model="compteur[key]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-autocomplete
            v-if="listnumCompteurACD.length > 0"
            v-model="modeleVoiture"
            :items="listeModelesVoitures"
            outlined
            dense
            label="Modele Voiture"
            @input="getVersionToModelVoiture"
          ></v-autocomplete>
          <v-autocomplete
            v-if="listeVersionModels.length >= 1 && listnumCompteurACD.length > 0"
            outlined
            dense
            v-model="versionVoitureModels"
            :items="listeVersionModels"
            @input="getInformationsVoiture"
            label="Version"
          ></v-autocomplete>
          <div v-if="versionVoitureModels != '' && listnumCompteurACD.length > 0">
            <v-form class="d-flex flex-row" ref="formPrixEssenceElec">
              <v-text-field
                style="width: 40%"
                class="mr-2"
                type="number"
                label="Distance Parcourue"
                outlined
                dense
                suffix="km"
                :rules="[
                  $rules.required('une distance parcourue'),
                  $rules.greaterThanZero('La distance parcourue'),
                ]"
                v-model="distanceVoiture"
              ></v-text-field>
              <v-text-field
                style="width: 40%"
                class="ml-2"
                type="number"
                label="Conso Essence"
                outlined
                suffix="L/100km"
                :rules="[
                  $rules.required('une consommation d\'essence'),
                  $rules.greaterThanZero('La consommation d\'essence'),
                ]"
                dense
                v-model="consoVoiture"
              ></v-text-field>
            </v-form>
            <div>
              <v-btn
                style="width: 100%"
                v-if="versionVoitureModels != ''"
                class="btnInfos"
                :color="$colors[0]"
                dense
                @click="afficheInfos = !afficheInfos"
                ><v-icon class="white--text">{{
                  afficheInfos == false ? "mdi-plus" : "mdi-minus"
                }}</v-icon></v-btn
              >
            </div>
            <v-form class="d-flex flex-row mt-8" v-if="afficheInfos">
              <v-text-field
                label="Prix Essence"
                suffix="€"
                type="number"
                style="width: 40%"
                class="mr-2"
                outlined
                :rules="[
                  $rules.required('un prix d\'essence'),
                  $rules.greaterThanZero('Le prix d\'essence'),
                ]"
                dense
                v-model="prixEssence"
              ></v-text-field>
              <v-text-field
                label="Prix Elec"
                outlined
                style="width: 40%"
                class="ml-2"
                type="number"
                suffix="€"
                :rules="[
                  $rules.required('un prix d\'electricite'),
                  $rules.greaterThanZero('Le prix d\'electricite'),
                ]"
                dense
                v-model="prixElec"
              ></v-text-field>
            </v-form>
            <div class="mt-8">
              <v-text-field
                label="Cout Essence"
                outlined
                disabled
                suffix="€"
                dense
                v-model="coutEssence"
              ></v-text-field>
              <v-text-field
                label="Cout Elec"
                outlined
                disabled
                suffix="€"
                dense
                v-model="coutElec"
              ></v-text-field>
              <v-text-field
                label="Différence cout Essence-Elec"
                outlined
                disabled
                suffix="€"
                dense
                v-model="prixDifferenceEssenceElec"
              ></v-text-field>
              <v-btn
                style="width: 100%"
                :color="$colors[0]"
                v-if="versionVoitureModels != ''"
                :disabled="
                  distanceVoiture == 0 ||
                  consoVoiture == 0 ||
                  prixElec == 0 ||
                  prixEssence == 0
                "
                class="btnInfos white--text"
                dense
                @click="getCoutElecEssence"
                >Obtenir prix Essence/Elec</v-btn
              >
              <v-btn
                style="width: 100%"
                :color="$colors[0]"
                v-if="versionVoitureModels != ''"
                :disabled="coutEssence == 0 || coutElec == 0"
                class="btnInfos white--text mt-4"
                dense
                @click="insertDataHistoriqueCalcul"
                >Enregistrer les données</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import loadingComponent from "../components/Loading.vue";
import voitureAPI from "../api/voiture";
import axios from "@/plugins/axios";
import dayjs from "dayjs";
export default {
  name: "HelloWorld",
  components: { loadingComponent },
  data() {
    return {
      snackbar: false,
      messageSnackbar: "",
      messageError: "",
      societeRechercheModel: "LOPES CLEMENT",
      infosCourbe: false,
      dialogLoading: false,
      loadingEnedis: false,
      errorCodePostalEnedis: false,
      errorEnedis: false,
      errorDialog: false,
      adresseUser: "3 RUE DES MARTYRS 75009 PARIS",
      compteur: {
        segment: "",
        kva: 0,
        puissanceMaxAtteinte: 0,
        optionTarifaire: "",
        numCompteurACD: "",
      },
      listSociete: [],
      listnumCompteurACD: [],
      afficheInfos: false,
      versionVoitureModels: "",
      listeVersionModels: [],
      modeleVoiture: "",
      prixEssence: 0,
      prixElec: 0,
      distanceVoiture: 0,
      consoVoiture: 0,
      listeModelesVoitures: [],
      coutEssence: 0,
      coutElec: 0,
      prixDifferenceEssenceElec: 0,
    };
  },
  async created() {
    await this.getModel();
  },
  methods: {
    async getPropositionSociete() {
      if (this.societeRechercheModel === null) {
        this.listSociete = [];
        this.listnumCompteurACD = [];
      }
      if (this.$refs.formSiret.validate()) {
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "getPropositionSociete/",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            token: this.$store.getters.getUserToken,
            societeValue: this.$DOMPurify.sanitize(this.societeRechercheModel),
          }),
        }).then((res) => {
          this.listSociete = res.data.result;
        });
      }
    },
    close() {
      (this.errorDialog = false), (this.dialogLoading = false);
    },
    async getInfosCompteur() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getInformationsCompteur/",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          token: this.$store.getters.getUserToken,
          numCompteur: this.compteur.numCompteurACD,
        }),
      }).then((res) => {
        let response = res.data.compteur;
        this.compteur.kva = response.kva;
        this.compteur.puissanceMaxAtteinte = response.puissanceAtteinteMaximale;
        this.compteur.segment = response.segment;
        this.compteur.optionTarifaire = response.optionTarifaire;
      });
    },
    async getNumCompteurBySociete() {
      if (this.$refs.formSiret.validate()) {
        this.dialogLoading = true;
        this.loadingEnedis = true;
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "getListCompteurParSiret/",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            token: this.$store.getters.getUserToken,
            societeValue: this.societeRechercheModel,
          }),
        })
          .then(async (res) => {
            let listCompteur = res.data.listeCompteur;
            if (listCompteur.length > 0) {
              this.listnumCompteurACD = res.data.listeCompteur;
              this.compteur.numCompteurACD = this.listnumCompteurACD[0];
              this.loadingEnedis = false;
              this.dialogLoading = false;
              await this.getInfosCompteur();
            }
          })
          .catch((error) => {
            
            if (error.response.status === 404) {
              // Gérer la réponse 404 ici
              this.messageError = error.response.data.messageError;
              this.errorDialog = true;
              this.loadingEnedis = false;
            } else {
              // Gérer les autres erreurs
              console.log("Une erreur s'est produite");
            }
          });
      }
    },
    async getEnedisPoint() {
      if (this.$refs.formAdresseACD.validate() /*&& this.$cookies.get('token')*/) {
        this.listnumCompteurACD = ["07139073795897"];
        this.compteur.numCompteurACD = this.listnumCompteurACD[0];
        this.loadingEnedis = true;
        this.dialogLoading = true;
        /*if((/[A-Z][a-z].*$/).test(this.adresseUser)){
          let commune = this.adresseUser.match((/[A-Z][a-z].*$/));
          this.adresseUser = this.adresseUser.replace(commune,"");
        }*/
        //let splitAdresse = this.adresseUser.match(/\b\d{5}(?:[-\s]\d{4})?\b/);
        //let codePostal = splitAdresse[0];
        //let adresse = splitAdresse['input'].replace(codePostal,'');
        /*
        await fetch(process.env.VUE_APP_URL_API_CLIENT+"getListeDesPoints/", {
              method: "POST",
              body:JSON.stringify({
                adresseUser: this.adresseUser,
                /*adresse: this.$DOMPurify.sanitize(adresse),
                codePostal: this.$DOMPurify.sanitize(codePostal),
                codeInsee:34129,*/
        /*
                token:this.$cookies.get('token')
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
              }
            })
            .then(res=>res.json())
            .then(data=>{
              if(data.data){
                this.listnumCompteurACD = data.data;
                this.numCompteurACD = this.listnumCompteurACD[0];
              }else if(data.errorPostal){
                this.dialogError = true;
                this.errorCodePostalEnedis = true;
                this.listnumCompteurACD = [];
              }else if (data.error){
                this.dialogError = true;
                this.errorEnedis = true;
                this.listnumCompteurACD = [];
              }

            })
            */
        this.loadingEnedis = false;
        this.dialogLoading = false;
      }
    },
    async insertDataHistoriqueCalcul() {
      this.dialogLoading = true;
      this.loadingEnedis = true;
      let objectData = {
        prix_essence: this.prixEssence,
        prix_elec: this.prixElec,
        consommation_litre_100km: this.consoVoiture,
        distance: this.distanceVoiture,
        difference_essence_elec: this.prixDifferenceEssenceElec,
        cout_elec: this.coutElec,
        cout_essence: this.coutEssence,
        numCompteur: this.compteur.numCompteurACD,
      };
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "insertDataHistoriqueCalcul/",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          token: this.$store.getters.getUserToken,
          dataHistoriqueCalcul: objectData,
        }),
      }).then((res) => {
        if (res.data.messageError) {
          this.messageError = res.data.messageError;
          this.errorDialog = true;
          this.loadingEnedis = false;
        } else if (res.data.messageSucces) {
          this.messageError = res.data.messageSucces;
          this.loadingEnedis = false;
        }
      });
    },
    async getCoutElecEssence() {
      if (this.$refs.formPrixEssenceElec.validate()) {
        this.loadingEnedis = true;
        this.dialogLoading = true;

        const bodyObject = JSON.stringify({
          token: this.$store.getters.getUserToken,
          version: this.versionVoitureModels,
          model: this.modeleVoiture,
          distance: this.distanceVoiture,
          consommationEssence: this.consoVoiture,
          prixEssence: this.prixEssence,
          prixElec: this.prixElec,
        });
        const coutEssenceElec = await voitureAPI.get_cout_elec_essence_voiture(
          bodyObject
        );

        (this.coutElec = coutEssenceElec.coutElec),
          (this.coutEssence = coutEssenceElec.coutEssence),
          (this.prixDifferenceEssenceElec = coutEssenceElec.differenceEssenceElec);
        this.loadingEnedis = false;
        this.dialogLoading = false;
      }
    },
    async getInformationsVoiture() {
      this.coutEssence = 0;
      this.coutElec = 0;
      this.prixDifferenceEssenceElec = 0;
      this.loadingEnedis = true;
      this.dialogLoading = true;

      const prix_elec_essence = await voitureAPI.get_prix_elec_essence_voiture();

      this.prixElec = prix_elec_essence.prixElec;
      this.prixEssence = prix_elec_essence.prixEssence;

      this.loadingEnedis = false;
      this.dialogLoading = false;
    },
    async getVersionToModelVoiture() {
      this.coutEssence = 0;
      this.coutElec = 0;
      this.prixDifferenceEssenceElec = 0;
      this.loadingEnedis = true;
      this.dialogLoading = true;

      this.versionVoitureModels = "";

      let listVersionModelVoiture = await voitureAPI.get_version_model_voiture(
        this.modeleVoiture
      );
      this.listeVersionModels = listVersionModelVoiture.listeVersionModels;
      this.versionVoitureModels = listVersionModelVoiture.versionVoitureModels;
      await this.getInformationsVoiture();
      this.loadingEnedis = false;
      this.dialogLoading = false;
    },
    async getModel() {
      if (this.$store.getters.getUserToken) {
        this.loadingEnedis = true;
        this.dialogLoading = true;

        this.listeModelesVoitures = await voitureAPI.get_all_model_voiture();

        this.loadingEnedis = false;
        this.dialogLoading = false;
      }
    },
  },
};
</script>
