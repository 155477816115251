<template>
  <div class="page-blanc">
    <img 
    class="logo-enffic-pdf"
    src="../images/logo-eneffic.png"
    />
    <p class="page-title">
      <span>Synthèse financière de mon projet</span>
    </p>
    <div class="content">
      <div class="cards-wrapper">
        <div class="card card-blue">
          <p class="titleOfCard">Budget du projet photovoltaique</p>
          <p class="price">{{ Number(budgetPhotovoltaique).toLocaleString("fr-FR") }}€</p>
        </div>
        <div class="card card-green">
          <p class="titleOfCard">Prime à l'investissement</p>
          <p class="price">{{ Number(primeInvestissement).toLocaleString("fr-FR") }}€</p>
        </div>
        <div class="card card-blue">
          <p class="titleOfCard">Gain Annuel Moyen</p>
          <p class="price">{{ Number(gainAnnuelMoyen).toLocaleString("fr-FR") }}€</p>
        </div>
      </div>

      <div class="pdf-table-wrapper">
        <table>
          <thead>
            <tr>
              <td>Année</td>
              <td>Production photovoltaique (kWh) tenant compte de la perte maximale de rendement prévue par la garantie fabricant</td>
              <td class="greenTD">Economies sur la facture d'éléctricité</td>
              <td>Vente de surplus</td>
              <td class="greenTD">Gains totaux</td>
            </tr>
          </thead>
          <tbody>
            <template v-if="tableauAmourtisement && tableauAmourtisement.length>0">
              <tr v-for="row in tableauAmourtisement">
                <td>{{ row.annee }}</td>
                <td>{{ Number((row.productionSolar * 1000).toFixed(2)).toLocaleString("fr-FR") }}</td>
                <td class="greenTD">{{ Number(row.enconomie?.toFixed(2)).toLocaleString("fr-FR") }}</td>
                <td>{{ Number(row.surplus).toLocaleString("fr-FR") }}</td>
                <td class="greenTD">{{ Number(row.gainTotal?.toFixed(2)).toLocaleString("fr-FR") }}</td>
              </tr>
            </template>
            <template v-else>
              <tr v-for="index in 25">
                <td>{{ index }}</td>
                <td>{{ "--" }}</td>
                <td class="greenTD">{{ "--" }}</td>
                <td>{{ "--" }}</td>
                <td class="greenTD">{{ "--" }}</td>
              </tr>
            </template>
            <tr>
              <td></td>
              <td>TOTAL</td>
              <td class="greenTD">{{ totalEconomie ? Number(totalEconomie?.toFixed(2)).toLocaleString("fr-FR") : "--" }}</td>
              <td>{{ totalSurplus ? Number(totalSurplus?.toFixed(2)).toLocaleString("fr-FR") : "--" }}</td>
              <td class="greenTD">{{ totalGain ? Number(totalGain?.toFixed(2)).toLocaleString("fr-FR") : "--" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="footer">
      <ul>
        <li>ENEFFIC - 2 Aliée du Levant 34970 Lattes</li>
        <li>Téléphone: +33 (0)4 65 84 38 76 - E-mail: contact@eneffic.fr</li>
        <li>SIREN: 951 979 798</li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "TableSyntheseFinanciere",
  props: [
    "budgetPhotovoltaique",
    "primeInvestissement",
    "gainAnnuelMoyen",
    "tableauAmourtisement",
    "totalEconomie",
    "totalSurplus",
    "totalGain",
  ],
};
</script>

<style lang="scss" scoped>
.page-blanc{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 32px;

  .logo-enffic-pdf{
    height: 24px;
    margin-top: 40px;
    margin-left: 40px;
  }
  
  .page-title{
    width: fit-content;
    position: relative;
    font-size: 18pt;
    font-weight: 600;
    text-align: center;
    margin: 40px auto 54px;
    span{
      position: relative;
    }
    &::before{
      content: "";
      position: absolute;
      display: block;
      width: 50%;
      height: 16px;
      top: 50%;
      background: #00f7bd;
    }
  }

  .content{
    position: relative;
    width: 100%;
    padding: 8px;
    .cards-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-inline: 40px;
      gap: 80px;

      .card{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 185px;
        height: 160px;
        border-radius: 0px;
        border: none;
        outline: none;
        padding: 15px;
        color: #14324F;
        .titleOfCard{
          font-size: 13pt;
          font-weight: 600;
          text-align: center;
          color:#14324F;
          margin-bottom: 15px;
        }
        .price{
          font-size: 12pt;
          font-weight: 700;
          text-align: center;
          color:#14324F;
          margin-bottom: 15px;
        }
      }

      .card-green{
        box-shadow: 20px 20px 0px 2px rgba(0,247,189,0.46);
        background-color: rgba(0,247,189, 1);
      }
      .card-blue{
        box-shadow: 20px 20px 0px 2px rgba(20, 217, 245,0.2);
        background-color: rgba(20, 217, 245, 1);
      }
    }

    .pdf-table-wrapper{
      margin-top: 80px;
      margin-left: 54px;
      margin-right: 54px;
      table, th, td {
        border: 2px solid black;
        border-collapse: collapse;
        text-align: center;
        min-width: 120px;
        font-size: 12pt;
        font-weight: 600;
      }

      .greenTD{
        background-color: #01F6BB;
      }

    }
  }
  .footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 2;
    font-size: 10pt;
    color: #14324F;
    font-weight: 400;
    text-align: center;
    ul{
      list-style-type: none;
    }
  }
}
</style>