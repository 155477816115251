<template>
  <div style="height: 100%" class="d-flex flex-column align-center justify-center">
    <v-card style="width: 55%">
      <v-toolbar class="rounded-t-lg" :style="{ background: $styles.background }" dark>
        <v-card-title class="pl-0">Formulaire Visite Installation</v-card-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="formACD">
          <v-form ref="formAdresseACD" class="d-flex flex-row">
            <v-text-field dense v-model="vi.title" placeholder="Visite Installation n°1" label="Titre visite installation"
              outlined></v-text-field>
          </v-form>
          <div class="d-flex flex-row">
            <v-text-field class="px-2" dense type="date" :min="new Date().toISOString().split('T')[0]" :rules="[dateDebutLower]" v-model="vi.dateDebut"
              label="Date de debut" outlined></v-text-field>
            <v-text-field dense class="px-2" type="date" :rules="[dateFinHigher]" v-model="vi.dateFin" label="Date de fin"
              outlined></v-text-field>
            <!--<DayPicker
              class="px-2"
              v-for="(time, index) in timeSlot"
              :key="'day' + index"
              :labelDate="time.label"
              :date="time.date"
              @DaysPicker="getDate($event, index)"
            />-->
          </div>
          <div class="d-flex flex-row">
            <v-text-field dense class="px-2" type="time" :rules="[timeStartLower]" v-model="vi.timeStart"
              label="Heure de debut" outlined></v-text-field>
            <v-text-field dense class="px-2" type="time" :rules="[timeEndHigher]" v-model="vi.timeEnd" label="Heure de fin"
              outlined></v-text-field>
            <!--<TimePicker
              class="px-2"
              v-for="(time, index) in timeSlot"
              :key="'time' + index"
              :label="time.labelTime"
              :time="time.time"
              @TimePicker="getTime($event, index)"
            />-->
          </div>
  
          <div class="d-flex flex-row">
              <v-autocomplete
                v-model="vi.selectTechnicien"
                :items="listTechnicien"
                item-text="username"
                item-value="idUser"
                dense
                class="px-2"
                placeholder="Affecter un technicien"
                label="Affecter un technicien"
                outlined
              ></v-autocomplete>
              <v-autocomplete
                  v-model="vi.selectIngenieur"
                  :items="listIngenieur"
                  item-text="username"
                  item-value="idUser"
                  dense
                  class="px-2"
                  placeholder="Affecter un ingenieur"
                  label="Affecter un ingenieur"
                  outlined
                ></v-autocomplete>
            </div>

          
          <v-autocomplete
            v-if="showSocieteSelect"
            v-model="vi.societe"
            :loading="isLoading"
            :items="flattenCompteurs"
            hide-no-data
            hide-selected
            item-text="raisonSociale"
            item-value="id"
            label="Societe"
            placeholder="Selectionner une Societe"
            dense
            outlined
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.raisonSociale }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.numCompteur }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>

          <v-textarea no-resize outlined v-model="vi.description" label="Description">
          </v-textarea>
          <div class="d-flex flex-row">
            <v-btn class="px-2" color="primary" @click="ajouterVisiteInstallation">Ajouter</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import DayPicker from "@/components/VisiteTechnique/DayPicker";
import TimePicker from "@/components/VisiteTechnique/TimePicker";
import axios from "@/plugins/axios";
export default {
  name: "ajoutVisiteInstallation",
  components: { DayPicker, TimePicker },
  data() {
    return {
      isLoading: false,
      showSocieteSelect: false,
      vi: {
        title: "",
        dateDebut: "",
        dateFin: "",
        timeStart: "",
        timeEnd: "",
        description: "",
        selectTechnicien: "",
        selectIngenieur: "",
        societe: "",
      },
      listTechnicien: [],
      listIngenieur: [],
      id_devis: "",
      listSociete: [],
      timeSlot: [
        {
          date: "",
          label: "Date de debut",
          time: "",
          labelTime: "Heure de debut",
        },
        {
          date: "",
          label: "Date de fin",
          time: "",
          labelTime: "Heure de fin",
        },
      ],
    };
  },
  mounted() {
    this.showSocieteSelect = !this.$route.query.id_devis;
    this.id_devis = this.$route.query.id_devis;
  },
  async created() {
    await this.getAllSociete();
    await this.getAllTechnicien();
    await this.getAllIngenieur();
  },
  methods: {
    async ajouterVisiteInstallation() {
      var formData = new FormData();
      formData.append("token", this.$cookies.get("token"));
      formData.append("VI", JSON.stringify(this.vi));
      formData.append("id_devis", this.id_devis);
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "ajouterVisiteInstallation/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.status == true) {
          } else {
            this.listeTech = []
          }
        })
        .catch((err) => {
        });
    },
    // get all societe demande de cotation
    async getAllSociete() {
      await fetch(process.env.VUE_APP_URL_API_CLIENT + "getDemandeCotationForForum/", {
        method: "POST",
        body: JSON.stringify({
          token: this.$store.getters.getUserToken,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false && data.data.length > 0) {
            this.listSociete = data.data;
          }
        }).catch((error)=> console.log("error: ", error));
    },
    // get all technicien
    async getAllTechnicien() {
      await fetch(process.env.VUE_APP_URL_API_CLIENT + "getAllTechnicien/", {
        method: "POST",
        body: JSON.stringify({
          token: this.$store.getters.getUserToken,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false && data.result.length > 0) {
            for (let i = 0; i < data.result.length; i++) {
              this.listTechnicien.push({ username: data.result[i].nom + " " + data.result[i].prenom, idUser: data.result[i].id });
            }
          }
        });
    },
    // get all ingenieur
    async getAllIngenieur() {
      await fetch(process.env.VUE_APP_URL_API_CLIENT + "getAllIngenieur/", {
        method: "POST",
        body: JSON.stringify({
          token: this.$store.getters.getUserToken,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false && data.result.length > 0) {
            for (let i = 0; i < data.result.length; i++) {
              this.listIngenieur.push({ username: data.result[i].nom + " " + data.result[i].prenom, idUser: data.result[i].id });
            }
          }
        });
    },
    getDate(dateReceive, index) {
      if (index == 0) {
        this.vi.dateDebut = dateReceive
      }
      if (index == 1) {
        this.vi.dateFin = dateReceive
      }
      this.timeSlot[index].date = dateReceive;
      if (this.timeSlot[0].date > this.timeSlot[1].date) {
        let temp = this.timeSlot[0].date;
        this.timeSlot[0].date = this.timeSlot[1].date;
        this.timeSlot[1].date = temp;
      }
    },
    getTime(dateReceive, index) {
      if (index == 0) {
        this.vi.timeStart = dateReceive
      }
      if (index == 1) {
        this.vi.timeEnd = dateReceive
      }
      this.timeSlot[index].time = dateReceive;
      const [hours1, minutes1] = this.timeSlot[0].time.split(":").map(Number);
      const [hours2, minutes2] = this.timeSlot[1].time.split(":").map(Number);
      if (hours1 > hours2) {
        let temp = this.$dayjs().set("hour", hours1).set("minute", minutes1);
        let temp1 = this.$dayjs().set("hour", hours2).set("minute", minutes2);
        this.timeSlot[1].time = temp.format("HH:mm");
        this.timeSlot[0].time = temp1.format("HH:mm");
      } else if (hours1 == hours2) {
        if (minutes1 > minutes2) {
          let temp = this.$dayjs().set("hour", hours1).set("minute", minutes1);
          let temp1 = this.$dayjs().set("hour", hours2).set("minute", minutes2);
          this.timeSlot[1].time = temp.format("HH:mm");
          this.timeSlot[0].time = temp1.format("HH:mm");
        }
      }
    },
  },
  computed: {
    dateDebutLower() {
      if (this.vi.dateFin !== "" && this.vi.dateDebut > this.vi.dateFin) {
        return "La date de début doit être inférieure ou égale à la date de fin";
      }
      return true;
    },
    dateFinHigher() {
      if (this.vi.dateDebut !== "" && this.vi.dateFin < this.vi.dateDebut) {
        return "La date de fin doit être supérieure ou égale à la date de début";
      }
      return true;
    },
    timeStartLower() {
      if (this.vi.timeEnd !== "" && this.vi.dateDebut === this.vi.dateFin && this.vi.timeStart > this.vi.timeEnd) {
        return "L'heure de début doit être inférieure";
      }
      return true;
    },
    timeEndHigher() {
      if (this.vi.timeStart !== "" && this.vi.dateDebut === this.vi.dateFin && this.vi.timeEnd < this.vi.timeStart) {
        return "L'heure de fin doit être supérieure";
      }
      return true;
    },
    
    flattenCompteurs() {
      return this.listSociete.flatMap(societe =>
        societe.Compteur.map(compteur => ({
          id: societe.id,
          raisonSociale: societe.raisonSociale,
          numCompteur: compteur.numCompteur
        }))
      );
    },
  },
  watch: {
    
  }
};
</script>
