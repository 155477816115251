<template>
  <v-row
    cols="12"
    class="d-flex flex-row justify-center ma-2 main-container"
    :style="openNav ? 'margin-right:400px !important' : 'margin-right:0px !important'"
  >
    <Loading
      v-if="loadingPage"
      :dialogProps="dialogPage"
      messageDialogeProps="erreur"
      :loadingProps="loadingPage"
      :errorProps="false"
    />
    <vue-gallery-slideshow
      :images="images"
      :index="visibleImageIndex"
      @close="closeSlideShow"
    ></vue-gallery-slideshow>

    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
      <!-- **************************************************** -->
      <!-- ************* Questions Validation Devis *********** -->
      <!-- **************************************************** -->
      <!--<QuestionFaisabiliteNew ref="QuestionsFaisabilite" :vtId="VT_id" />-->
      <TestQuestionFaisabiliteNew ref="QuestionsFaisabilite" :vtId="VT_id" />
    </v-col>

    <!-- side bar for comments -->
    <div class="drawer-container">
      <div
        class="sidebar pb-4"
        :style="openNav ? 'width:400px !important' : 'width:0px !important'"
      >
        <div class="sidebar-content pa-2">
          <div class="comments-wrapper pb-4">
            <h6
              class="comments-title Poppins-Bold py-4 px-2"
              :style="'background-color:' + $colors[0]"
            >
              <v-icon color="#fff">mdi-forum-outline</v-icon
              ><span class="f-w-700"> - VT Questions/Réponses:</span>
            </h6>
            <QuestionsAnswersList
              :listQuestions="VTQuestions"
              @openSlideShow="handleOpenSlideShow"
            />
          </div>
          <div v-if="cheminementQuestions?.length > 0" class="comments-wrapper pb-4">
            <div v-for="(cheminementObj, index) in cheminementQuestions">
              <div v-if="cheminementObj?.questions?.length > 0">
                <h6
                  class="comments-title Poppins-Bold py-4 px-2"
                  :style="'background-color:' + $colors[0]"
                >
                  <v-icon color="#fff">mdi-forum-outline</v-icon
                  ><span class="f-w-700"> - Cheminement Questions/Réponses:</span>
                </h6>
                <QuestionsAnswersList
                  :listQuestions="cheminementObj.questions"
                  @openSlideShow="handleOpenSlideShow"
                />
              </div>
              <div v-if="cheminementObj?.cheminementComp?.questions?.length > 0">
                <h6
                  class="comments-title Poppins-Bold py-4 px-2"
                  :style="'background-color:' + $colors[0]"
                >
                  <v-icon color="#fff">mdi-forum-outline</v-icon
                  ><span class="f-w-700"> - Cheminement Comp Questions/Réponses:</span>
                </h6>
                <QuestionsAnswersList
                  :listQuestions="cheminementObj.cheminementComp.questions"
                  @openSlideShow="handleOpenSlideShow"
                />
              </div>
              <div v-if="cheminementObj?.cheminementTic?.questions?.length > 0">
                <h6
                  class="comments-title Poppins-Bold py-4 px-2"
                  :style="'background-color:' + $colors[0]"
                >
                  <v-icon color="#fff">mdi-forum-outline</v-icon
                  ><span class="f-w-700"> - Cheminement TIC Questions/Réponses:</span>
                </h6>
                <QuestionsAnswersList
                  :listQuestions="cheminementObj.cheminementTic.questions"
                  @openSlideShow="handleOpenSlideShow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        :style="openNav ? 'right:320px !important' : 'right:-80px'"
        class="openbtn"
        @click="openNav = !openNav"
      >
        ☰ Questions/Réponses
      </button>
    </div>
  </v-row>
</template>

<script>
import Loading from "@/components/Loading.vue";
import axios from "@/plugins/axios";
import TestQuestionFaisabiliteNew from "./components/TestQuestionFaisabiliteNew.vue";
import QuestionFaisabiliteNew from "./components/QuestionFaisabiliteNew.vue";
import QuestionsAnswersList from "./components/QuestionsAnswersList.vue";
import VueGallerySlideshow from "vue-gallery-slideshow";

export default {
  name: "FormulaireFaisabiliteNew",
  components: {
    Loading,
    QuestionFaisabiliteNew,
    QuestionsAnswersList,
    VueGallerySlideshow,
    TestQuestionFaisabiliteNew,
  },
  data() {
    return {
      visibleImageIndex: null,
      images: [],

      maxNumberFile: 3,
      fileRequired: false,
      loadingPage: false,
      dialogPage: false,
      VTQuestions: [],
      cheminementQuestions: [],
      QuestionsData: {},
      newArrayMultiChoice: [],
      baseURL: "",
      VT_id: null,
      panel: [],
      listTechnicien: [],
      searchTechnicien: false,
      VisiteTechnique: {},
      comment: "",
      openNav: true,
    };
  },
  mounted() {
    this.baseURL = process.env.VUE_APP_URL_API_CLIENT_BASE_URL;
  },
  computed: {
    userId() {
      return this.$store.getters.getUserId;
    },
    roleUser() {
      return this.$store.getters.getUserRole;
    },
  },
  async created() {
    // Access the ID from the URL
    this.VT_id = this.$route.params.id;
    await this.getData();
  },
  methods: {
    async getData() {
      this.loadingPage = true;
      this.dialogPage = true;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + `get_devis_for_validation/`,
        method: "POST",
        data: {
          token: this.$store.getters.getUserToken,
          vtId: this.VT_id,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.VisiteTechnique = response.data.vt;
            this.VTQuestions = response.data.vt.questions;
            this.cheminementQuestions = response.data.vt.cheminement;
            this.QuestionsData = response.data.devis;
          }
        })
        .catch((errors) => {
          
        });
      this.loadingPage = false;
      this.dialogPage = false;
    },
    handleOpenSlideShow(props) {
      this.visibleImageIndex = props.index;
      this.images = props.images;
    },
    closeSlideShow() {
      this.visibleImageIndex = null;
      this.images = [];
    },
  },
};
</script>

<style scoped>
/****************************** */
/******** main content ******** */
/****************************** */

.info-content-container {
  display: flex;
  flex-direction: column;
}
.info-content {
  display: flex;
  flex-direction: row;
}
.info-content-title {
  font-size: 14px;
  font-weight: 400;
  color: #a5abbb;
  margin-bottom: 10px;
}
.info-content-info {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}
/*-----------*/
.section-title {
  font-size: 16px;
  font-weight: 400;
  color: #007991;
  margin-bottom: 20px;
}
.section-title .text {
  margin-bottom: 10px;
}
.devider {
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
}

.image-card {
  width: 180px;
  max-width: 180px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  margin: 8px;
}

/********** comment drawer ************* */
::v-deep .v-label {
  margin: 0px !important;
}

.my-drawer {
  top: 64px !important;
  height: 100% !important;
  max-height: 100% !important;
}

.drawer-container {
  position: relative;
  height: calc(100vh - 64px);
  overflow-y: auto;
}

.sidebar {
  height: 100% !important;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 64px;
  right: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: 0px 4px 16px -4px #979797;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  position: fixed;
  top: 143px;
  right: -60px;
  font-size: 15px;
  cursor: pointer;
  background-color: #007991;
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 4px 4px 0px 0px;
  border: none;
  z-index: 99;
  transform: rotateZ(-90deg);
  transition: 0.5s;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }

  .sidebar a {
    font-size: 18px;
  }
}

.input-comment-label {
  font-size: 14px;
  font-weight: 400;
}
.input-comment {
  font-size: 14px;
  font-weight: 400;
}
.comments-wrapper {
  margin-top: 32px;
  margin-bottom: 32px;
}
.comments-title {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  border-bottom: 1px solid #d8e7ec;
  padding-bottom: 8px;
  margin-bottom: 20px;
}
.comment-content-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-radius: 4px;
  padding: 8px 0px;
  margin-bottom: 8px;
  border-bottom: 1px solid #e2e2e2;
}
.comment-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.comment-content-username {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 4px;
  color: #14324f;
}
.comment-content-username-logo {
  width: 28px;
  height: 28px;
  background: #028f70;
  border-radius: 50%;
  margin-right: 4px;
}
.comment-content-text {
  font-size: 12px;
  font-weight: 400;
  color: #14324f;
  margin-bottom: 4px;
}
</style>
