<template>
  <div class="ma-4">
    <div style="width: 100%; overflow-x: auto">
      <TabSlider v-model="typeClient">
        <template v-slot="{ selectedTab }">
          <div v-if="selectedTab === 'Pro'">Pro</div>
          <div v-else>Particulier</div>
        </template></TabSlider
      >
      <datatable
        id="table-validation-cotation"
        :columns="columns"
        :sortKey="sortKey"
        :sortOrders="sortOrders"
        @sort="sortBy"
        @search="rechercheData"
      >
        <template v-slot:body>
          <tbody
            v-for="(project, index) in projects"
            :key="project.id"
            class="text-center"
            v-if="loadingTable == false"
          >
            <tr>
              <td style="vertical-align: middle">
                <router-link :to="`/devis/profil-devis/${project.id}`">
                  <v-icon :color="$colors[0]"> mdi-eye </v-icon>
                </router-link>
              </td>
              <td style="vertical-align: middle">
                {{ project.dateAjout }}
              </td>

              <td style="vertical-align: middle">{{ project.marque }}</td>
              <td style="vertical-align: middle">{{ project.montage }}</td>
              <td
                style="vertical-align: middle"
                v-if="project.type_client == 'Pro'"
              >
                <div class="d-flex flex-column">
                  <router-link :to="`/detail-societe/${project.societe}`">
                    <small>{{ project.raison }}</small>
                  </router-link>
                  <small>{{ project.siret }}</small>
                </div>
              </td>
              <td v-if="project.type_client == 'Particulier'">
                <router-link
                  :to="`/detail-clientp/${project.idProfileParticulier}`"
                >
                  {{ project.profileParticulier }}
                </router-link>
              </td>
              <td style="vertical-align: middle">
                <router-link :to="`/detail-compteur/${project.compteur}`">
                  <small>{{ project.numCompteur }}</small>
                </router-link>
              </td>
              <td style="vertical-align: middle">
                <small
                  >{{
                    Number(
                      project.devisCalcul?.tvaResultat?.TotalHT
                    ).toLocaleString("fr-FR")
                  }}
                  €</small
                >
              </td>

              <td style="vertical-align: middle" class="text-center">
                <div class="d-flex flex-column btn-group">
                  <div class="d-flex flex-row justify-center">
                    <small
                      class="small-status"
                      v-if="project.status == 'refusee'"
                      ><v-icon dense style="color: #ff0000"
                        >mdi-close-circle</v-icon
                      ><b class="ml-2">refusé</b></small
                    >
                    <small
                      class="small-status"
                      v-if="project.status == 'annuler'"
                      ><v-icon dense style="color: #ff0000"
                        >mdi-file-cancel-outline</v-icon
                      ><b class="ml-2">annulé</b></small
                    >
                    <small
                      class="small-status"
                      v-if="project.status == 'en_attend'"
                      ><v-icon dense style="color: #2080ee"
                        >mdi-clock-outline</v-icon
                      ><b class="ml-2" style="word-break: keep-all"
                        >en attente</b
                      ></small
                    >
                    <small
                      class="small-status"
                      v-if="project.status == 'en_cours'"
                      ><v-icon dense style="color: orange"
                        >mdi-progress-clock</v-icon
                      ><b class="ml-2">en cours</b></small
                    >
                    <small
                      class="small-status"
                      v-if="project.status == 'pre_valider'"
                      ><v-icon dense style="color: #9c27b0"
                        >mdi-progress-check</v-icon
                      ><b class="ml-2">pré-validé</b></small
                    >
                    <small
                      class="small-status"
                      v-if="project.status == 'valider'"
                      ><v-icon dense style="color: #00e676"
                        >mdi-check-circle-outline</v-icon
                      ><b class="ml-2" style="word-wrap: normal"
                        >validé</b
                      ></small
                    >
                    <small
                      class="small-status"
                      v-if="project.status == 'signer'"
                      ><v-icon dense style="color: rgb(134, 29, 90)"
                        >mdi-file-sign</v-icon
                      ><b class="ml-2" style="word-wrap: normal"
                        >signé</b
                      ></small
                    >
                    <small
                      class="small-status"
                      v-if="project.status == 'envoyer_signiature'"
                      ><v-icon dense style="color: rgb(114, 85, 7)"
                        >mdi mdi-send-clock</v-icon
                      ><b class="ml-2" style="word-wrap: normal"
                        >envoie signature</b
                      ></small
                    >
                    <small
                      class="small-status"
                      v-if="project.status == 'renitialiser'"
                      ><v-icon dense style="color: rgb(121, 166, 196)"
                        >mdi mdi-lock-reset</v-icon
                      ><b class="ml-2" style="word-wrap: normal"
                        >réinitialiser</b
                      ></small
                    >

                    <div
                      class="d-flex flex-row"
                      v-if="showActionsBtns(project.status)"
                    >
                      <v-icon
                        class="dropdown-toggle"
                        data-toggle="collapse"
                        :data-target="'#collapseStatut' + index"
                        aria-expanded="false"
                        :aria-controls="'collapseStatut' + index"
                        size="20"
                        left
                      >
                        mdi-menu-down
                      </v-icon>
                    </div>
                  </div>
                  <div
                    class="collapse"
                    :id="'collapseStatut' + index"
                    style="border: 0px"
                    v-if="showActionsBtns(project.status)"
                  >
                    <div class="d-flex flex-row mt-4 mt-lg-2">
                      <v-btn
                        title="Refus de devis"
                        @click="
                          ActionsBtns(
                            project.id,
                            'refusee',
                            `Vous êtes sur le point de refuser ce devis !`
                          )
                        "
                        class="dropdown-project"
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'BORNE_FAISABILITE',
                            project.status,
                            'refusee'
                          )
                        "
                        icon
                        small
                        plain
                      >
                        <v-icon color="red"> mdi-close-circle </v-icon>
                      </v-btn>
                      <!-- annulé -->
                      <v-btn
                        title="Annulation de devis"
                        @click="
                          ActionsBtns(
                            project.id,
                            'annuler',
                            `Vous êtes sur le point d'annuler ce devis`
                          )
                        "
                        class="dropdown-project"
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'BORNE_FAISABILITE',
                            project.status,
                            'annuler'
                          )
                        "
                        icon
                        small
                        plain
                      >
                        <v-icon color="red"> mdi-cancel </v-icon>
                      </v-btn>
                      <!-- renitialiser-->
                      <v-btn
                        title="Réinitialisation de devis"
                        @click="
                          ActionsBtns(
                            project.id,
                            'en_attend',
                            `Vous êtes sur le point de réinitialiser ce devis!`
                          )
                        "
                        class="dropdown-project"
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'BORNE_FAISABILITE',
                            project.status,
                            'renitialiser'
                          )
                        "
                        icon
                        small
                        plain
                      >
                        <v-icon color="grey accent-5">
                          mdi mdi-lock-reset
                        </v-icon>
                      </v-btn>
                      <!-- signé button -->
                      <v-btn
                        title="Signer ce devis"
                        @click="
                          ActionsBtns(
                            project.id,
                            'signer',
                            `Vous êtes sur le point de signer ce devis`
                          )
                        "
                        class="dropdown-project"
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'BORNE_FAISABILITE',
                            project.status,
                            'signer'
                          )
                        "
                        icon
                        small
                        plain
                      >
                        <v-icon color="rgb(134, 29, 90)"> mdi mdi-draw </v-icon>
                      </v-btn>

                      <!-- envoyer signiature button -->
                      <v-btn
                        title="Envoie de signature"
                        @click="
                          ActionsBtns(
                            project.id,
                            'envoyer_signiature',
                            `Vous êtes sur le point d'envoyer une signature pour un devis`
                          )
                        "
                        class="dropdown-project"
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'BORNE_FAISABILITE',
                            project.status,
                            'envoyer_signiature'
                          )
                        "
                        icon
                        small
                        plain
                      >
                        <v-icon color="grey accent-5">
                          mdi mdi-send-clock
                        </v-icon>
                      </v-btn>
                      <v-btn
                        title="Pré-validation de devis"
                        @click="
                          ActionsBtns(
                            project.id,
                            'pre_valider',
                            `Vous êtes sur le point de pré-valider ce devis`
                          )
                        "
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'BORNE_FAISABILITE',
                            project.status,
                            'pre_validation'
                          )
                        "
                        class="dropdown-project"
                        icon
                        small
                        plain
                      >
                        <v-icon color="#9C27B0"> mdi-eye-check </v-icon>
                      </v-btn>
                      <v-btn
                        title="Validation de devis"
                        @click="
                          ActionsBtns(
                            project.id,
                            'valider',
                            `Vous êtes sur le point de valider ce devis!`
                          )
                        "
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'BORNE_FAISABILITE',
                            project.status,
                            'valider'
                          )
                        "
                        class="dropdown-project"
                        icon
                        small
                        plain
                      >
                        <v-icon color="green accent-3">
                          mdi-check-circle
                        </v-icon>
                      </v-btn>
                      <v-btn
                        title="Création d'une visite technique pour un devis"
                        @click="CreerUneVisteTechnique(project.id)"
                        class="dropdown-project"
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'BORNE_FAISABILITE',
                            project.status,
                            'create_VT'
                          )
                        "
                        icon
                        small
                        plain
                      >
                        <v-icon color="blue accent-5"> mdi mdi-toolbox </v-icon>
                      </v-btn>

                      <v-btn
                        title="Creer Viste Installation"
                        @click="CreerUneVisteInstallation(project.id)"
                        class="dropdown-project"
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'BORNE_FAISABILITE',
                            project.status,
                            'create_VI'
                          )
                        "
                        icon
                        small
                        plain
                      >
                        <v-icon color="green accent-5">
                          mdi-hammer-screwdriver
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </td>
              <td style="vertical-align: middle" class="text-center">
                <div class="d-flex justify-content-center align-items-center">
                  <v-btn
                    v-if="!!projects[index]"
                    style="position: sticky"
                    text
                    color="blue darken-4"
                    data-toggle="collapse"
                    :data-target="'#multiCollapseVente' + index"
                    aria-expanded="false"
                    :aria-controls="'multiCollapseVente' + index"
                  >
                    <span>{{
                      projects[index].selected.length == 1
                        ? "1 site"
                        : `${projects[index].selected.length} sites`
                    }}</span>
                    <v-icon class="ml-2" size="17"> mdi-plus </v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
            <tr
              class="collapse multi-collapse"
              :id="'multiCollapseVente' + index"
            >
              <td id="subtd" colspan="11" class="padding-phone">
                <table width="100%" height="100%">
                  <thead
                    class="white--text"
                    :style="`background:${$styles.background}`"
                  >
                    <tr>
                      <th
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 10%;
                        "
                      >
                        MARQUE
                      </th>
                      <th
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 10%;
                        "
                      >
                        PDR
                      </th>
                      <th
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 10%;
                        "
                      >
                        MONTAGE
                      </th>
                      <th
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 10%;
                        "
                      >
                        PRIX BORNE
                      </th>
                      <th
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 10%;
                        "
                      >
                        MATERIEL
                      </th>
                      <th
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 10%;
                        "
                      >
                        Variable/VRD
                      </th>
                      <th
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 10%;
                        "
                      >
                        PROD
                      </th>
                      <!-- <th style="text-align: center; vertical-align: middle; width: 10%">
                        TOTAL HTA <br /><span>NON SIGNALÉTIQUE</span>
                      </th> -->
                      <th
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 10%;
                        "
                      >
                        TOTAL HTA <span>SIGNALÉTIQUE</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="!!projects[index]">
                    <tr v-for="(v, ind) in projects[index].selected">
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>{{ v.marque }}</span>
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>{{ v.type_point_recharge }}</span>
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>{{ v.montage }}</span>
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span
                            >{{
                              Number(v.prix_borne).toLocaleString("fr-FR")
                            }}
                            €</span
                          >
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span
                            >{{
                              Number(v.materiel).toLocaleString("fr-FR")
                            }}
                            €</span
                          >
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span
                            >{{
                              Number(v.Variable).toLocaleString("fr-FR")
                            }}
                            €</span
                          >
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span
                            >{{
                              Number(v.prod).toLocaleString("fr-FR")
                            }}
                            €</span
                          >
                        </div>
                      </td>
                      <!-- 
                        TOTAL HTA NON SIGNALÉTIQUE
                        <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span
                            >{{
                              Number(v.totalTTCNonSignaletique).toLocaleString("fr-FR")
                            }}
                            €</span
                          >
                        </div>
                      </td> -->
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span
                            >{{
                              Number(v.totalTTCSignaletique).toLocaleString(
                                "fr-FR"
                              )
                            }}
                            €</span
                          >
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>Allimentation</span>
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>--</span>
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>--</span>
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>--</span>
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span
                            >{{
                              Number(
                                projects[index].devisCalcul
                                  .resultatAllimentation?.Matrielle
                              ).toLocaleString("fr-FR")
                            }}
                            €</span
                          >
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span
                            >{{
                              Number(
                                projects[index].devisCalcul
                                  .resultatAllimentation?.VRD
                              ).toLocaleString("fr-FR")
                            }}
                            €</span
                          >
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>--</span>
                        </div>
                      </td>
                      <!-- 
                        TOTAL HTA NON SIGNALÉTIQUE
                        <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>{{ Number(projects[index].devisCalcul.resultatAllimentation.totalHTA).toLocaleString("fr-FR") }} €</span>
                        </div>
                      </td> -->
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span
                            >{{
                              Number(
                                projects[index].devisCalcul
                                  .resultatAllimentation?.totalHTA
                              ).toLocaleString("fr-FR")
                            }}
                            €</span
                          >
                        </div>
                      </td>
                    </tr>
                    <!-- Materiel Auxiliaire -->
                    <tr
                      v-if="
                        projects[index].devisCalcul.resultatMaterielAuxiliaire
                      "
                    >
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>Autres</span>
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>--</span>
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>--</span>
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>--</span>
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>--</span>
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>--</span>
                        </div>
                      </td>
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span>--</span>
                        </div>
                      </td>
                      <!-- 
                      TOTAL HTA NON SIGNALÉTIQUE
                      <td style="width: 10%" id="subtd-1">
                      <div class="td-table">
                        <span>{{ Number(projects[index].devisCalcul?.resultatMaterielAuxiliaire?.totalHTA).toLocaleString("fr-FR") }} €</span>
                      </div>
                    </td> -->
                      <td style="width: 10%" id="subtd-1">
                        <div class="td-table">
                          <span
                            >{{
                              Number(
                                projects[index].devisCalcul
                                  ?.resultatMaterielAuxiliaire?.totalHTA
                              ).toLocaleString("fr-FR")
                            }}
                            €</span
                          >
                        </div>
                      </td>
                    </tr>
                    <!-- END Materiel Auxiliaire -->
                    <td style="width: 100%" colspan="9">
                      <noDataTable
                        style="width: 100%"
                        v-if="
                          projects[index].selected.length == 0 &&
                          loadingTable == false
                        "
                      />
                    </td>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
          <td v-if="loadingTable == true" colspan="8" style="width: 100%">
            <progressCircular />
          </td>
          <td
            v-if="projects.length == 0 && loadingTable == false"
            style="width: 100%"
            colspan="8"
          >
            <noDataTable style="width: 100%" />
          </td>
        </template>
      </datatable>
    </div>
    <div
      class="d-flex flex-row justify-content-center justify-sm-start align-items-center"
    >
      <pagination
        v-if="projects.length > 0"
        :pagination="pagination"
        @selectNumberOfRecords="rechercheData"
        @first="getData(pagination.firstPage)"
        @last="getData(pagination.lastPage)"
        @prev="getData(pagination.prevPageUrl)"
        @current="getData(pagination.currentPage)"
        @next="getData(pagination.nextPageUrl)"
      >
      </pagination>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Datatable from "@/components/dataTable/Datatable.vue";
import Pagination from "@/components/dataTable/Pagination.vue";
import noDataTable from "@/components/no-data-table.vue";
import progressCircular from "@/components/progress-circular.vue";
import TabSlider from "@/components/TabSlider.vue";

export default {
  name: "table-devis",
  components: { Datatable, Pagination, noDataTable, progressCircular,TabSlider },
  data() {
    let typeClient = "Pro";

    return {
      typeClient: typeClient,
      filterStatus: "tout",
      sortKey: "date",
      perPage: ["15", "20", "30"],
      tableData: {
        statusFilter: "",
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
      },
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      expanded: [],
      singleExpand: false,
      sortOrders: {},
      projects: [],
      projects: [],
      loadingTable: false,

      columns: [
        {
          width: "10%",
          label: "Profil devis",
          name: "profil_devis",
          sortable: true,
        },
        {
          width: "10%",
          label: "Date d'Ajout",
          name: "dateAjout",
          sortable: true,
        },
        { width: "10%", label: "Marque", name: "marque", sortable: true },
        { width: "10%", label: "Montage", name: "montage", sortable: true },
        {
          width: "10%",
          label: typeClient === "Pro" ? "Raison Sociale" : "Particulier",
          name: "Raison",
          sortable: false,
        },
        {
          width: "10%",
          label: "Numéro de compteur",
          name: "numCompteur",
          sortable: false,
        },
        {
          width: "10%",
          label: "TOTAL HTA",
          name: "SIGNALETIQUE",
          sortable: false,
        },
        { width: "10%", label: "Statut", name: "status", sortable: false },

        {
          width: "10%",
          label: "OFFRES",
          name: "actions",
          sortable: false,
        },
      ],
    };
  },
  async created() {
    await this.getData({ pageNumber: 0 });
  },

  computed: {
    showActionsBtns() {
      return (status) => {
        // A MODIFIER EN FONCTION DU BACK
        return (
          this.$store.getters.hasPermissionByStatus(
            "BORNE_FAISABILITE",
            status,
            "valider"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "BORNE_FAISABILITE",
            status,
            "renitialiser"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "BORNE_FAISABILITE",
            status,
            "annuler"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "BORNE_FAISABILITE",
            status,
            "signer"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "BORNE_FAISABILITE",
            status,
            "envoyer_signiature"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "BORNE_FAISABILITE",
            status,
            "refusee"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "BORNE_FAISABILITE",
            status,
            "pre_validation"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "BORNE_FAISABILITE",
            status,
            "create_VT"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "BORNE_FAISABILITE",
            status,
            "create_VI"
          )
        );
      };
    },
  },
  watch: {
    typeClient(newValue) {
      this.updateColumnLabel(newValue);
      this.rechercheData({ type: "search", key: this.tableData?.search || "" });
    },
  },
  methods: {
    CreerUneVisteTechnique(id_devis) {
      const props = {
        id_devis: id_devis,
      };
      this.$router.push({
        path: "/visite-technique/nouveau-formulaire",
        query: props,
      });
    },
    CreerUneVisteInstallation(id_devis) {
      const props = {
        id_devis: id_devis,
      };
      this.$router.push({
        path: "/visite-installation/ajoutVisiteInstallation",
        query: props,
      });
    },
    updateColumnLabel(typeClient) {
      const column = this.columns.find((col) => col.name === "Raison");
      if (column) {
        if (typeClient === "Pro") {
          column.label = "Raison Sociale";
        } else if (typeClient === "Particulier") {
          column.label = "Particulier";
        }
      }
    },
    ActionsBtns(id, status, actionMessage) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: actionMessage,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007991",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Je Confirme",
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = true;
          this.loading = true;
          this.error = false;
          var formData = new FormData();
          formData.append("id", id);
          formData.append("status", status);
          formData.append("token", this.$store.getters.getUserToken);
          axios({
            url:
              process.env.VUE_APP_URL_API_CLIENT +
              "changeFaisabiliteBornStatus/",
            method: "POST",
            data: formData,
          })
            .then((res) => {
              if (res.status == 200) {
                this.getData(this.pagination.currentPage);
                
              }
            })
            .catch((err) => {
              
            });
        }
      });
    },
    redirectProfilDevis(id) {
      this.$router.push({ path: `/devis/presentation-devis/${id}` });
    },
    filterByStatus() {
      var pageNumber = 1;
      if (this.filterStatus != "tout") {
        this.tableData.statusFilter = this.filterStatus;
        this.getData(1);
      } else {
        this.tableData.statusFilter = "";
        this.getData(1);
      }
    },
    async getData(pageNumber = 1) {
      this.loadingTable = true;
      this.tableData.draw++;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllFaisabiliteBorn/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          typeClient: this.typeClient,

          token: this.$cookies.get("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            console.log(data);
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          }
        })
        .catch((errors) => {
          
        });
      this.loadingTable = false;
      console.log("ici");
      console.log(this.loadingTable);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getData();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 1;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      }
      this.tableData.draw++;
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllFaisabiliteBorn/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          typeClient: this.typeClient,

          token: this.$cookies.get("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          }
        })
        .catch((errors) => {
          
        });
    },
  },
};
</script>
<style scoped>
.sub-table td {
  width: 11vw;
  border: 0px solid;
}
</style>
