<template>
    <div style="height: fit-content" class="col-xl-12 col-sm-12 col-12">
      <v-card style="box-shadow: none">
        <div class="card-content shadow-test" style="min-height: 140px">
          <div class="card-body">
            <div class="media d-flex">
              <div class="align-self-center">
                <v-icon color="#64CCC5" size="60" class="font-large-2 float-left">{{
                  icon
                }}</v-icon>
              </div>
              <div class="media-body text-right">
                <h5>{{ title }}</h5>
                <div class="ml-4">
                  <span :style="{ fontSize: 'x-large', color: color1 }" class="float-left"> {{ subTitle1 }} </span>
                  <span :style="{ fontSize: 'x-large', color: color1 }"
                  >{{ typeof total1 == 'number' ? Number(total1).toLocaleString("fr-FR") : total1 }} {{unite1}}</span
                >
                </div>
                <div class="ml-4">
                  <span :style="{ fontSize: 'x-large', color: color2 }" class="float-left"> {{ subTitle2 }} </span>
                  <span :style="{ fontSize: 'x-large', color: color2 }"
                  >{{ typeof total2 == 'number' ? Number(total2).toLocaleString("fr-FR") : total2 }} {{unite2}}</span
                >
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </template>
  <script>
  export default {
    name: "cardCustom2Values",
    props: ["icon", "title",
     "subTitle1", "color1", "total1", "unite1",
     "subTitle2", "color2", "total2", "unite2"
    ],
  };
  </script>
  