<template>
  <div>
    <v-form ref="formTime">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            outlined
            v-model="timeModel"
            :label="label"
            :rules="[$rules.timeRule]"
            prepend-inner-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          format="24hr"
          v-if="menu"
          v-model="timeModel"
          full-width
        > <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="sendTime"
          >
            OK
          </v-btn>
        </v-time-picker>
      </v-menu>
    </v-form>
  </div>
</template>
<script>
export default {
  name: "TimePicker",
  props: ["time", "label"],
  data() {
    return {
      menu: false,
      timeModel: this.time,
    };
  },
  watch:{
    time:function(newValue){
        
        this.timeModel = newValue;
    }
  },
  methods:{
    sendTime(value){
        if(this.$refs.formTime.validate()){
            this.$refs.menu.save(this.timeModel)
            this.$emit('TimePicker',this.timeModel)
        }
    }
  }
};
</script>
