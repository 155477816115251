<template>
  <div class="page-blanc">
    <img class="center-shape" src="../images/shapes/shape-center.png" />
    <img class="left-center-shape" src="../images/shapes/shape-1.png" />
    <img class="right-top-shape" src="../images/shapes/shape-3.png" />
    <img class="bottom-right-shape" src="../images/shapes/shape-2.png" />

    <img 
    class="logo-enffic-pdf"
    src="../images/logo-eneffic.png"
    />
    <p class="page-title">
      <span>Synthèse technique de mon projet</span>
    </p>

    <div class="content">
      <div class="mb-8">
        <p class="address-text">Addresse 1: {{ address }}</p>
        <p v-if="codePostale" class="address-text">Code Prostal: {{ codePostale }}</p>
      </div>
      <div class="content-image">
        <img v-if="imageSrc" :src="imageSrc" />
        <div v-else class="image-placeholder"></div>
      </div>
    </div>
    <div class="footer">
      <ul>
        <li>ENEFFIC - 2 Aliée du Levant 34970 Lattes</li>
        <li>Téléphone: +33 (0)4 65 84 38 76 - E-mail: contact@eneffic.fr</li>
        <li>SIREN: 951 979 798</li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "SyntheseTechniqueAddress",
  props: [
    "address",
    "codePostale",
    "imageSrc",
  ],
};
</script>

<style lang="scss" scoped>
.page-blanc{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 32px;

  .center-shape{
    position: absolute;
    width: 600px;
    height: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .left-center-shape{
    position: absolute;
    width: 140px;
    height: 220px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  .right-top-shape{
    position: absolute;
    width: 75px;
    height: 75px;
    top: 15.5%;
    right: 0;
  }

  .bottom-right-shape{
    position: absolute;
    width: 150px;
    height: 150px;
    bottom: 0;
    right: 0;
  }

  .logo-enffic-pdf{
    height: 24px;
    margin-top: 40px;
    margin-left: 40px;
  }
  
  .page-title{
    width: fit-content;
    position: relative;
    font-size: 18pt;
    font-weight: 600;
    margin: 60px auto 100px;
    span{
      position: relative;
    }
    &::before{
      content: "";
      position: absolute;
      display: block;
      width: 50%;
      height: 16px;
      top: 60%;
      left: 0;
      background: #00f7bd;
    }
  }

  .content{
    position: relative;
    width: 730px;
    padding-left: 32px;
    margin: 0 auto;
    .address-text{
      font-size: 14pt;
      font-weight: 500;
      margin-bottom: 4px;
    }
    .content-image{
      width: 100%;
      height: 850px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 100%;
        max-height: 100%;
      }
      .image-placeholder{
        width: 100%;
        height: 100%;
        background-color: #ddd;
      }
    }
  }
  .footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 2;
    font-size: 10pt;
    color: #14324F;
    font-weight: 400;
    text-align: center;
    ul{
      list-style-type: none;
    }
  }
}
</style>