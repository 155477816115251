<template>
    <v-dialog v-model="dialog" max-width="1024">
      <div class="modal-content">
        <div class="close" style="font-size: 32px" @click="$emit('close')">
          &times;
        </div>
  
        <div style="padding: 20px">
          <h2>Sélectionner Module</h2>
        </div>
        <div class="panel-options">
          <table>
            <thead>
              <tr>
                <th>Fabriquant</th>
                <th>Cote</th>
                <th>Code</th>
                <th style="width: 100px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="panel in panelOptions" :key="panel.type">
                <td>
                  <img
                    :src="require(`@/assets/solar-panel.png`)"
                    alt="panel img"
                    class="panel-image"
                  />{{ panel.type }}
                </td>
                <td>{{ panel.watts }} W</td>
                <td>{{ panel.code }}</td>
                <td><button @click="selectPanel(panel)">Sélectionner</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </v-dialog>
  </template>
  
  <script>
   import axios from "@/plugins/axios";
  export default {
   
    data() {
      return {
        panelOptions: [],
        listPanels: [],
        dialog: true,
      };
    },
    created() {
      this.getPanels();
    },
    methods: {
      selectPanel(panel) {
        const obj = {
          type: panel.type,
          watts: panel.watts,
          code: panel.code,
          width: panel.width,
          height: panel.height,
        };
        this.$emit("add-installation", obj);
        this.$emit("close");
      },
     async getPanels(){
      console.log("getPanels");
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + `get_solar_panels/`,
          method: "POST",
          headers: {
              "Content-Type": "application/json",
            },
          data: JSON.stringify({
              token: this.$store.getters.getUserToken,
           }),
          
        })
        .then((response) => {
          if (response.data.status === true) {
            //this.panelOptions = response.data.data;
            this.panelOptions = response.data.data;
            console.log("listPanels", this.listPanels);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
  };
  </script>
  
  <style scoped>
  .modal-content {
    background: white;
    border-radius: 5px;
    position: relative;
    width: 100%;
    padding-bottom: 20px;
  }
  .close {
    position: absolute;
    top: 0px;
    right: 10px;
    line-height: normal;
    cursor: pointer;
  }
  .panel-options {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
    border-left: none;
    border-right: none;
  }
  th {
    background-color: #f2f2f2;
    border-top: 1px solid #ccc;
  }
  td .panel-image {
    margin-right: 10px;
    width: 30px;
    height: auto;
    vertical-align: middle;
  }
  button {
    padding: 5px 10px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    transition: background-color 0.3s;
  }
  button:hover {
    background-color: #0056b3;
  }
  td:last-child {
    text-align: right;
  }
  </style>
  