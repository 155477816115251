<template>
  <div style="height: 100%; width: 100%; padding: 15px">
    <div>
      <div style="width: 50%" class="d-flex flex-row">
        <v-text-field
          outlined
          dense
          label="Adresse"
          :rules="[$rules.required('Adresse')]"
          v-model="recherche"
          :error="searched == false"
          :error-messages="searched == false ? ['Vérifiez l\'adresse saisie1'] : []"
        >
          <template v-slot:append>
            <v-icon :color="$colors[0]">mdi-map-marker-radius-outline</v-icon>
          </template>
        </v-text-field>
        <v-btn class="ml-4" outlined @click="findPlace" style="border: solid 2px #8aa7b4">
          <v-icon :color="$colors[0]">mdi-map-marker-outline</v-icon>
        </v-btn>
      </div>
    </div>
    <!-- container (toolbar + map) -->
    <div v-show="searched">
      <div v-show="searched" class="map-container">
        <!-- toolbar -->
        <div class="toolbar hide-scrollbar">
          <div class="project-list">
            <template v-for="installation in installations">
              <InstallationItem
                @update-area="updateArea"
                @installation_valide="handleinstallationCapture"
                :map="map"
                :installation="installation"
                :selectedInstallation="selectedInstallation"
                :longitude="longitude_area"
                :latitude="latitude_area"
                @toggleExpand="updateSelectedInstallation"
                @addPanneau="addPanneau(installation)"
                @update-orientation="updateOrientation(installation, $event)"
                @update-lossPercentage="updateLossPercentage(installation, $event)"
                @update-optimalInclinisation="
                  updateOptimalInclinisation(installation, $event)
                "
                @update-incliner="updateIncliner(installation, $event)"
                @update-production="handleUpdateProduction"
                @update-peakPower="updatePeakPower(installation, $event)"
                @togglePanneu="togglePannaux"
                @delete-installation="deleteInstallation"
                @polygon-complete="handlePolygonComplete"
                @update-inclinaison="handleUpdateInclinaison"
              />
            </template>
            <div class="add-installation-btn" @click="showInstallationModal = true">
              <span class="mdi mdi-plus"></span>
              <span>Add Installation</span>
            </div>
            <!-- <div
              class="prod-installation-btn"
              v-if="showProdButton"
              @click="sendInstallation()"
            >
              <span class="mdi mdi-plus"></span>
              <span>Calculer Production</span>
            </div> -->
            <InstallationModal
              v-if="showInstallationModal"
              @close="showInstallationModal = false"
              @add-installation="addInstallation"
            />
          </div>
        </div>

        <!-- map -->
        <div style="height: 100%">
          <div ref="map" id="map-container" class="map-wrapper">
            <div id="map"></div>
          </div>
          <div>
            <div
              ref="compass"
              class="compass"
              :style="{ transform: 'rotate(' + -compassHeading + 'deg)' }"
            >
              <p style="margin-left: 7px">{{ compassDegree }}</p>
              <div class="compass-img">
                <img src="/compass.png" height="50" width="50" />
              </div>
            </div>
          </div>
          <div>
            <div class="screenshot mt-3" @click="takeScreenshot">
              <span class="mdi mdi-camera"></span>
            </div>
            <!-- button for dispalying all the installation on the map-->

            <div
              v-if="Object.keys(polygons).length >= 1 && installations.length > 1"
              class="showAll"
              @click="showAllPolygons"
            >
              <span v-if="showAllGridEyes == true" class="mdi mdi-eye-off-outline"></span>
              <span v-else class="mdi mdi-eye-outline"></span>
            </div>
            <!-- Affichage de la taille des polygones -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import html2canvas from "html2canvas";
import InstallationItem from "./InstallationItem.vue";
import InstallationModal from "./InstallationModal.vue";
import GridOverlay from "./GridOverlay.vue"; // Import the GridOverlay component
import Vue from "vue";
import axios from "axios";
import { f } from "vue-plotly";

export default {
  data() {
    return {
      showAllGridEyes: false,
      google: null,
      latitude_area: 0,
      longitude_area: 0,
      recherche: this.adresse, // Initialize recherche with adresse
      searched: null,
      showedinst: false,
      polygons: [],
      production: [],
      map: null,
      area: 0,
      polygonsSize: 0,
      compassHeading: 0,
      compassDegree: "0°",
      installations: [],
      polygons: {},
      showProdButton: false,
      selectedInstallation: null,
      installationId: 0,
      showInstallationModal: false,

      dataMarkers: {
        latitude: 40.76,
        longitude: -73.983,
        solarPotential: 0.8,
      },
    };
  },
  computed: {
    checkPolygonsSize() {
      console.log("checkPolygonsSize", Object.keys(this.polygons).length);
      return Object.keys(this.polygons).length;
    },
  },

  async mounted() {
    await this.initMap();
    this.$nextTick(() => {
      this.addCompassControl();
    });
  },
  components: {
    InstallationItem,
    InstallationModal,
    GridOverlay,
  },
  props: {
    adresse: {
      type: String,
      required: true,
    },
    oldInstallation: {
      type: Array,
      required: false,
    },
  },
  watch: {
    adresse(newVal) {
      this.recherche = newVal;
    },
    polygons: {
      handler(newVal) {
        console.log("show all polygons", Object.keys(newVal).length);
      },
      deep: true,
    },
    installations: {
      handler(newVal) {
        // check the items in the installations if that has any polygon showProdButton = true
        if (newVal.length > 0) {
          newVal.forEach((installation) => {
            if (installation.polygons) {
              this.showProdButton = true;
            }
          });
        }
      },
      deep: true,
    },
    production: {
      handler(newVal) {
        // Update the installations with the new production data
        if (newVal.length > 0) {
          this.installations = this.installations.map((installation) => {
            // Create a shallow copy of the installation object
            let newInstallation = { ...installation };

            // Find the corresponding data in newVal
            const correspondingData = newVal.find(
              (item) => item.id === newInstallation.id
            );

            if (correspondingData) {
              // Add the new production attribute and update its value
              newInstallation.production = correspondingData.year_production;
            }
            return newInstallation;
          });
        }
      },
      deep: true,
      immediate: true,
    },
    // oldInstallation: {
    //   handler(newVal,oldValue) {
    //     if (newVal && newVal.length > 0 && this.searched) {
    //       //boucle tzid kol installation bl kaaba bl kaaba
    //       this.installationId = newVal[newVal.length - 1].id + 1;
    //       //this.installations = newVal;
    //       // create overlays for each installation from installation.bounds
    //       newVal.forEach((installation) => {
    //         if (installation.bounds) {
    //           this.createCustomOverlay(installation.bounds, installation);
    //         }
    //       });

    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    searched(newVal) {
      if (newVal) {
        if (this.oldInstallation && this.oldInstallation.length > 0 && newVal === true) {
          this.installations = this.oldInstallation;
          this.oldInstallation.forEach((installation) => {
            if (installation.bounds) {
              this.createCustomOverlayOld(installation.bounds, installation);
            }
          });
          this.installationId =
            this.oldInstallation[this.oldInstallation.length - 1].id + 1;

          // fill the installations with the polygons without changing installations array just this.polygons array
          this.selectedInstallation = this.oldInstallation[0].id;
        }
      }
      this.$emit("setMapStatus", newVal);
    },
    polygons(newVal) {
      this.polygonsSize = Object.keys(newVal).length;
      print("polygonsSize", this.polygonsSize);
    },

    selectedInstallation(newVal, oldVal) {
      // Hide polygons of the previously selected installation
      if (oldVal !== null) {
        this.hidePolygons(oldVal);
      }

      // Show polygons of the newly selected installation
      if (newVal !== null) {
        this.showPolygons(newVal);
      }

      // Update grid instances
      const gridInstances = document.querySelectorAll(".outer-container");
      gridInstances.forEach((gridInstance) => {
        const vueInstance = gridInstance.__vue__;
        if (vueInstance) {
          vueInstance.$props.selectedInstallation = this.selectedInstallation;
          vueInstance.updateGrid(); // Ensure the grid updates its layout
        }
      });
    },
    deep: true,
  },
  created() {
    this.recherche = this.adresse; // Ensure recherche is initialized with adresse in the created hook
  },

  _methods: {
    async initMap() {
      try {
        const loader = new Loader({
          apiKey: "AIzaSyC8jB8jLLPW0nCDqE8XrDAPYHg7YjXN2qI", // Replace with your actual API key
          libraries: ["places", "drawing"], // Include any additional libraries you need
        });
        await loader.load();
        this.map = new window.google.maps.Map(document.getElementById("map"), {
          center: { lat: 43.567296, lng: 3.896473 },
          mapTypeId: "satellite",
          zoom: 21,
          minZoom: 1,
          maxZoom: 21,
          heading: 90,
          tilt: 0,
          rotateControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          zoomControl: false,
        });

        this.map.addListener("heading_changed", () => {
          this.updateCompass();
        });

        if (this.map) {
          console.log("Map loaded successfully.");
        } else {
          console.error("Map initialization failed.");
          // redirect to oldPVGIS:
          this.$router.push({ name: "Solar-Calc-Form" });
        }
      } catch (error) {
        console.error("Error loading the Google Maps API:", error);
        this.$router.push({ name: "Solar-Calc-Form" });
      }
    },
    // async sendInstallation() {
    //   const selectedInstallationf = this.installations.find(installation => installation.id === this.selectedInstallation)

    //   await axios({
    //     url:  process.env.VUE_APP_URL_API_CLIENT + `getInstallationAndSendItToSolarApi/`,
    //     method: "POST",
    //     data: {
    //       installation: [selectedInstallationf],
    //       token: this.$store.getters.getUserToken,
    //     },
    //   })
    //     .then((response) => {
    //       this.production = response.data.data;

    //     })
    //     .catch((error) => {

    //     });

    // },
    testPoly() {
      const extractedData = {};
      for (const [id, polygonArray] of Object.entries(this.polygons)) {
        extractedData[id] = polygonArray.map((polygon) => {
          return polygon.latLngs.Eg[0].Eg.map((coord) => ({
            lat: coord.lat(),
            lng: coord.lng(),
          }));
        });
      }
      return extractedData;
    },
    handleUpdateProduction(production, installationId) {
      this.installations = this.installations.map((installation) => {
        if (installation.id === installationId) {
          installation.production = production;
        }
        return installation;
      });
    },
    handleUpdateInclinaison(inclinaison) {
      const gridInstances = document.querySelectorAll(".outer-container");
      gridInstances.forEach((gridInstance) => {
        const vueInstance = gridInstance.__vue__;
        if (vueInstance) {
          vueInstance.$props.selectedInstallation = inclinaison.installationId;
          vueInstance.$props.inclination = inclinaison.incliner;
          vueInstance.updateGrid(); // Ensure the grid updates its layout
        }
      });
    },
    drawPolygon(points) {
      if (Array.isArray(points) && Array.isArray(points[0])) {
        // Get the inner array of points
        points = points[0];
      }
      // Create a new polygon
      const coordinates = points.map((point) => {
        // Check if the point is an object and has valid lat/lng properties
        if (
          point &&
          typeof point === "object" &&
          typeof point.lat === "number" &&
          typeof point.lng === "number" &&
          isFinite(point.lat) &&
          isFinite(point.lng)
        ) {
          return new google.maps.LatLng(point.lat, point.lng);
        } else {
          throw new Error(`Invalid point coordinates: ${JSON.stringify(point)}`);
        }
      });

      // Create a polygon with these coordinates
      const polygon = new google.maps.Polygon({
        paths: coordinates,
        strokeColor: "#0569db", // Border color
        strokeOpacity: 0.8, // Border opacity
        strokeWeight: 2, // Border width
        fillColor: "#4c85c7", // Fill color
        fillOpacity: 0.35, // Fill opacity
      });

      return polygon;
    },
    stringifyCircularJSON(val) {
      const cache = new Set();
      return JSON.stringify(val, function (key, value) {
        if (typeof value === "object" && value !== null) {
          if (cache.has(value)) {
            // Duplicate reference found, discard key
            return;
          }
          // Store value in our set
          cache.add(value);
        }
        return value;
      });
    },
    //   serializePolygon(polygon) {

    //     const path = polygon.getPath().getArray().map(latLng => ({
    //         lat: latLng.lat(),
    //         lng: latLng.lng()
    //     }));

    //     return {
    //         path,
    //         area: google.maps.geometry.spherical.computeArea(polygon.getPath()),
    //         center: {
    //             lat: polygon.getBounds().getCenter().lat(),
    //             lng: polygon.getBounds().getCenter().lng()
    //         }
    //     };
    // },
    updateArea(obj) {
      let instPoly = this.testPoly();

      // Add or update the polygons for the installation
      this.installations = this.installations.map((installation) => {
        if (installation.id === obj.installationId) {
          // Initialize polygons if it doesn't exist
          if (!installation.polygons) {
            installation.polygons = [];
          }

          // Find the polygon with the same latitude and longitude
          const polygonIndex = installation.polygons.findIndex(
            (polygon) =>
              polygon.latitude === obj.latitude && polygon.longitude === obj.longitude
          );

          if (polygonIndex !== -1) {
            // If the polygon exists, update it
            installation.polygons[polygonIndex] = obj;
          } else {
            // If the polygon does not exist, add it
            installation.polygons.push(obj);
          }

          // Ensure polyGone property is set correctly
          const updatedIndex =
            polygonIndex !== -1 ? polygonIndex : installation.polygons.length - 1;
          installation.polygons[updatedIndex]["polyGone"] = instPoly[
            obj.installationId.toString()
          ]
            ? instPoly[obj.installationId.toString()]
            : [];
        }
        return installation;
      });
      // remove the first object from installation.polygons array
      this.installations = this.installations.map((installation) => {
        if (installation.polygons) {
          installation.polygons = installation.polygons.filter(
            (polygon) => polygon.latitude !== 0 && polygon.longitude !== 0
          );
        }
        return installation;
      });
    },
    addCompassControl() {
      if (this.$refs.compass) {
        this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.$refs.compass);
        this.map.addListener("heading_changed", this.updateCompass);
        this.updateCompass();
      } else {
      }
    },
    updateCompass() {
      const heading = this.map.getHeading() || 0;
      this.compassHeading = heading;
      this.compassDegree = `${Math.round(heading)}°`;
    },
    updateSelectedInstallation(installation) {
      this.selectedInstallation = installation;
      this.disableAllPannaux();
    },
    updateOrientation(installation, newAngle) {
      installation.orientation = Number(newAngle);
      const gridInstance = document.querySelector(
        `.outer-container[data-id='${installation.id}']`
      );
      if (gridInstance) {
        const vueInstance = gridInstance.__vue__;
        if (vueInstance) {
          vueInstance.$props.orientation = Number(newAngle);
          vueInstance.updateGrid(); // Ensure the grid updates its layout
        }
      }
    },
    updateLossPercentage(installation, newLossPercentage) {
      installation.lossPercentage = Number(newLossPercentage);
    },
    updateOptimalInclinisation(installation, newOptimalInclinisation) {
      installation.optimalInclinisation = newOptimalInclinisation;
    },
    updateIncliner(installation, newIncliner) {
      installation.incliner = Number(newIncliner);
    },
    updatePeakPower(installation, newPeakPower) {
      installation.peakPower = Number(newPeakPower);
    },
    addInstallation(panel) {
      let obj = {
        id: this.installationId++,
        name: `Installation ${this.installationId}`,
        nbrPanels: 0,
        orientation: 0,
        lossPercentage: 0,
        peakPower: 0,
        optimalInclinisation: "non",
        incliner: 0,
        production: 0,
        wattsPerPanel: panel.watts,
        ...panel,
      };

      this.selectedInstallation = obj.id;
      this.installations.push(obj);
      this.showInstallationModal = false;
      this.$emit("addInstallation", obj);
    },
    addPanneau(installation) {
      if (!installation) {
        alert("no installation panel selected");
        return;
      }
      if (installation.nbrPanels > 0) {
        alert("installation already added");
        return;
      }
      // increase number of Panels
      installation.nbrPanels++;

      const currentMapLocation = this.map.getCenter();
      const lat = currentMapLocation.lat();
      const lng = currentMapLocation.lng();

      const widthInMeters = installation.width;
      const heightInMeters = installation.height;

      const latSpan = heightInMeters / 111000;
      const lngSpan = widthInMeters / (111000 * Math.cos((lat * Math.PI) / 180));

      const bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(lat - latSpan / 2, lng - lngSpan / 2),
        new google.maps.LatLng(lat + latSpan / 2, lng + lngSpan / 2)
      );

      // Create the custom overlay at the current position
      this.createCustomOverlay(bounds, installation);
    },
    createCustomOverlayOld(bounds, installation) {
      let div = null;
      const overlay = new google.maps.OverlayView();
      const sw = new google.maps.LatLng(bounds.south, bounds.west);
      const ne = new google.maps.LatLng(bounds.north, bounds.east);
      overlay.onAdd = () => {
        div = document.createElement("div");
        div.style.borderStyle = "none";
        div.style.borderWidth = "0px";
        div.style.position = "absolute";

        const latLngBounds = new google.maps.LatLngBounds(sw, ne);
        // Create Vue instance for the grid
        const GridOverlayComponent = Vue.extend(GridOverlay);
        const instance = new GridOverlayComponent({
          propsData: {
            startX: 0,
            startY: 0,
            orientation: installation.orientation,
            width: latLngBounds.toSpan().lng() * 111000 * 10, // Calculate width in meters
            height: latLngBounds.toSpan().lat() * 111000 * 10, // Calculate height in meters
            onDrag: () => {},
            oldGrid: installation.panneaux || [],
            installationId: installation.id,
            selectedInstallation: this.selectedInstallation,
          },
        }).$mount();

        div.appendChild(instance.$el);

        const panes = overlay.getPanes();
        panes.overlayMouseTarget.appendChild(div);
      };

      overlay.draw = () => {
        const swBounds = new google.maps.LatLng(bounds.south, bounds.west);
        const neBounds = new google.maps.LatLng(bounds.north, bounds.east);
        const overlayProjection = overlay.getProjection();
        const sw = overlayProjection.fromLatLngToDivPixel(swBounds);
        const ne = overlayProjection.fromLatLngToDivPixel(neBounds);

        if (div) {
          div.style.left = sw.x + "px";
          div.style.top = ne.y + "px";
          const width = ne.x - sw.x;
          const height = sw.y - ne.y;

          div.style.width = width * 10 + "px";
          div.style.height = height * 10 + "px";

          // Safely update the instance props to force re-render
          const gridInstance = div.querySelector(".outer-container");
          if (gridInstance) {
            const vueInstance = gridInstance.__vue__;
            if (vueInstance) {
              vueInstance.$props.width = width * 10;
              vueInstance.$props.height = height * 10;
              //vueInstance.$props. = height * 10;
              vueInstance.isHidden = true;
              vueInstance.isActive = false;
              vueInstance.updateGrid();

              // Ensure the grid updates its layout
            }
          }
        }
      };

      overlay.onRemove = function () {
        if (div) {
          div.parentNode.removeChild(div);
          div = null;
        }
      };
      overlay.setMap(this.map);
    },
    createCustomOverlay(bounds, installation) {
      let div = null;
      const overlay = new google.maps.OverlayView();

      overlay.onAdd = () => {
        div = document.createElement("div");
        div.style.borderStyle = "none";
        div.style.borderWidth = "0px";
        div.style.position = "absolute";

        // Create Vue instance for the grid
        const GridOverlayComponent = Vue.extend(GridOverlay);
        const instance = new GridOverlayComponent({
          propsData: {
            startX: 0,
            startY: 0,
            orientation: installation.orientation,
            width: bounds.toSpan().lng() * 111000 * 10, // Calculate width in meters
            height: bounds.toSpan().lat() * 111000 * 10, // Calculate height in meters
            onDrag: handleDrag,

            installationId: installation.id,
            selectedInstallation: this.selectedInstallation,
          },
        }).$mount();

        instance.$on("cellChange", (data) => {
          // Trouvez l'installation avec l'ID spécifié
          this.installations = this.installations.map((installation) => {
            if (installation) {
              // Ajoutez cette vérification
              // and attribute "panneaux" to it
              if (installation.id === data.installationId) {
                //installation.panneaux = installation.panneaux || [];
                // Add the new panel to the installation
                installation["panneaux"] = data.cells;
              }
            }
            return installation; // Assurez-vous de retourner l'installation à la fin
          });
          // remove thye deplication of the installation.panneaux with x and y
        });

        instance.$on("orientationChange", (data) => {
          for (let i = 0; i < this.installations.length; i++) {
            if (this.installations[i].id === data.installationId) {
              this.installations[i].orientation = data.orientation;
              break;
            }
          }
        });

        instance.$on("selectedPannelsChange", (data) => {
          installation.nbrPanels = data.nbrPanels;
        });

        div.appendChild(instance.$el);
        const panes = overlay.getPanes();
        panes.overlayMouseTarget.appendChild(div);
      };

      overlay.draw = () => {
        const overlayProjection = overlay.getProjection();
        const sw = overlayProjection.fromLatLngToDivPixel(bounds.getSouthWest());
        const ne = overlayProjection.fromLatLngToDivPixel(bounds.getNorthEast());

        if (div) {
          div.style.left = sw.x + "px";
          div.style.top = ne.y + "px";
          const width = ne.x - sw.x;
          const height = sw.y - ne.y;

          div.style.width = width * 10 + "px";
          div.style.height = height * 10 + "px";

          // Safely update the instance props to force re-render
          const gridInstance = div.querySelector(".outer-container");
          if (gridInstance) {
            const vueInstance = gridInstance.__vue__;
            if (vueInstance) {
              vueInstance.$props.width = width * 10;
              vueInstance.$props.height = height * 10;
              vueInstance.updateGrid(); // Ensure the grid updates its layout
            }
          }
        }
        // update the installation with the bound to save it in the database
        this.installations = this.installations.map((insta) => {
          if (insta.id == installation.id) {
            if (insta.hasOwnProperty("bounds")) {
              insta.bounds = bounds;
            } else {
              insta["bounds"] = bounds;
            }
          }
          return insta;
        });
      };

      const handleDrag = (dx, dy) => {
        const overlayProjection = overlay.getProjection();
        const startBounds = bounds;

        const sw = overlayProjection.fromLatLngToDivPixel(startBounds.getSouthWest());
        const ne = overlayProjection.fromLatLngToDivPixel(startBounds.getNorthEast());

        const newCenterX = (sw.x + ne.x) / 2 + dx;
        const newCenterY = (sw.y + ne.y) / 2 + dy;

        const newCenterLatLng = overlayProjection.fromDivPixelToLatLng(
          new google.maps.Point(newCenterX, newCenterY)
        );

        const latHeight =
          startBounds.getNorthEast().lat() - startBounds.getSouthWest().lat();
        const lngWidth =
          startBounds.getNorthEast().lng() - startBounds.getSouthWest().lng();
        const newSW = new google.maps.LatLng(
          newCenterLatLng.lat() - latHeight / 2,
          newCenterLatLng.lng() - lngWidth / 2
        );
        const newNE = new google.maps.LatLng(
          newCenterLatLng.lat() + latHeight / 2,
          newCenterLatLng.lng() + lngWidth / 2
        );

        bounds = new google.maps.LatLngBounds(newSW, newNE);
        overlay.draw();
      };

      overlay.onRemove = function () {
        if (div) {
          div.parentNode.removeChild(div);
          div = null;
        }
      };

      overlay.setMap(this.map);
    },
    // createCustomAllOverlays(installations) {
    //   installations.forEach((installation) => {
    //     const currentMapLocation = this.map.getCenter();
    //     const lat = currentMapLocation.lat();
    //     const lng = currentMapLocation.lng();

    //     const widthInMeters = installation.width;
    //     const heightInMeters = installation.height;

    //     const latSpan = heightInMeters / 111000;
    //     const lngSpan =
    //       widthInMeters / (111000 * Math.cos((lat * Math.PI) / 180));

    //     const bounds = new google.maps.LatLngBounds(
    //       new google.maps.LatLng(lat - latSpan / 2, lng - lngSpan / 2),
    //       new google.maps.LatLng(lat + latSpan / 2, lng + lngSpan / 2)
    //     );
    //     this.createCustomOverlay(bounds, installation);
    //   });
    // },
    findPlace() {
      this.searched = false;
      const request = {
        query: this.recherche,
        fields: ["name", "geometry"],
      };
      var service = new window.google.maps.places.PlacesService(this.map);
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.map.setCenter(results[0].geometry.location);
          this.map.setZoom(25);
          this.searched = true;
          this.$emit("placeFound", true);
        } else {
          this.searched = false;

          this.$emit("placeFound", false);
        }
      });
    },

    createCompassOverlay() {
      let compassOverlay = new google.maps.OverlayView();

      compassOverlay.onAdd = function () {
        let layer = this.getPanes().overlayLayer;
        layer.appendChild(this.$refs.compass);
      };

      compassOverlay.draw = function () {
        // Positioning if needed
      };

      compassOverlay.onRemove = function () {
        this.$refs.compass.parentNode.removeChild(this.$refs.compass);
      };

      compassOverlay.setMap(this.map);
    },
    togglePannaux(inst) {
      this.showedinst = inst.showed;
    },
    async takeScreenshot() {
      const gridInstance = document.querySelector(
        `.outer-container[data-id="${this.selectedInstallation}"]`
      );
      if (gridInstance) {
        const vueInstance = gridInstance.__vue__;
        if (vueInstance) {
          vueInstance.isHidden = true;
          vueInstance.isActive = false;
          await vueInstance.updateGrid(); // Ensure updateGrid is completed
        }
      }

      const mapContainer = this.$refs.map;
      if (mapContainer) {
        try {
          const canvas = await html2canvas(mapContainer, {
            useCORS: true,
            logging: true,
            scale: 1,
          });
          const image = canvas.toDataURL("image/png");

          const link = document.createElement("a");
          link.href = image;
          link.download = "map-screenshot.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (err) {
          console.error("Error taking screenshot:", err);
        } finally {
          if (gridInstance) {
            const vueInstance = gridInstance.__vue__;
            if (vueInstance) {
              vueInstance.isHidden = true;
              vueInstance.isActive = false;
              await vueInstance.updateGrid(); // Ensure updateGrid is completed
            }
          }
        }
      } else {
        console.error("Map container is not found");
      }
    },
    async takeScreenshotPreview() {
      const gridInstance = document.querySelector(
        `.outer-container[data-id="${this.selectedInstallation}"]`
      );
      if (gridInstance) {
        const vueInstance = gridInstance.__vue__;
        if (vueInstance) {
          vueInstance.isHidden = true;
          vueInstance.isActive = false;
          await vueInstance.updateGrid(); // Ensure updateGrid is completed
        }
      }

      const mapContainer = this.$refs.map;
      if (mapContainer) {
        const canvas = await html2canvas(mapContainer, {
          useCORS: true,
          logging: true,
          scale: 1,
        });
        const image = canvas.toDataURL("image/png");

        // Convert base64 image to Blob
        const response = await fetch(image);
        const blob = await response.blob();

        // Convert Blob to File
        const file = new File([blob], "screenshot.png", { type: "image/png" });

        // Assign the file to this.capture
        this.capture = Promise.resolve(file);

        // Return the promise
        return this.capture;
      } else {
        console.error("Map container is not found");
      }
    },
    handlePolygonComplete({ installationId, polygon }) {
      if (!this.polygons[installationId]) {
        this.polygons[installationId] = [];
      }
      this.polygons[installationId].push(polygon);
    },

    deleteInstallation(installationId) {
      // Remove the installation from the list
      this.installations = this.installations.filter(
        (inst) => inst.id !== installationId
      );

      // Remove associated polygons from the map and from the polygons object
      if (this.polygons[installationId]) {
        this.polygons[installationId].forEach((polygon) => {
          polygon.setMap(null);
        });
        delete this.polygons[installationId];
        this.$emit("deleteInstallation", installationId);
      }

      // Ensure selected installation is updated correctly
      if (this.selectedInstallation === installationId) {
        this.selectedInstallation = null;
      }
    },

    showPolygons(installationId) {
      if (this.polygons[installationId]) {
        this.polygons[installationId].forEach((polygon) => {
          polygon.setMap(this.map);
          //this.togglePannaux({ showed: true });
        });
      }
    },

    showAllPolygons() {
      this.showAllGridEyes = !this.showAllGridEyes;
      if (Object.keys(this.polygons).length >= 1) {
        if (this.showAllGridEyes == true) {
          //console.log("show all polygons",Object.keys(this.polygons).length);
          for (const installationId in this.polygons) {
            this.polygons[installationId].forEach((polygon) => {
              polygon.setMap(this.map);
            });
          }
          const gridInstances = document.querySelectorAll(".outer-container");
          gridInstances.forEach((gridInstance) => {
            const vueInstance = gridInstance.__vue__;
            if (vueInstance) {
              vueInstance.$props.showAll = true;
              vueInstance.updateGrid(); // Ensure the grid updates its layout
            }
          });
        } else {
          //console.log("show all polygons",Object.keys(this.polygons).length);
          for (const installationId in this.polygons) {
            this.polygons[installationId].forEach((polygon) => {
              polygon.setMap(null);
            });
          }
          const gridInstances = document.querySelectorAll(".outer-container");
          gridInstances.forEach((gridInstance) => {
            const vueInstance = gridInstance.__vue__;
            if (vueInstance) {
              vueInstance.$props.showAll = false;
              vueInstance.$props.selectedInstallation = null;
              vueInstance.updateGrid(); // Ensure the grid updates its layout
            }
          });
        }
      } else {
        return;
      }
    },
    disableAllPannaux() {
      const gridInstances = document.querySelectorAll(".outer-container");
      gridInstances.forEach((gridInstance) => {
        const vueInstance = gridInstance.__vue__;
        if (vueInstance) {
          vueInstance.$props.showAll = false;
          vueInstance.updateGrid(); // Ensure the grid updates its layout
        }
      });
    },

    hidePolygons(installationId) {
      //document.getElementById("installation" + installationId).style.display = "none";
      if (this.polygons[installationId]) {
        this.polygons[installationId].forEach((polygon) => {
          polygon.setMap(null);
        });
      }
    },

    async handleinstallationCapture(installation) {
      const capture = await this.takeScreenshotPreview();

      this.$emit("installation_valide", capture);
    },
  },
  get methods() {
    return this._methods;
  },
  set methods(value) {
    this._methods = value;
  },
};
</script>

<style scoped>
#map {
  height: 100%;
  width: 100%;
}

.map-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.compass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  top: 10px !important;
  right: 15px !important;
}

.compass-img {
  background-color: rgba(255, 255, 255, 0.904);
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.screenshot {
  position: absolute;
  top: 100px;
  right: 20px;
  cursor: pointer;
  font-size: 40px;
  color: #fff;
  text-shadow: 0px 4px 8px #262626;
}
.showAll {
  position: absolute;
  top: 160px;
  right: 20px;
  cursor: pointer;
  font-size: 40px;
  color: #fff;
  text-shadow: 0px 4px 8px #262626;
}
</style>

<!-- search style -->
<style scoped>
.search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.search-input-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  max-width: 600px;
}
.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 8px;
}

.icon-prefix {
  position: absolute;
  left: 10px;
  pointer-events: none;
  color: #ddd;
}

.search-input {
  padding: 10px;
  padding-left: 40px;
  width: 100%;
  outline: none;
  border-radius: 8px;
}
.search-btn {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  background: #03bb90;
  padding: 0px 16px;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.map-container {
  margin-top: 12px;
  height: 700px;
  position: relative;
}
</style>
<!-- toolbar -->
<style scoped>
.toolbar {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100%;
  overflow-y: auto;
  width: 395px;
  overflow-y: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 1;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}
.project-list {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  gap: 20px;
  padding: 10px;
}
.add-installation-btn {
  font-size: 14px;
  border: 2px solid #bbb;
  color: #fff;
  font-weight: 500;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  background-color: #607d8b;
  cursor: pointer;
  box-shadow: 0px 4px 8px 0px rgba(60, 64, 67, 0.3);
}
.prod-installation-btn {
  font-size: 14px;
  border: 2px solid #bbb;
  color: #fff;
  font-weight: 500;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  background-color: #f5a43b;
  cursor: pointer;
  box-shadow: 0px 4px 8px 0px rgba(60, 64, 67, 0.3);
}
</style>
