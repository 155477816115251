<template>
  <v-dialog v-model="Popup" persistent max-width="50%">
    <v-card>
      <v-toolbar :color="$colors[0]" dark>
        <span class="pl-0 Poppins-Bold">Informations Fournisseur</span>
      </v-toolbar>

      <v-form ref="formActuelle" class="pa-4">
        <v-row class="ma-0 pa-0">
          <v-col cols="11" sm="12" md="12" lg="12" xl="12" class="ma-0 pa-0">
            <v-subheader class="ma-0 pa-0 Poppins-Bold"
              >Informations du fournisseur</v-subheader
            >
            <v-col class="pa-0" cols="11" sm="5" md="5" lg="5" xl="5">
              <v-radio-group class="mt-0" v-model="lesPrix.typeTurpe" row>
                <v-radio
                  :color="$colors[6]"
                  label="Turpe Inclus"
                  value="oui"
                  class="v-radio"
                ></v-radio>
                <v-radio
                  :color="$colors[6]"
                  label="Turpe non Inclus"
                  value="non"
                  class="v-radio"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-divider class="ma-0 pa-0"></v-divider>
          </v-col>
        </v-row>

        <!-- Prix -->
        <v-row class="ma-0 pa-0">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-1 pa-0">
            <v-subheader class="ma-0 pa-0 Poppins-Bold">Prix du MWh</v-subheader>
            <v-divider class="ma-0 pa-0"></v-divider>
          </v-col>
          <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl="2">
            <v-text-field
              dense
              v-if="
                parametreCompteur == 'C5BASE' ||
                (parametreCompteur == 'C4' && compteurProp.optionTarifaire == 'BTINFCU4')
              "
              type="number"
              step="0.001"
              required
              outlined
              v-model="lesPrix.prixAchatBASE"
              label="(BASE)"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              dense
              v-if="
                parametreCompteur == 'C3' ||
                parametreCompteur == 'C4' ||
                parametreCompteur == 'C5C4'
              "
              type="number"
              step="0.001"
              suffix="€"
              required
              outlined
              v-model="lesPrix.prixAchatHPH"
              label="(HPH)"
              :rules="[rules.required('un PRIX (HPH) valide')]"
            ></v-text-field>
            <v-text-field
              dense
              v-if="
                parametreCompteur == 'C5HP'
              "
              type="number"
              step="0.001"
              suffix="€"
              required
              outlined
              v-model="lesPrix.prixAchatHP"
              label="(HP)"
              :rules="[rules.required('un PRIX (HP) valide')]"
            ></v-text-field>
          </v-col>

          <v-col
            class="pl-0"
            cols="11"
            sm="3"
            md="2"
            lg="2"
            xl="2"
            v-if="
              parametreCompteur == 'C3' ||
              parametreCompteur == 'C5HP' ||
              parametreCompteur == 'C4' ||
              parametreCompteur == 'C5C4'
            "
          >
            <v-text-field
              dense
              v-if="
                parametreCompteur == 'C3' ||
                parametreCompteur == 'C4' ||
                parametreCompteur == 'C5C4'
              "
              type="number"
              step="0.001"
              suffix="€"
              required
              outlined
              v-model="lesPrix.prixAchatHCH"
              label="(HCH)"
              :rules="[rules.required('un PRIX (HCH) valide')]"
            ></v-text-field>
            <v-text-field
              dense
              v-if="
                parametreCompteur == 'C5HP'
              "
              type="number"
              step="0.001"
              suffix="€"
              required
              outlined
              v-model="lesPrix.prixAchatHC"
              label="(HC)"
              :rules="[rules.required('un PRIX (HC) valide')]"
            ></v-text-field>
          </v-col>

          <v-col
            class="pl-0"
            cols="11"
            sm="3"
            md="2"
            lg="2"
            xl="2"
            v-if="
              parametreCompteur == 'C3' ||
              parametreCompteur == 'C4' ||
              parametreCompteur == 'C5C4'
            "
          >
            <v-text-field
              dense
              type="number"
              step="0.001"
              suffix="€"
              required
              outlined
              v-model="lesPrix.prixAchatHPE"
              label="(HPE)"
              :rules="[rules.required('un PRIX (HPE) valide')]"
            ></v-text-field>
          </v-col>

          <v-col
            class="pl-0"
            cols="11"
            sm="3"
            md="2"
            lg="2"
            xl="2"
            v-if="
              parametreCompteur == 'C3' ||
              parametreCompteur == 'C4' ||
              parametreCompteur == 'C5C4'
            "
          >
            <v-text-field
              dense
              type="number"
              step="0.001"
              suffix="€"
              required
              outlined
              v-model="lesPrix.prixAchatHCE"
              label="(HCE)"
              :rules="[rules.required('un PRIX (HCE) valide')]"
            ></v-text-field>
          </v-col>

          <v-col
            class="pl-0"
            cols="11"
            sm="3"
            md="2"
            lg="2"
            xl="2"
            v-if="parametreCompteur == 'C3'"
          >
            <v-text-field
              dense
              v-if="parametreCompteur == 'C3'"
              type="number"
              step="0.001"
              required
              outlined
              v-model="lesPrix.prixAchatPointe"
              suffix="€"
              label="(P)"
              :rules="[rules.required('un PRIX (P) valide')]"
            ></v-text-field>
          </v-col>
          <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl="2">
            <v-text-field
              dense
              type="number"
              step="0.001"
              required
              outlined
              suffix="€"
              v-model="lesPrix.CEE"
              label="CEE"
              :rules="[rules.required('un CEE valide')]"
            ></v-text-field>
          </v-col>

          <v-col class="pl-0" cols="11" sm="3" md="2" lg="2" xl="2">
            <v-text-field
              dense
              type="number"
              step="0.001"
              required
              outlined
              suffix="€"
              v-model="lesPrix.ABO"
              label="Abonnement mensuel"
              :rules="[rules.required('une valeur valide')]"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- coef capa -->
        <v-row class="ma-0 pa-0">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-1 pa-0">
            <v-subheader class="ma-0 pa-0 Poppins-Bold"
              >Informations sur la capacité</v-subheader
            >
            <v-divider class="ma-0 pa-0"></v-divider>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-1 pa-0">
            <v-radio-group v-model="lesPrix.typeCapa" row class="ma-0 pa-0">
              <v-radio
                :color="$colors[6]"
                label="Coût capacité €/MWh"
                value="CAPA"
              ></v-radio>
              <v-radio
                :color="$colors[6]"
                label="Coefficient capacité €/MWh"
                value="COEF"
              ></v-radio>
              <v-radio
                :color="$colors[6]"
                label="CAPA Inclus"
                value="CAPAINCLUS"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            class="pl-0"
            cols="11"
            sm="3"
            md="2"
            lg="2"
            xl="2"
            v-if="lesPrix.typeCapa != 'CAPAINCLUS'"
          >
            <v-text-field
              dense
              v-if="
                parametreCompteur == 'C5BASE'
              "
              type="number"
              step="0.001"
              suffix="€"
              required
              outlined
              v-model="lesPrix.prixAchatCapaBASE"
              label="(BASE)"
              :rules="[rules.required('un CAPA (BASE) valide')]"
            ></v-text-field>
            <v-text-field
              dense
              v-if="
                parametreCompteur == 'C3' ||
                parametreCompteur == 'C4' ||
                parametreCompteur == 'C5C4'
              "
              type="number"
              step="0.001"
              suffix="€"
              required
              outlined
              v-model="lesPrix.prixAchatCapaHPH"
              label="(HPH)"
              :rules="[rules.required('un CAPA (HPH) valide')]"
            ></v-text-field>
            <v-text-field
              dense
              v-if="parametreCompteur == 'C5HP'"
              type="number"
              step="0.001"
              suffix="€"
              required
              outlined
              v-model="lesPrix.prixAchatCapaHP"
              label="(HP)"
              :rules="[rules.required('un CAPA (HP) valide')]"
            ></v-text-field>
          </v-col>

          <v-col
            class="pl-0"
            cols="11"
            sm="3"
            md="2"
            lg="2"
            xl="2"
            v-if="
              lesPrix.typeCapa != 'CAPAINCLUS' &&
              (parametreCompteur == 'C3' ||
                parametreCompteur == 'C5HP' ||
                parametreCompteur == 'C4' ||
                parametreCompteur == 'C5C4')
            "
          >
            <v-text-field
              dense
              v-if="
                parametreCompteur == 'C3' ||
                parametreCompteur == 'C4' ||
                parametreCompteur == 'C5C4'
              "
              type="number"
              step="0.001"
              suffix="€"
              required
              outlined
              v-model="lesPrix.prixAchatCapaHCH"
              label="(HCH)"
              :rules="[rules.required('un CAPA (HCH) valide')]"
            ></v-text-field>
            <v-text-field
              dense
              v-if="parametreCompteur == 'C5HP'"
              type="number"
              step="0.001"
              suffix="€"
              required
              outlined
              v-model="lesPrix.prixAchatCapaHC"
              label="(HC)"
              :rules="[rules.required('un CAPA (HC) valide')]"
            ></v-text-field>
          </v-col>

          <v-col
            class="pl-0"
            cols="11"
            sm="3"
            md="2"
            lg="2"
            xl="2"
            v-if="
              lesPrix.typeCapa != 'CAPAINCLUS' &&
              (parametreCompteur == 'C3' ||
                parametreCompteur == 'C4' ||
                parametreCompteur == 'C5C4')
            "
          >
            <v-text-field
              dense
              v-if="
                parametreCompteur == 'C3' ||
                parametreCompteur == 'C4' ||
                parametreCompteur == 'C5C4'
              "
              type="number"
              step="0.001"
              suffix="€"
              required
              outlined
              v-model="lesPrix.prixAchatCapaHPE"
              label="(HPE)"
              :rules="[rules.required('un CAPA (HPE) valide')]"
            ></v-text-field>
          </v-col>

          <v-col
            class="pl-0"
            cols="11"
            sm="3"
            md="2"
            lg="2"
            xl="2"
            v-if="
              lesPrix.typeCapa != 'CAPAINCLUS' &&
              (parametreCompteur == 'C3' ||
                parametreCompteur == 'C4' ||
                parametreCompteur == 'C5C4')
            "
          >
            <v-text-field
              dense
              v-if="
                parametreCompteur == 'C3' ||
                parametreCompteur == 'C4' ||
                parametreCompteur == 'C5C4'
              "
              type="number"
              step="0.001"
              suffix="€"
              required
              outlined
              v-model="lesPrix.prixAchatCapaHCE"
              label="(HCE)"
              :rules="[rules.required('un CAPA (HCE) valide')]"
            ></v-text-field>
          </v-col>

          <v-col
            class="pl-0"
            cols="11"
            sm="3"
            md="2"
            lg="2"
            xl="2"
            v-if="lesPrix.typeCapa != 'CAPAINCLUS' && parametreCompteur == 'C3'"
          >
            <v-text-field
              dense
              v-if="parametreCompteur == 'C3'"
              type="number"
              step="0.001"
              suffix="€"
              required
              outlined
              v-model="lesPrix.prixAchatCapaPointe"
              label="(P)"
              :rules="[rules.required('un CAPA (P) valide')]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions>
        <v-row class="ma-2" justify="space-between">
          <div></div>
          <div>
            <v-btn color="error" class="marginBtn ml-5" text @click="closePopup()">
              Fermer
            </v-btn>

            <v-btn
              :color="$colors[0]"
              class="responsive-margin ml-5 text-white"
              v-if="editFournisseur == false"
              @click="AjouterFournisseur()"
            >
              Ajouter
            </v-btn>
          </div>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  name: "venteForm",
  props: {
    Popup: {
      type: Boolean,
      required: true,
    },
    compteurProp: {
      type: Object,
      required: true,
    },
    updatePrixProp: {
      type: Object,
      default: () => {
        return {};
      },
      required: false,
    },
  },
  data() {
    return {
      editFournisseur: false,
      indexFournisseurEdited: -1,
      errorDateDeFin: false,
      nbrMonth: 0,
      dialog: false,
      loading: false,
      error: false,
      messageDialogeSucces: "",
      messageDialogeError: "",
      errorEnedis: false,
      openModalFornisseurActuelle: false,
      vraiSiret: false,
      resultatEnedisMesure: false,
      resultatEnedisTechnique: false,
      enedisRecherche: false,
      siret: "",
      valueRechercheSiret: "",
      listSociete: [{ data: "", disable: true }],
      listCompteur: [],
      parametreCompteur: "",
      scoreEllipro: "",
      scoreCreditSafe: "",
      listProposition: [],
      optionsTarifaires: [
        "BTINFCU4",
        "BTINFCUST",
        "BTINFMU4",
        "BTINFMUDT",
        "BTINFLU",
        "BTSUPCU4",
        "BTSUPLU4",
        "HTACU5",
        "HTACUPM5",
        "HTALU5",
        "HTALUPM5",
      ],

      tousLesAutreFournisseur: [],
      updatePrix: {},

      lesPrix: {
        prixAchatHPH: null,
        prixAchatHCH: null,
        prixAchatHPE: null,
        prixAchatHCE: null,
        prixAchatPointe: null,
        prixAchatHP: null,
        prixAchatHC: null,
        prixAchatBASE: null,
        prixAchatCapaHPH: null,
        prixAchatCapaHCH: null,
        prixAchatCapaHPE: null,
        prixAchatCapaHCE: null,
        prixAchatCapaPointe: null,
        prixAchatCapaHP: null,
        prixAchatCapaHC: null,
        prixAchatCapaBASE: null,
        typeCapa: "CAPA",
        typeTurpe: "non",
        ABO: "0",
        CEE: "0",
        typeFournisseur: "Fournisseur",
        parametreCompteur: null,
      },
      compteur: {
        optionTarifaire: "",
      },

      fournisseurActuelle: false,

      //debut rules

      rules: {
        isPositiveNumb: (v) =>
          /^[0-9]\d*$/.test(v) ||
          "La valeur doit etre un entier strictement supérieur à 0",
        required(fieldName) {
          return (value) => !!value || `Veuillez saisir ${fieldName}`;
        },
        siretLength: (v) => v.length == 14 || "Le siret doit comporter 14 chiffres",
        isMonthCorrect: (v) =>
          (v >= 1 && v <= 12) || "Le mois doit etre compris entre 1 et 12 ",
        isNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
        compteurElecLength: (v) =>
          v.length == 14 || "Le RAE / PDL est une suite de 14 chiffres",
        isGazCompteur: (v) =>
          v.match(/^(GI)[0-9]{6}$/) ||
          (v.length == 14 && v.match(/^[0-9]+$/) != null) ||
          "Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres",
        phoneLength: (v) =>
          v.length == 10 || "Le numéro de téléphone est une suite de 10 chiffres",
        phoneisNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
        margeMaxMin: (v) =>
          (v <= 25 && v >= 2) || "La marge doit être comprise entre 2 et 25",
        Length: (v) => v.length > 0 || "Veuillez saisir un type d'offre",
        isBetweenRangeNumber: (v) =>
          (v >= 2 && v <= 25) || "La valeur saisie doit etre comprise entre 2 et 25",
        emailRules: (v) => (!!v && /.+@.+\.+.+/.test(v)) || "E-mail invalide",
      },
      required(fieldName) {
        return (value) =>
          !!value ||
          value >= 0 ||
          (!!value && value != "") ||
          `Veuillez saisir ${fieldName}`;
      },
      minDebutContrat: new Date().toISOString().substr(0, 10),
      minDebutFinContrat: new Date().toISOString().substr(0, 10),
      maxDateFinContrat: new Date(new Date().getFullYear() + 5 + "-" + 12 + "-" + 31)
        .toISOString()
        .substr(0, 10),
      maxDateDebutContrat: new Date(new Date().getFullYear() + 5 + "-" + 11 + "-30")
        .toISOString()
        .substr(0, 10),
    };
  },
  created() {
    this.parametreCompteur = this.compteurProp.parametreCompteur;
    this.lesPrix.parametreCompteur = this.compteurProp.parametreCompteur;
  },
  methods: {
    resetForm() {
      // clear form
      Object.keys(this.lesPrix).forEach((key) => {
        this.lesPrix[key] = null;
      });
    },

    dateFormatSlach(date) {
      return date.split("-").reverse().join("/");
    },
    dateFormatMinus(date) {
      return date.split("/").reverse().join("-");
    },

    isValidDate(formatDate, minDate, maxDate) {
      return (date) => {
        var tmpdate = this.dateFormatMinus(date);
        if (!date) {
          return "Veuillez saisir une date valide";
        }
        if (moment(tmpdate).isBefore(minDate)) {
          return `La date doit être supérieure  au ${this.dateFormatSlach(minDate)}.`;
        }
        if (moment(tmpdate).isAfter(maxDate)) {
          return `La date limite de fin de contrat est: ${this.dateFormatSlach(
            maxDate
          )}.`;
        }
        return true;
      };
    },

    async AjouterFournisseur() {
      // send data data backend
      if (this.$refs.formActuelle.validate()) {
        this.$emit("closePopup");
        this.$emit("updateListPrix", this.lesPrix);
      }
    },

    closePopup() {
      this.$emit("closePopup");
    },
  },
  computed: {
    isDisabledMesures() {
      if (this.nbrMonth >= 8) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    updatePrixProp: function (newVal) {
      if (!!newVal) {
        this.updatePrix = { ...newVal };
      }
    },
    compteurProp: {
      handler() {
        this.parametreCompteur = this.compteurProp.parametreCompteur;
        this.lesPrix = this.compteurProp.lesPrix;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
::v-deep .v-input--selection-controls .v-radio > .v-label {
  margin: 0px !important;
}
</style>
