<template>
  <div class="page-blanc">
    <img class="center-shape" src="../images/shapes/shape-center.png" />
    <img class="right-top-shape" src="../images/shapes/shape-3.png" />
    <img class="bottom-right-shape" src="../images/shapes/shape-2.png" />

    <img 
    class="logo-enffic-pdf"
    src="../images/logo-eneffic.png"
    />
    <p class="page-title">
      <span>Synthèse de consommation de mon projet</span>
    </p>

    <div class="content">
      <p class="section-title">Comparaison consommation et production solaire</p>
      <div class="chart-wrapper">
        <VueApexCharts 
        type="line" 
        height="350px" 
        :options="chartOptions" 
        :series="series">
        </VueApexCharts>
      </div>
      <p class="section-title">Quel modèle pour ma production électrique ?</p>
      <div class="cards-wrapper">
        <div class="card">
          <div class="icon-wrapper">
            <img src="../images/icons/lampe.png" />
          </div>
          <div class="d-flex flex-column">
            <div class="titleOfCard">Autoconsommation</div>
            <div class="subtitle">Vous produisez et consommez votre propre électricité.</div>
          </div>
        </div>
        <div class="card">
          <div class="icon-wrapper">
            <img src="../images/icons/revente-totale.png" />
          </div>
          <div class="d-flex flex-column">
            <div class="titleOfCard">Revente Totale</div>
            <div class="subtitle">Vous vendez la totalité de l'électricité produite au réseau.</div>
          </div>
        </div>
      </div>

    </div>
    <div class="footer">
      <ul>
        <li>ENEFFIC - 2 Aliée du Levant 34970 Lattes</li>
        <li>Téléphone: +33 (0)4 65 84 38 76 - E-mail: contact@eneffic.fr</li>
        <li>SIREN: 951 979 798</li>
      </ul>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "SyntheseConsoChart",
  components: {
    VueApexCharts,
  },
  props: ["seriesConsoProd"],
  watch: {
    seriesConsoProd: {
      handler(newVal) {
        if (newVal) {
          this.series = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      series: [
        {
          name: 'Votre consommation réalisée',
          type: 'column',
          data: [0,0,0,0,0,0,0,0,0,0,0,0]
        }, {
          name: 'Production solaire',
          type: 'area',
          data: [0,0,0,0,0,0,0,0,0,0,0,0]
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
          },
          election: {
            enabled: false,
          }
        },
        colors: ['#14DAF5', '#00f7bd'],
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },
        
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        markers: {
          size: 0
        },
        xaxis: {
          type: 'category',
          categories: ['janv.', 'fév.', 'mars', 'avr', 'mai', 'juin', 'juill.', 'aout', 'sept.', 'oct.', 'nov.', 'déc.'],

        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(0) + " kWh";
            }
          }
        },
        dataLabels: {
          enabled: false,
        },
      },
    }
  },
};
</script>

<style lang="scss" scoped>
.page-blanc{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 32px;

  .center-shape{
    position: absolute;
    width: 600px;
    height: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .right-top-shape{
    position: absolute;
    width: 75px;
    height: 75px;
    top: 15.5%;
    right: 0;
  }

  .bottom-right-shape{
    position: absolute;
    width: 150px;
    height: 150px;
    bottom: 0;
    right: 0;
  }

  .logo-enffic-pdf{
    height: 24px;
    margin-top: 40px;
    margin-left: 40px;
  }
  
  .page-title{
    width: fit-content;
    position: relative;
    font-size: 18pt;
    font-weight: 600;
    margin: 60px auto 100px;
    span{
      position: relative;
    }
    &::before{
      content: "";
      position: absolute;
      display: block;
      width: 50%;
      height: 16px;
      top: 60%;
      left: 0;
      background: #00f7bd;
    }
  }

  .content{
    position: relative;
    width: 100%;
    padding: 0px;
    .section-title{
      font-size: 16pt;
      font-weight: 600;
      color: #14324F;
      text-align: center;
      margin-bottom: 20px;
    }
    .chart-wrapper{
      background: #fff;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 120px;
    }
    .cards-wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      gap: 40px;
      padding: 40px 24px;

      .card{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 15px;
        flex: 1;
        height: 150px;
        border-radius: 0px;
        border: none;
        outline: none;
        .icon-wrapper{
          width: 160px;
          height: 90px;
          box-shadow: 8px 8px 0px 2px rgba(0,247,189,0.3);
          background-color: rgba(0,247,189, 1);
          margin-right: 20px;
          img{
            width: 100%;
            height: 100%;
          }
        }

        .titleOfCard{
          font-size: 14pt;
          font-weight: 500;
          text-align: left;
          color:#14324F;
        }

        .subtitle{
          font-size: 12pt;
          font-weight: 500;
          text-align: left;
          color:#14324F;
          box-shadow: none;
        }
      }
    }
  }
  .footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 2;
    font-size: 10pt;
    color: #14324F;
    font-weight: 400;
    text-align: center;
    ul{
      list-style-type: none;
    }
  }
}
</style>