import axios from "@/plugins/axios";
import store from "@/store";
export default {
    async get_version_model_voiture(modeleVoiture){
      let resultat = {};
        await axios({
            url:process.env.VUE_APP_URL_API_CLIENT+"getVersionToModele/",
            method: "POST",
            headers: {
              "Content-type": "application/json;"
            },
            data:{
              token:store.getters.getUserToken,
              modele:modeleVoiture
            },
          })
          .then(res => {
            
            resultat =  {listeVersionModels:res.data.data,versionVoitureModels:res.data.data[0]}
            });
            return resultat;
    },
    async get_cout_elec_essence_voiture(bodyObject){
      let resultat = {};
        await fetch(process.env.VUE_APP_URL_API_CLIENT+"calculeEssenceElec/", {
            method: "POST",
            body:bodyObject,
            headers: {
              "Content-type": "application/json; charset=UTF-8"
            }
          })
          .then((response) => response.json())
          .then((data) => {
            resultat = {coutElec:Number.parseFloat(data.coutElec).toFixed(2),coutEssence:Number.parseFloat(data.coutEssence).toFixed(2),differenceEssenceElec:Number.parseFloat(data.differenceEssenceElec.toFixed(2))}
          });
          return resultat;
    },
    async get_prix_elec_essence_voiture(){
      let resultat = {};
        await fetch(process.env.VUE_APP_URL_API_CLIENT+"getInformationsVoitureModel/", {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8"
            }
          })
          .then((response) => response.json())
          .then((data) => {
            resultat =  {prixElec:Number.parseFloat(data.data.prixElec).toFixed(2),prixEssence:Number.parseFloat(data.data.prixEssence).toFixed(2)}
          });
          return resultat;
    },

    async get_all_model_voiture(){
       let result = [];
      await fetch(process.env.VUE_APP_URL_API_CLIENT+"getAllModeleVoiture/", {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
      .then((response) => response.json())
      .then((data) => {
         result = data.data
      });
      return result;
      },
}