<template>
  <div>
    <v-btn
      class=""
      @click="showPresentationForm"
      style="color: antiquewhite"
      :color="$colors[0]"
    >
      <span class="mdi mdi-download" style="font-size: 24px"></span>
    </v-btn>
    <v-dialog v-model="presPopup" width="80%" elevation="0">
      <v-card
        class="download-pdf-indv p-4"
        elevation="0"
        style="width: 100% !important"
      >
        <div
          class="download-pdf-indv-header mt-3 d-flex justify-content-between"
        >
          <h5 class="download-pdf-indv-title mt-2">
            Télécharger une Présentation PDF
          </h5>
          <span
            @click="closePresentationForm"
            class="mdi mdi-close"
            style="font-size: 24px"
          ></span>
        </div>

        <v-form ref="formPdfPreview" class="download-pdf-indv-body">
          <v-select
            class="m-1"
            label="selection de mode :"
            :outlined="true"
            :items="['Auto', 'Total']"
            v-model="mode"
          ></v-select>
          <v-text-field
            v-model="ChargeExploitation"
            label="OPEX: Charge d'exploitation"
            :rules="[$rules.required('Charge exploitation'), $rules.isFloat]"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="PrimeInvestissement"
            label="Prime d'investissement"
            :rules="[$rules.required('Prime investissement'), $rules.isFloat]"
            outlined
          ></v-text-field>

          <label
            for="addImageAddress"
            class="drop-container"
            id="dropcontainer"
            @dragover.prevent="handleDragOver"
            @dragenter="handleDragEnter"
            @dragleave="handleDragLeave"
            @drop.prevent="handleDrop"
          >
            <span class="drop-title">Déposez Votre image ici</span>
            ou
            <p class="add-title">Ajouter une image</p>
            <input
              ref="addImageAddress"
              id="addImageAddress"
              class="inputImageAddress"
              type="file"
              @change="handleImageAddressClientChange"
              accept="image/*"
              style="display: none"
            />
          </label>

          <div v-if="addressImageToShow" class="image-preview-wrapper">
            <img :src="addressImageToShow" />
          </div>

          <div class="d-flex flex-row flex-wrap" style="gap: 10px">
            <v-btn
              class="button-download-pdf"
              :color="$colors[1]"
              @click="previewPdf()"
            >
              <span class="white--text">preview</span>
            </v-btn>
            <v-btn
              class="button-download-pdf"
              :color="$colors[0]"
              @click="generatePdf()"
            >
              <v-icon class="white--text">mdi-download-outline</v-icon>
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Modal Add Image and Download PDF -->

    <!-- pdf content to render inside pdf (chart) -->
    <div id="pdf-content">
      <div class="chart-wrapper">
        <apexchart
          type="line"
          height="300px"
          :options="consoProdChartOptions"
          :series="consoProdChartSeries"
        >
        </apexchart>
      </div>

      <div class="chart-wrapper">
        <apexchart
          type="bar"
          height="300px"
          :options="gainsChartOptions"
          :series="gainsChartSeries"
        >
        </apexchart>
      </div>

      <div class="chart-wrapper">
        <apexchart
          type="bar"
          height="300px"
          :options="amortissementChartOptions"
          :series="amortissementChartSeries"
        >
        </apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { PDFDocument, rgb, StandardFonts, layoutMultilineText } from "pdf-lib";
import VueApexCharts from "vue-apexcharts";
import html2canvas from "html2canvas";
import PVGISMap from "../map/PVGISMap.vue";
import jsPDF from "jspdf";
export default {
  props: {
    pdfData: Object,
    compteur: String,
    capture: String,
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      // choisir mode ( auto ou total)
      mode: "Auto",
      // modal image
      addressImageToShow: null,
      presPopup: false,
      // page De Garde
      pageDeGardeTitle: "",
      RespName: "",
      RespFonction: ``,
      RespEmail: "",
      RespTel: "",
      ClientName: "",
      ClientSociete: "",
      ClientTel: "",
      // page 4
      PuissanceProjetKwc: "",
      ProductionAttendueKWh: "",
      NombreDePannaux: "",
      // page 5
      address: "",
      addressImage: null,
      // page 8
      CoutTotalInstallation: "",
      RevenusExploitationMoyenAnnuelAttendu: "",
      ChargeExploitation: "",
      TempsDeRetour: "",
      // page 9
      BudgetPhotovoltaique: "",
      PrimeInvestissement: "",
      GainAnnuelMoyen: "",
      totalEconomie: "",
      totalVenteSurplus: "",
      totalGains: "",
      tableAmortissement: [],

      // charts
      consoProdChartSeries: [
        {
          name: "Votre consommation réalisée",
          type: "column",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Production solaire",
          type: "area",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      consoProdChartOptions: {
        chart: {
          id: "chartSyntheseConso",
          height: 350,
          type: "line",
          stacked: false,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          election: {
            enabled: false,
          },
        },
        colors: ["#14DAF5", "#00f7bd"],
        stroke: {
          width: [0, 2, 5],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
        },
        xaxis: {
          type: "category",
          categories: [
            "janv.",
            "fév.",
            "mars",
            "avr",
            "mai",
            "juin",
            "juill.",
            "aout",
            "sept.",
            "oct.",
            "nov.",
            "déc.",
          ],
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(0) + " kWh";
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
      },
      gainsChartSeries: [
        {
          name: "ECONOMIES SUR FACTURES",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "VENTE DE SURPLUS",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      gainsChartOptions: {
        chart: {
          id: "gainsChart",
          type: "bar",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          election: {
            enabled: false,
          },
        },
        colors: ["#00f7bd", "#14DAF5"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          type: "numeric",
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(2) + " €";
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
      amortissementChartSeries: [
        {
          name: "Amortissement",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      amortissementChartOptions: {
        chart: {
          id: "amortissementChart",
          type: "bar",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          election: {
            enabled: false,
          },
        },
        colors: ["#14DAF5", "#00f7bd"],
        plotOptions: {
          bar: {
            colors: {
              ranges: [
                {
                  from: -100,
                  to: -46,
                },
                {
                  from: -45,
                  to: 0,
                },
              ],
            },
            columnWidth: "80%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(2);
            },
          },
        },
        xaxis: {
          type: "numeric",
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            },
          },
        },
      },
    };
  },
  created() {
    this.fillPropsData();
  },
  watch: {
    pdfData: {
      deep: true,
      handler(newVal, oldVal) {
        this.fillPropsData();
      },
    },
    mode: {
      deep: true,
      handler(newVal, oldVal) {
        this.fillPropsData();
      },
    },
    presPopup: {
      handler(newVal, oldVal) {
        if (newVal) {
          console.log("take screenshot");
          this.takeScreenshot();
        }
      },
    },
  },
  methods: {
    fillPropsData() {
      // Use the created lifecycle hook to fill the child component's state
      this.pageDeGardeTitle = this.pdfData.pageDeGardeTitle;
      this.RespName = this.pdfData.RespName;
      this.RespFonction = this.pdfData.RespFonction;
      this.RespEmail = this.pdfData.RespEmail;
      this.RespTel = this.pdfData.RespTel;
      this.ClientName = this.pdfData.ClientName;
      this.ClientSociete = this.pdfData.ClientSociete;
      this.ClientTel = this.pdfData.ClientTel;
      // page 4
      this.PuissanceProjetKwc = this.pdfData.PuissanceProjetKwc;
      this.ProductionAttendueKWh = this.pdfData.ProductionAttendueKWh;
      this.NombreDePannaux = this.pdfData.NombreDePannaux;
      // page 5
      this.address = this.pdfData.address;

      // page 8
      this.CoutTotalInstallation = this.pdfData.CoutTotalInstallation;
      // page 9
      this.BudgetPhotovoltaique = this.pdfData.BudgetPhotovoltaique;

      // Fill chart data
      if (this.pdfData?.consoProdChartSeries) {
        this.consoProdChartSeries = this.pdfData.consoProdChartSeries;
      }
      if (this.mode == "Auto") {
        if (this.pdfData?.gainsChartSeries) {
          this.gainsChartSeries = this.pdfData.gainsChartSeries;
        }
        if (this.pdfData?.amortissementChartSeries) {
          this.amortissementChartSeries = this.pdfData.amortissementChartSeries;
        }
        this.tableAmortissement = this.pdfData.tableAmortissement;

        this.GainAnnuelMoyen = this.pdfData.GainAnnuelMoyen;
        this.totalEconomie = this.pdfData.totalEconomie;
        this.totalVenteSurplus = this.pdfData.totalVenteSurplus;

        this.RevenusExploitationMoyenAnnuelAttendu =
          this.pdfData.RevenusExploitationMoyenAnnuelAttendu;
        // this.ChargeExploitation = this.pdfData.ChargeExploitation;
        this.TempsDeRetour = this.pdfData.TempsDeRetour;
        this.totalGains = this.pdfData.totalGains;
      } else {
        if (this.pdfData?.gainsChartSeriesTotal) {
          this.gainsChartSeries = this.pdfData.gainsChartSeriesTotal;
        }
        if (this.pdfData?.amortissementChartSeriesTotal) {
          this.amortissementChartSeries =
            this.pdfData.amortissementChartSeriesTotal;
        }

        this.tableAmortissement = this.pdfData.tableAmortissementTotal;

        this.GainAnnuelMoyen = this.pdfData.GainAnnuelMoyenTotal;
        this.totalEconomie = this.pdfData.totalEconomieTotal;
        this.totalVenteSurplus = this.pdfData.totalVenteSurplusTotal;

        this.RevenusExploitationMoyenAnnuelAttendu =
          this.pdfData.RevenusExploitationMoyenAnnuelAttenduTotal;
        // this.ChargeExploitation = this.pdfData.ChargeExploitation;
        this.TempsDeRetour = this.pdfData.TempsDeRetourTotal;
        this.totalGains = this.pdfData.totalGainsTotal;
      }
    },
    
    async takeScreenshot() {
      // Find the element by its ID
      let element = document.querySelector('#map-container #map');

      // Check if the element exists
      if (element) {
        console.log("element", element);

        // Use html2canvas to capture the screenshot
        html2canvas(element, {
          useCORS: true,         // Try to load images from different origins
          allowTaint: false,     // Do not allow cross-origin images to taint the canvas
          logging: true,         // Enable logging for debugging
        })
        .then((canvas) => {
          canvas.toBlob((blob) => {
            // Create a File object from the Blob
            let file = new File([blob], "screenshot.png", { type: "image/png" });

            // Simulate a file input change event
            let dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            this.$refs.addImageAddress.files = dataTransfer.files;

            // Trigger the change event manually
            this.$refs.addImageAddress.dispatchEvent(new Event('change'));
          }, 'image/png');
        })
        .catch((error) => {
          console.error("Error capturing screenshot:", error);
        });
      } else {
        console.warn('Element with ID "map" not found.');
      }
    },
    showPresentationForm() {
      this.presPopup = !this.presPopup;
    },
    closePresentationForm() {
      this.presPopup = false;
    },

    handleDragOver(event) {
      event.preventDefault();
    },
    handleDragEnter(event) {
      // Add a class to indicate dragging
      event.currentTarget.classList.add("drag-active");
    },
    handleDragLeave(event) {
      // Remove the class when dragging leaves the container
      event.currentTarget.classList.remove("drag-active");
    },
    handleDrop(event) {
      event.preventDefault();

      // Handle dropped files
      event.currentTarget.classList.remove("drag-active");

      const fileInput = this.$refs.addImageAddress;
      fileInput.files = event.dataTransfer.files;

      // Call your existing method to handle the change
      this.handleImageAddressClientChange();
    },
    handleImageAddressClientChange() {
      const file = this.$refs.addImageAddress.files[0];

      if (file) {
        // Ensure that the selected file is an image
        if (file.type.startsWith("image/")) {
          // set the image for pdf
          this.addressImage = file;
          // Convert the selected image to a data URL
          const reader = new FileReader();
          reader.onload = () => {
            this.addressImageToShow = reader.result;
          };
          reader.readAsDataURL(file);
        } else {
          // Display an error message or handle the invalid file type accordingly
          alert("Invalid file type. Please select an image.");
        }
      }
    },
    to_fixed2LocaleString(num) {
      return num != null && num >= 0
        ? Number(parseFloat(num).toFixed(2))
            .toLocaleString("fr-FR")
            .replace(/\u202F/g, " ")
            .toString()
        : "";
    },
    async convertJpegToPng(jpegBytes) {
      return new Promise((resolve, reject) => {
        const image = new Image();

        image.onload = () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = image.width;
          canvas.height = image.height;

          // Draw the JPEG image onto the canvas
          context.drawImage(image, 0, 0, image.width, image.height);

          // Get the PNG bytes from the canvas
          canvas.toBlob(
            (blob) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                resolve(new Uint8Array(reader.result));
              };
              reader.readAsArrayBuffer(blob);
            },
            "image/png",
            1 // Quality (1 is the highest)
          );
        };

        // Set the JPEG bytes as the source for the image
        const blob = new Blob([jpegBytes], { type: "image/jpeg" });
        image.src = URL.createObjectURL(blob);

        image.onerror = (error) => {
          reject(error);
        };
      });
    },
    async convertApexCartToImage(chartId) {
      const chartInstance = window.Apex._chartInstances.find(
        (chart) => chart.id === chartId
      );
      const base64 = await chartInstance.chart.dataURI();
      return base64.imgURI;
    },
    // view pdf as html
    async previewPdf() {
      if (this.$refs.formPdfPreview.validate()) {
        try {
          // *********** presentation content ***********
          let pdfData = {
            // page de Garde
            vendeur: {
              prenom: "",
              nom: "",
              fonction: "",
              email: "",
              telephone: "",
            },
            societe: {
              prenom: "",
              nom: "",
              raison: "",
              siret: "",
            },

            puissanceKwc: 0,
            productionAttendueKwh: 0,
            nombrePanneaux: 0,

            // page: Synthèse technique de mon projet (Address image)
            address: null,
            codePostale: null,

            // page: Synthèse de consommation de mon projet (1 Charts)
            seriesConsoProd: [
              {
                name: "Votre consommation réalisée",
                type: "column",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              },
              {
                name: "Production solaire",
                type: "area",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              },
            ],

            // page: Synthèse de financière de mon projet (2 Charts)
            seriesGains: [
              {
                name: "ECONOMIES SUR FACTURES",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
              },
              {
                name: "VENTE DE SURPLUS",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
              },
            ],
            seriesAmortissement: [
              {
                name: "Amortissement",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
              },
            ],

            // page: Coût de mon projet
            coutTotalInstallation: 0,
            revenusExploitation: 0,
            chargesExploitation: 0,
            tempsDeRetour: null,

            // page: Synthèse financière de mon projet
            gainAnnuelMoyen: 0,
            primeInvestissement: 0,
            budgetPhotovoltaique: 0,
            totalEconomie: 0,
            totalSurplus: 0,
            totalGain: 0,
            tableauAmourtisement: [],
          };
          // fill presentation content
          pdfData.vendeur = {
            prenom: this.pdfData.RespNom,
            nom: this.pdfData.RespPrenom,
            fonction: this.pdfData.RespFonction,
            email: this.pdfData.RespEmail,
            telephone: this.pdfData.RespTel,
          };
          pdfData.societe = {
            prenom: this.pdfData.ClientNom,
            nom: this.pdfData.ClientPrenom,
            raison: this.pdfData.ClientSociete,
            siret: this.pdfData.ClientTel,
          };
          // page 4
          pdfData.puissanceKwc = this.PuissanceProjetKwc;
          pdfData.productionAttendueKwh = this.ProductionAttendueKWh;
          pdfData.nombrePanneaux = this.NombreDePannaux;
          // page 5
          pdfData.address = this.address;
          pdfData.codePostale = this.pdfData.codePostale;
          // page 8
          pdfData.coutTotalInstallation = this.CoutTotalInstallation;
          pdfData.revenusExploitation =
            this.RevenusExploitationMoyenAnnuelAttendu;
          pdfData.chargesExploitation = this.ChargeExploitation; // from input
          pdfData.tempsDeRetour = this.TempsDeRetour;
          // page 9
          // pdfData.budgetPhotovoltaique = this.pdfData.BudgetPhotovoltaique;
          pdfData.budgetPhotovoltaique = this.CoutTotalInstallation; // change it later
          pdfData.primeInvestissement = this.PrimeInvestissement; // from input
          pdfData.gainAnnuelMoyen = this.GainAnnuelMoyen;
          pdfData.totalEconomie = this.totalEconomie;
          pdfData.totalSurplus = this.totalVenteSurplus;
          pdfData.totalGain = this.totalGains;
          pdfData.tableauAmourtisement = this.tableAmortissement;

          // Fill chart data
          if (this.consoProdChartSeries) {
            pdfData.seriesConsoProd = this.consoProdChartSeries;
          }
          if (this.gainsChartSeries) {
            pdfData.seriesGains = this.gainsChartSeries;
          }
          if (this.amortissementChartSeries) {
            pdfData.seriesAmortissement = this.amortissementChartSeries;
          }

          const formData = new FormData();
          formData.append("num_compteur", this.compteur);
          formData.append("content", JSON.stringify(pdfData));
          // check if image is no uploaded use the screenshot of map
          if (this.addressImage) {
            formData.append("address_image", this.addressImage); // from input
          } else {
            formData.append("address_image", this.capture); // from input
          }

          const res = await axios.post(
            `${process.env.VUE_APP_URL_API_CLIENT}generateIndvPreviewToken/`,
            formData
          );
          if (res?.status == 200 && res?.data) {
            window.open(
              `/solar-indv-presentation/${res.data.token}`,
              "_blank",
              "noreferrer"
            );
          }
        } catch (error) {
          if (error.response.status == 400) {
            this.$toast.error("Les données de PDF ne sont pas complete.");
          }
        }
      }
    },
    // pdf generation
    async generatePdf() {
      if (this.$refs.formPdfPreview.validate()) {
        // try {
          console.log("generating pdf...");
          const pageDeGardeDoc = await this.pageDeGardeFiller();
          const page2Doc = await this.page2Filler();
          const page3Doc = await this.page3Filler();
          const page4Doc = await this.page4Filler();
          const page5Doc = await this.page5Filler();
          const page6Doc = await this.page6Filler();
          const page7Doc = await this.page7Filler();
          const page8Doc = await this.page8Filler();
          const page9Doc = await this.page9Filler();
          const pageFinaleDoc = await this.pageFinaleFiller();

          console.log("loaded and filled all pdf forms...");

          let mergedPdf = await PDFDocument.create();
          mergedPdf = await this.addPagesToPdf(mergedPdf, pageDeGardeDoc);
          mergedPdf = await this.addPagesToPdf(mergedPdf, page2Doc);
          mergedPdf = await this.addPagesToPdf(mergedPdf, page3Doc);
          mergedPdf = await this.addPagesToPdf(mergedPdf, page4Doc);
          mergedPdf = await this.addPagesToPdf(mergedPdf, page5Doc);
          mergedPdf = await this.addPagesToPdf(mergedPdf, page6Doc);
          mergedPdf = await this.addPagesToPdf(mergedPdf, page7Doc);
          mergedPdf = await this.addPagesToPdf(mergedPdf, page8Doc);
          mergedPdf = await this.addPagesToPdf(mergedPdf, page9Doc);
          mergedPdf = await this.addPagesToPdf(mergedPdf, pageFinaleDoc);

          console.log("marged all pdf...");

          // save pdf
          const filledPdfBytes = await mergedPdf.save();
          console.log("Downloading the filled PDF...");
          // Download the filled PDF
          const blob = new Blob([filledPdfBytes], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Présentation.pdf";
          link.click();
        // } catch (error) {
        //   alert("erreur lors la generation de PDF");
        // }
      }
    },
    // to merge pdf pages
    addPagesToPdf: async function (mergedPdf, donorPdf) {
      const copiedPages = await mergedPdf.copyPages(
        donorPdf,
        donorPdf.getPageIndices()
      );
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
      return mergedPdf;
    },
    // Page: page de garde
    async pageDeGardeFiller() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-1.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Get the form containing all the fields
      const form = pdfDoc.getForm();
      const fields = form.getFields();
      const page = pdfDoc.getPages()[0];
      const pageSize = page.getSize();

      const fontFamilyBold = await pdfDoc.embedFont(
        StandardFonts.HelveticaBold
      );
      const fontFamily = await pdfDoc.embedFont(StandardFonts.Helvetica);

      await this.drawPageFooter(pdfDoc, page, [0.9, 0.9, 0.9]);

      // draw title page de garde
      let widthOfTitle = 375;
      let xTitlePage = pageSize.width / 2 - widthOfTitle / 2;
      let yTitlePage = pageSize.height / 2 - 50;
      await this.drawMultiLineText({
        page: page,
        text: this.pageDeGardeTitle.toString(),
        x: xTitlePage,
        y: yTitlePage,
        font: fontFamily,
        size: 18,
        textColor: [1, 1, 1],
        align: "center",
        width: widthOfTitle,
      });

      const fontSize = 9;
      const textColor = [0, 0, 0];
      const xPreparedBy = pageSize.width / 2 - 202;
      const xSociete = pageSize.width / 2 + 50;
      //*************************************** */
      //*************** prepare par: ********** */
      //*************************************** */
      // full name
      let yStartPreparedBy = 190;
      let resp_name = this.capitalizeWords(this.RespName.toString());
      let newYPosition = await this.drawMultiLineText({
        page: page,
        text: resp_name,
        x: xPreparedBy,
        y: yStartPreparedBy,
        font: fontFamily,
        size: fontSize,
        textColor: textColor,
        width: 165,
      });
      // fonction
      yStartPreparedBy = newYPosition - 10;
      let resp_fonction = this.capitalizeWords(this.RespFonction.toString());
      newYPosition = await this.drawMultiLineText({
        page: page,
        text: resp_fonction,
        x: xPreparedBy,
        y: yStartPreparedBy,
        font: fontFamily,
        size: fontSize,
        textColor: textColor,
        width: 165,
      });
      yStartPreparedBy = newYPosition - 15;
      // email
      newYPosition = await this.drawMultiLineTextNoSpace({
        page: page,
        text: this.RespEmail.toString(),
        x: xPreparedBy,
        y: yStartPreparedBy,
        font: fontFamily,
        size: fontSize,
        textColor: textColor,
        chunkSize: 32,
      });
      yStartPreparedBy = newYPosition - 10;
      // telephone
      page.drawText(this.RespTel.toString(), {
        x: xPreparedBy,
        y: yStartPreparedBy,
        size: fontSize,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });

      //*************************************** */
      //*************** Societe *************** */
      //*************************************** */
      let ySocieteStart = 190;
      // Client full Name
      let client_name = this.capitalizeWords(this.ClientName.toString());
      ySocieteStart = await this.drawMultiLineText({
        page: page,
        text: client_name,
        x: xSociete,
        y: ySocieteStart,
        font: fontFamily,
        size: fontSize,
        textColor: textColor,
        width: 165,
      });
      ySocieteStart = ySocieteStart - 10;
      // raison sociale
      let client_raison = this.capitalizeWords(this.ClientSociete.toString());
      ySocieteStart = await this.drawMultiLineText({
        page: page,
        text: client_raison,
        x: xSociete,
        y: ySocieteStart,
        font: fontFamily,
        size: fontSize,
        textColor: textColor,
        width: 165,
      });
      ySocieteStart = ySocieteStart - 10;
      // telephone
      ySocieteStart = await this.drawMultiLineTextNoSpace({
        page: page,
        text: this.ClientTel.toString(),
        x: xSociete,
        y: ySocieteStart,
        font: fontFamily,
        size: fontSize,
        textColor: textColor,
        chunkSize: 32,
      });

      return pdfDoc;
    },
    // Page: Notre approche
    async page2Filler() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-2.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      const page = pdfDoc.getPages()[0];
      await this.drawPageFooter(pdfDoc, page, [0.55, 0.55, 0.55]);

      return pdfDoc;
    },
    // Page: Detail du processus
    async page3Filler() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-3.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      const page = pdfDoc.getPages()[0];
      await this.drawPageFooter(pdfDoc, page, [0.55, 0.55, 0.55]);

      return pdfDoc;
    },
    // Page: Synthèse technique de mon projet (Numbers)
    async page4Filler() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-4.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Get the form containing all the fields
      const form = pdfDoc.getForm();
      const fields = form.getFields();
      const page = pdfDoc.getPages()[0];
      await this.drawPageFooter(pdfDoc, page, [0.55, 0.55, 0.55]);
      // Get all fields in the PDF by their names
      const PuissanceProjetKwc = form.getTextField("PuissanceProjetKwc");
      const ProductionAttendueKWh = form.getTextField("ProductionAttendueKWh");
      const NombreDePannaux = form.getTextField("NombreDePannaux");

      // Fill in the basic info fields
      const PKWC = `${this.to_fixed2LocaleString(this.PuissanceProjetKwc)}`;
      const PAKWH = `${this.to_fixed2LocaleString(this.ProductionAttendueKWh)}`;
      const NBRP = this.NombreDePannaux.toString();
      PuissanceProjetKwc.setText(PKWC);
      ProductionAttendueKWh.setText(PAKWH);
      NombreDePannaux.setText(NBRP);

      // make pdf form input readOnly
      fields.forEach((field) => {
        field.enableReadOnly();
      });
      // change appearence from input to text
      form.updateFieldAppearances();

      return pdfDoc;
    },
    // Page: Synthèse technique de mon projet (Address Image)
    async page5Filler() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-5.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Get the form containing all the fields
      const form = pdfDoc.getForm();
      const fields = form.getFields();
      var pages = pdfDoc.getPages();
      var page = pages[0];

      await this.drawPageFooter(pdfDoc, page, [0.55, 0.55, 0.55]);

      // Get all fields in the PDF by their names
      const address = form.getTextField("address");

      // Fill in the basic info fields
      address.setText(this.address.toString());
      address.setFontSize(11);
      // Upload image from input file
      var imageFile = null;
      if (this.addressImage) {
        imageFile = this.addressImage;
      } else {
        imageFile = this.capture;
      }

      if (imageFile) {
        // Convert the image file to bytes
        const imageBytes = await imageFile.arrayBuffer();
        // Check the image type and use the appropriate function
        if (imageFile.type === "image/png") {
          // embed the image to the pdf
          const imagePage = await pdfDoc.embedPng(imageBytes);
          const { width, height } = page.getSize();
          // Add the image to the PDF
          page.drawImage(imagePage, {
            x: 125,
            y: 180,
            width: 400,
            height: 300,
          });
        } else if (imageFile.type === "image/jpeg") {
          // Convert jpeg image to PNG image
          const convertedImageBytes = await this.convertJpegToPng(imageBytes);
          // embed the image to the pdf
          const imagePage = await pdfDoc.embedPng(convertedImageBytes);
          const { width, height } = page.getSize();
          // Add the image to the PDF
          page.drawImage(imagePage, {
            x: 125,
            y: 180,
            width: 400,
            height: 300,
          });
        } else {
          console.error("Unsupported image type. Please use PNG or JPEG.");
          page.drawRectangle({
            x: 125,
            y: 180,
            width: 400,
            height: 300,
            color: rgb(0.94, 0.96, 0.97),
            borderColor: rgb(0.6, 0.64, 0.7),
            borderWidth: 1.5,
          });
        }
      } else {
        console.warn("No Image Selected. Please use PNG or JPEG.");
        page.drawRectangle({
          x: 125,
          y: 180,
          width: 350,
          height: 350,
          color: rgb(0.94, 0.96, 0.97),
          borderColor: rgb(0.6, 0.64, 0.7),
          borderWidth: 1.5,
        });
      }

      // make pdf form input readOnly
      fields.forEach((field) => {
        field.enableReadOnly();
      });
      // change appearence from input to text
      form.updateFieldAppearances();

      return pdfDoc;
    },
    // Page: Synthèse de consommation de mon projet (chart conso)
    async page6Filler() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-6.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      var pages = pdfDoc.getPages();
      var page = pages[0];

      await this.drawPageFooter(pdfDoc, page, [0.55, 0.55, 0.55]);

      // convert apexchart into png image
      try {
        const imageChartDataURL = await this.convertApexCartToImage(
          "chartSyntheseConso"
        );

        if (imageChartDataURL) {
          // Convert the image file to bytes
          const imageBytes = await fetch(imageChartDataURL).then((res) =>
            res.arrayBuffer()
          );
          const imagePage = await pdfDoc.embedPng(imageBytes);
          page.drawImage(imagePage, {
            x: 40,
            y: 400,
            width: 500,
            height: 200,
          });
        }
      } catch (error) {
        console.error("error seting chart consoProd:", error);
      }

      return pdfDoc;
    },
    // Page: Synthèse financière de mon projet (chart gains + amortissement)
    async page7Filler() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-7.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      var pages = pdfDoc.getPages();
      var page = pages[0];

      await this.drawPageFooter(pdfDoc, page, [0.55, 0.55, 0.55]);

      // convert gainsChart apexchart into png image
      try {
        const imageGainsChartDataURL = await this.convertApexCartToImage(
          "gainsChart"
        );
        const imageAmortissementChartDataURL =
          await this.convertApexCartToImage("amortissementChart");
        if (imageGainsChartDataURL) {
          // Convert the image file to bytes
          const imageBytes = await fetch(imageGainsChartDataURL).then((res) =>
            res.arrayBuffer()
          );
          const imagePage = await pdfDoc.embedPng(imageBytes);
          page.drawImage(imagePage, {
            x: 40,
            y: 450,
            width: 500,
            height: 200,
          });
        }
        if (imageAmortissementChartDataURL) {
          // Convert the image file to bytes
          const imageBytes = await fetch(imageAmortissementChartDataURL).then(
            (res) => res.arrayBuffer()
          );
          const imagePage = await pdfDoc.embedPng(imageBytes);
          page.drawImage(imagePage, {
            x: 40,
            y: 100,
            width: 500,
            height: 200,
          });
        }
      } catch (error) {
        console.error("error seting chart gains + amortissement:", error);
      }

      return pdfDoc;
    },
    // Page: Cout de mon projet
    async page8Filler() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-8.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Get the form containing all the fields
      const form = pdfDoc.getForm();
      const fields = form.getFields();
      const page = pdfDoc.getPages()[0];

      await this.drawPageFooter(pdfDoc, page, [0.55, 0.55, 0.55]);

      // Get all fields in the PDF by their names
      const CoutTotalInstallation = form.getTextField("CoutTotalInstallation");
      const RevenusExploitationMoyenAnnuelAttendu = form.getTextField(
        "RevenusExploitationMoyenAnnuelAttendu"
      );
      const ChargeExploitation = form.getTextField("ChargeExploitation");
      const TempsDeRetour = form.getTextField("TempsDeRetour");

      // Fill in the basic info fields
      const CI = `${this.to_fixed2LocaleString(
        this.CoutTotalInstallation
      )} €`;
      const EA = `${this.to_fixed2LocaleString(
        this.RevenusExploitationMoyenAnnuelAttendu
      )} €/an`;
      const CE = `${this.to_fixed2LocaleString(this.ChargeExploitation)} €/an`;
      const TR = this.TempsDeRetour.toString();
      CoutTotalInstallation.setText(CI);
      RevenusExploitationMoyenAnnuelAttendu.setText(EA);
      ChargeExploitation.setText(CE);
      TempsDeRetour.setText(TR);

      // make pdf form input readOnly
      fields.forEach((field) => {
        field.enableReadOnly();
      });
      // change appearence from input to text
      form.updateFieldAppearances();

      return pdfDoc;
    },
    // Page: Synthèse financière de mon projet (Table)
    async page9Filler() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-9.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Get the form containing all the fields
      const form = pdfDoc.getForm();
      const fields = form.getFields();
      const page = pdfDoc.getPages()[0];

      await this.drawPageFooter(pdfDoc, page, [0.55, 0.55, 0.55]);

      // Get all fields in the PDF by their names
      const BudgetPhotovoltaique = form.getTextField("BudgetPhotovoltaique");
      const PrimeInvestissement = form.getTextField("PrimeInvestissement");
      const GainAnnuelMoyen = form.getTextField("GainAnnuelMoyen");

      // get Table Total fields
      const totalEconomie = form.getTextField("tablefinance-total-economie");
      const totalVenteSurplus = form.getTextField(
        "tablefinance-total-venteSurplus"
      );
      const totalGains = form.getTextField("tablefinance-total-gains");

      // Fill in the basic info fields
      const BP = `${this.to_fixed2LocaleString(this.CoutTotalInstallation)} €`;
      const PI = `${this.to_fixed2LocaleString(this.PrimeInvestissement)} €`;
      const GAM = `${this.to_fixed2LocaleString(this.GainAnnuelMoyen)} €`;
      BudgetPhotovoltaique.setText(BP);
      PrimeInvestissement.setText(PI);
      GainAnnuelMoyen.setText(GAM);

      // Fill table Total fields
      const TE = `${this.to_fixed2LocaleString(this.totalEconomie)}`;
      const TVS = `${this.to_fixed2LocaleString(this.totalVenteSurplus)}`;
      const TG = `${this.to_fixed2LocaleString(this.totalGains)}`;
      totalEconomie.setText(TE);
      totalVenteSurplus.setText(TVS);
      totalGains.setText(TG);
      // Fill table values
      for (let i = 0; i < 25; i++) {
        for (let j = 1; j <= 4; j++) {
          let value = 0;
          if (this.tableAmortissement[i]) {
            if (j == 1)
              value = this.tableAmortissement[i].productionSolar * 1000;
            if (j == 2) value = this.tableAmortissement[i].enconomie;
            if (j == 3) value = this.tableAmortissement[i].surplus;
            if (j == 4) value = this.tableAmortissement[i].gainTotal;
          }

          const field = form.getTextField(`tablefinance-${i + 1}-${j}`);
          const tranformedValue = `${this.to_fixed2LocaleString(value)}`;
          field.setText(tranformedValue);
        }
      }

      // make pdf form input readOnly
      fields.forEach((field) => {
        field.enableReadOnly();
      });
      // change appearence from input to text
      form.updateFieldAppearances();

      return pdfDoc;
    },
    // Page: Finale
    async pageFinaleFiller() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-10.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const page = pdfDoc.getPages()[0];

      const pageSize = page.getSize();
      const fontFamily = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const fontSize = 18;
      const textColor = [1, 1, 1];
      const widthOfText = 200;
      const x = pageSize.width / 2 - widthOfText / 2;
      let y = pageSize.height / 2 + 35;
      const ySpacing = 22;
      await this.drawAlignedText({
        page: page,
        x: x,
        y: y,
        align: "center",
        text: "Eneffic",
        font: fontFamily,
        size: fontSize,
        textColor: textColor,
        width: widthOfText,
      });
      y = y - ySpacing;
      await this.drawAlignedText({
        page: page,
        x: x,
        y: y,
        align: "center",
        text: "2 All du Levant",
        font: fontFamily,
        size: fontSize,
        textColor: textColor,
        width: widthOfText,
      });
      y = y - ySpacing;
      await this.drawAlignedText({
        page: page,
        x: x,
        y: y,
        align: "center",
        text: "34970 Lattes",
        font: fontFamily,
        size: fontSize,
        textColor: textColor,
        width: widthOfText,
      });

      y = y - ySpacing - 20;
      await this.drawAlignedText({
        page: page,
        x: x,
        y: y,
        align: "center",
        text: "33 (0)4 65 84 38 76",
        font: fontFamily,
        size: fontSize,
        textColor: textColor,
        width: widthOfText,
      });
      y = y - ySpacing;
      await this.drawAlignedText({
        page: page,
        x: x,
        y: y,
        align: "center",
        text: "contact@eneffic.fr",
        font: fontFamily,
        size: fontSize,
        textColor: textColor,
        width: widthOfText,
      });

      return pdfDoc;
    },
    async drawPageFooter(pdfDoc, page, textColor) {
      const pageSize = page.getSize();
      const fontFamily = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const fontSize = 8;
      const widthOfText = 200;
      const x = pageSize.width / 2 - widthOfText / 2;
      let y = 45;
      await this.drawAlignedText({
        page: page,
        x: x,
        y: y,
        align: "center",
        text: "ENEFFIC - 2 Allée du Levant 34970 Lattes",
        font: fontFamily,
        size: fontSize,
        textColor: textColor,
        width: widthOfText,
      });
      y = y - fontSize;
      await this.drawAlignedText({
        page: page,
        x: x,
        y: y,
        align: "center",
        text: "Téléphone : +33 (0)4 65 84 38 76 - E-mail : contact@eneffic.fr",
        font: fontFamily,
        size: fontSize,
        textColor: textColor,
        width: widthOfText,
      });
      y = y - fontSize;
      await this.drawAlignedText({
        page: page,
        x: x,
        y: y,
        align: "center",
        text: "SIREN : 951 979 798",
        font: fontFamily,
        size: fontSize,
        textColor: textColor,
        width: widthOfText,
      });
    },

    async drawAlignedText({
      page,
      text,
      x = 0,
      y = 0,
      font,
      size,
      align,
      textColor = [0, 0, 0],
      width = 0,
      height = 0,
      borderWidth = 0,
      borderColor,
    }) {
      const widthText = font.widthOfTextAtSize(text, size);
      const heightText = font.heightAtSize(size) - 2;
      // calculate center position
      let xPosition = x + width / 2;
      if (align == "center") xPosition = xPosition - widthText / 2;
      if (align == "left") xPosition = x;
      // draw rectangle
      if (width > 0 && borderWidth > 0) {
        let rectProps = {
          x: x,
          y: y,
          width: width,
          height: height > 0 ? height : heightText,
          borderWidth: borderWidth,
        };
        if (borderColor)
          rectProps.borderColor = rgb(
            borderColor[0],
            borderColor[1],
            borderColor[2]
          );
        page.drawRectangle(rectProps);
      }
      if (height > 0) y = y + height / 2 - heightText / 2;
      // draw text
      page.drawText(text, {
        x: xPosition,
        y: y,
        size: size,
        font: font,
        color: rgb(textColor[0], textColor[1], textColor[2]),
      });
    },
    async drawWrappedMultiLineText({
      page,
      text,
      x = 0,
      y = 0,
      font,
      size,
      align,
      textColor = [0, 0, 0],
      width = 0,
      height = 0,
      borderWidth = 0,
      borderColor,
      bgColor,
      paddingHorizontal = 0,
      paddingVertical = 0,
    }) {
      // Prepare for multi-line text layout
      const multiText = layoutMultilineText(text, {
        alignment: align || "left",
        fontSize: size,
        font: font,
        lineHeight: size * 1.2, // Line height is often 120% of font size
        bounds: { width: width - paddingHorizontal * 2, height: 1000 }, // Set a very high height to ensure all text is considered
      });

      // Calculate the total height of the text content
      const textContentHeight = multiText.lines.length * size * 1.2;

      // Adjust vertical starting position based on the total height of the laid out text
      let startingPosition = y;
      startingPosition =
        y +
        (height > 0 ? (height - textContentHeight) / 2 : 0) +
        textContentHeight -
        size /* * 1.2 */ +
        paddingVertical;

      // Draw border rectangle if required
      let rectangleWidth = width;
      let rectangleHeight =
        (height > 0 ? height : textContentHeight) + 2 * paddingVertical;
      if (width > 0 && (borderWidth > 0 || bgColor?.length == 3)) {
        let rectProps = {
          x: x,
          y: y,
          width: rectangleWidth,
          height: rectangleHeight,
          borderWidth: borderWidth,
        };
        if (bgColor) rectProps.color = rgb(bgColor[0], bgColor[1], bgColor[2]);
        if (borderColor)
          rectProps.borderColor = rgb(
            borderColor[0],
            borderColor[1],
            borderColor[2]
          );
        page.drawRectangle(rectProps);
      }

      // Draw each line of text
      multiText.lines.forEach((line) => {
        const widthText = font.widthOfTextAtSize(line.text, size);

        // calculate center position
        let lineX = x;
        if (align === "left") lineX += paddingHorizontal;
        if (align === "center") lineX += (width - widthText) / 2;
        if (align === "right") lineX += width - widthText - paddingHorizontal;

        page.drawText(line.text, {
          x: lineX,
          y: startingPosition,
          size: size,
          font: font,
          color: rgb(textColor[0], textColor[1], textColor[2]),
        });
        startingPosition -= size * 1.2; // Move down for the next line
      });

      return { rectangleWidth, rectangleHeight };
    },
    async drawMultiLineText({
      page,
      text,
      x = 0,
      y = 0,
      font,
      size,
      lineHeight,
      align,
      textColor = [0, 0, 0],
      width = 0,
    }) {
      const lineSpace = lineHeight || size * 1.2; // Line height is often 120% of font size
      const heightText = font.heightAtSize(size) - size * 0.2;
      // Prepare for multi-line text layout
      const multiText = layoutMultilineText(text, {
        alignment: align || "left",
        fontSize: size,
        font: font,
        lineHeight: lineSpace,
        bounds: { width: width, height: 1000 },
      });

      // Calculate the total height of the text content
      const textContentHeight = multiText.lines.length * size * 1.2;

      // Adjust vertical starting position based on the total height of the laid out text
      let startingPosition = y;
      // startingPosition = y + textContentHeight - lineSpace;

      // Draw each line of text
      for (let i = 0; i < multiText.lines.length; i++) {
        const line = multiText.lines[i];
        const widthText = font.widthOfTextAtSize(line.text, size);

        // calculate center position
        let lineX = x;
        if (align === "left") lineX;
        if (align === "center") lineX += (width - widthText) / 2;
        if (align === "right") lineX += width - widthText;

        page.drawText(line.text, {
          x: lineX,
          y: startingPosition,
          size: size,
          font: font,
          lineHeight: lineSpace,
          color: rgb(textColor[0], textColor[1], textColor[2]),
        });
        // Move down for to the next line when its not the last line
        if (i < multiText.lines.length - 1) {
          startingPosition = startingPosition - lineSpace;
        }
      }

      return startingPosition - heightText;
    },
    async drawMultiLineTextNoSpace({
      page,
      text,
      x = 0,
      y = 0,
      font,
      size,
      textColor = [0, 0, 0],
      chunkSize = 24,
    }) {
      const lineHeight = size * 1.1;
      const heightText = font.heightAtSize(size) - size * 0.1;
      // Prepare for multi-line text layout
      const multiText = [];
      for (let i = 0; i < text.length; i += chunkSize) {
        multiText.push(text.slice(i, i + chunkSize));
      }

      // Calculate the total height of the text content
      const textContentHeight = multiText.length * lineHeight;

      // Adjust vertical starting position based on the total height of the laid out text
      let startingPosition = y;
      // Draw each line of text
      for (let i = 0; i < multiText.length; i++) {
        let lineX = x;
        page.drawText(multiText[i], {
          x: lineX,
          y: startingPosition,
          font: font,
          size: size,
          lineHeight: lineHeight,
          color: rgb(textColor[0], textColor[1], textColor[2]),
        });
        // Move down for to the next line when its not the last line
        if (i < multiText.length - 1) {
          startingPosition = startingPosition - lineHeight;
        }
      }

      return startingPosition - heightText;
    },

    // formatters
    capitalizeWords(text) {
      if (!text) return "";
      // Split the text into an array of words
      let words = text.split(" ");
      // Iterate through each word and capitalize the first letter
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      // Join the words back into a single string and return
      return words.join(" ");
    },
    formatNumberOrText(num, tofFixedNumber) {
      if (!isNaN(num) && num !== null && num !== undefined && num !== "") {
        // num is number
        return (
          Number(parseFloat(num).toFixed(tofFixedNumber))
            .toLocaleString("fr-FR")
            .replace(/\u202F/g, " ")
            .toString() + ""
        );
      } else {
        // num is text
        if (num !== null && num !== undefined && num !== "") {
          return num;
        }
        return "";
      }
    },
    to_fixed2LocaleStringEuro(num) {
      if (!isNaN(num) && num !== null && num !== undefined && num !== "") {
        // num is number
        if (num == 0) return "0";
        return (
          Number(parseFloat(num).toFixed(2))
            .toLocaleString("fr-FR")
            .replace(/\u202F/g, " ")
            .toString() + " €"
        );
      } else {
        // num is text
        if (num !== null && num !== undefined && num !== "") {
          return num;
        }
        return "";
      }
    },
    to_fixed2OrText(num) {
      if (!isNaN(num) && num !== null && num !== undefined && num !== "") {
        // num is number
        return (
          Number(parseFloat(num).toFixed(2))
            .toLocaleString("fr-FR")
            .replace(/\u202F/g, " ")
            .toString() + ""
        );
      } else {
        // num is text
        if (num !== null && num !== undefined && num !== "") {
          return num;
        }
        return "";
      }
    },
  },
};
</script>

<style scoped>
#pdf-content {
  width: 0px;
  height: 0px;
  overflow: hidden;
}
.chart-wrapper {
  width: 800px;
  height: 300px;
  background: #fff;
}
.download-pdf-indv {
  background: #fff;
}

.download-pdf-indv-header {
  color: #141414 !important;
  margin-bottom: 32px;
}
.inputImageAddress::file-selector-button {
  margin-right: 20px;
  border: none;
  background: rgb(0, 121, 145);
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.inputImageAddress::file-selector-button:hover {
  background: rgb(0, 121, 145);
}
.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
}
.drop-container.drag-active {
  background: #eee;
  border-color: #111;
}

.drop-container.drag-active .drop-title {
  color: #222;
}

.add-title {
  color: #747777;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  transition: color 0.2s ease-in-out;
}

.image-preview-wrapper {
  width: 100%;
  height: 250px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 20px;
  text-align: center;
  background: #b5bec2;
  img {
    width: auto;
    height: 100%;
  }
}
.download-pdf-indv-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.button-download-pdf {
  max-width: 100%;
  width: 100px;
  height: 44px;
  margin-top: 20px;
}

::v-deep .v-dialog {
  background: #fff !important;
}
</style>
