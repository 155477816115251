<template>
  <div class="page-blanc">
    <img class="center-shape" src="../images/shapes/shape-center.png" />
    <img class="left-center-shape" src="../images/shapes/shape-1.png" />
    <img class="right-top-shape" src="../images/shapes/shape-3.png" />
    <img class="bottom-right-shape" src="../images/shapes/shape-2.png" />

    <img 
    class="logo-enffic-pdf"
    src="../images/logo-eneffic.png"
    />
    <p class="page-title">
      <span>Synthèse technique de mon projet</span>
    </p>
    <div class="content">
      <div class="cards-wrapper">
        <div class="card">
          <p class="titleOfCard">Puissance du projet en kWc</p>
          <div class="priceAndIcon">
            <p class="price">{{ Number(puissanceKwc).toLocaleString("fr-FR") }}</p>
            <img src="../images/icons/elec-cable.png" />
          </div>
        </div>
        <div class="card right">
          <p class="titleOfCard">Production Attendue en kWh</p>
          <p class="price">{{ Number(productionAttendueKwh).toLocaleString("fr-FR") }}</p>
        </div>
        <div class="card">
          <p class="titleOfCard">Nombre de panneaux</p>
          <div class="priceAndIcon">
            <p class="price">{{ nombrePanneaux }}</p>
            <img src="../images/icons/nombre-de-panneaux.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <ul>
        <li>ENEFFIC - 2 Aliée du Levant 34970 Lattes</li>
        <li>Téléphone: +33 (0)4 65 84 38 76 - E-mail: contact@eneffic.fr</li>
        <li>SIREN: 951 979 798</li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "SyntheseTechniqueDetail",
  props: [
    "puissanceKwc",
    "productionAttendueKwh",
    "nombrePanneaux",
  ],
};
</script>

<style lang="scss" scoped>
.page-blanc{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 32px;

  .center-shape{
    position: absolute;
    width: 600px;
    height: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .left-center-shape{
    position: absolute;
    width: 140px;
    height: 220px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  .right-top-shape{
    position: absolute;
    width: 75px;
    height: 75px;
    top: 15.5%;
    right: 0;
  }

  .bottom-right-shape{
    position: absolute;
    width: 150px;
    height: 150px;
    bottom: 0;
    right: 0;
  }

  .logo-enffic-pdf{
    height: 24px;
    margin-top: 40px;
    margin-left: 40px;
  }
  
  .page-title{
    width: fit-content;
    position: relative;
    font-size: 18pt;
    font-weight: 600;
    margin: 100px auto 0px;
    span{
      position: relative;
    }
    &::before{
      content: "";
      position: absolute;
      display: block;
      width: 50%;
      height: 16px;
      top: 60%;
      left: 0;
      background: #00f7bd;
    }
  }

  .content{
    position: relative;
    width: 100%;
    padding: 100px 32px;

    .cards-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 60px;
      margin: 0px 100px;

      .card{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 15px 20px;
        width: 450px;
        height: 200px;
        border-radius: 24px;
        // border: none;
        border: 4px solid rgba(240, 245, 244, 0.15);
        outline: none;
        text-align: center;
        box-shadow: 1px 4px 16px 0px rgba(185, 185, 185, 0.75);

        &.right{
          align-self: flex-end;
        }

        .titleOfCard{
          font-size: 20pt;
          font-weight: 600;
          text-align: center;
          color:#075094;
          margin-bottom: 24px;
        }
        .priceAndIcon{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .price{
          font-size: 28pt;
          font-weight: 600;
          text-align: center;
          color:#14324F;
        }
        img{
          height: 80px;
        }
      }
    }

  }
  .footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 2;
    font-size: 10pt;
    color: #14324F;
    font-weight: 400;
    text-align: center;
    ul{
      list-style-type: none;
    }
  }
}
</style>