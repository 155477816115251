<template>
  <div class="page-blanc">
    <img class="center-shape" src="../images/shapes/shape-center.png" />
    <img class="left-center-shape" src="../images/shapes/shape-1.png" />
    <img class="right-top-shape" src="../images/shapes/shape-3.png" />

    <img 
    class="logo-enffic-pdf"
    src="../images/logo-eneffic.png"
    />
    <p class="page-title">
      <span>Coût de mon projet</span>
    </p>
    <p class="page-subtitle">Récapitulatif</p>
    <div class="content">
      <div class="cards-wrapper">
        <div class="card">
          <p class="titleOfCard">Cout Total de l'installation</p>
          <p class="price">{{ Number(coutTotalInstallation).toLocaleString("fr-FR") }} €</p>
        </div>
        <div class="card">
          <p class="titleOfCard">Revenus d'exploitation</p>
          <p class="price">{{ Number(revenusExploitation).toLocaleString("fr-FR") }} €/an</p>
        </div>
      </div>
      <div class="cards-wrapper">
        <div class="card">
          <p class="titleOfCard">OPEX: Charges d'exploitation</p>
          <p class="price">{{ Number(chargesExploitation).toLocaleString("fr-FR") }} €/an</p>
        </div>
        <div class="card">
          <p class="titleOfCard">temps de retour</p>
          <p class="price">{{ tempsDeRetour }}</p>
        </div>
      </div>
      <div class="lampe-wrapper">
        <img class="lampe-icon" src="../images/icons/lampe-no-bg.png" />
      </div>
      
    </div>
    <div class="footer">
      <ul>
        <li>ENEFFIC - 2 Aliée du Levant 34970 Lattes</li>
        <li>Téléphone: +33 (0)4 65 84 38 76 - E-mail: contact@eneffic.fr</li>
        <li>SIREN: 951 979 798</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "coutTotalInstallation",
    "revenusExploitation",
    "chargesExploitation",
    "tempsDeRetour",
  ],
};
</script>

<style lang="scss" scoped>
.page-blanc{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 32px;

  .center-shape{
    position: absolute;
    width: 600px;
    height: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .left-center-shape{
    position: absolute;
    width: 140px;
    height: 220px;
    top: 50%;
    left: 0;
  }
  .right-top-shape{
    position: absolute;
    width: 75px;
    height: 75px;
    top: 15.5%;
    right: 0;
  }

  .logo-enffic-pdf{
    height: 24px;
    margin-top: 40px;
    margin-left: 40px;
  }
  
  .page-title{
    width: fit-content;
    position: relative;
    font-size: 18pt;
    font-weight: 600;
    margin: 60px auto 32px;
    span{
      position: relative;
    }
    &::before{
      content: "";
      position: absolute;
      display: block;
      width: 90%;
      height: 16px;
      top: 60%;
      left: 0;
      background: #00f7bd;
    }
  }

  .page-subtitle{
    font-size: 16pt;
    font-weight: 400;
    text-align: center;
    margin-bottom: 150px;
  }

  .content{
    position: relative;
    width: 100%;

    .cards-wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      gap: 32px;
      padding: 20px;

      .card{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        width: 350px;
        height: 165px;
        border-radius: 20px;
        border: none;
        // border: 4px solid rgba(240, 245, 244, 0.15);
        outline: none;
        text-align: center;
        box-shadow: 1px 4px 16px 0px rgba(180, 180, 180, 0.75);

        .titleOfCard{
          font-size: 16pt;
          font-weight: 600;
          text-align: center;
          color:#073c6d;
          margin-bottom: 10px;
        }
        .price{
          font-size: 20pt;
          font-weight: 500;
          text-align: center;
          color:#14324F;
          margin-bottom: 10px;
        }
      }
    }

    .lampe-wrapper{
      width: fit-content;
      margin: 40px auto;
      .lampe-icon{
        width: 300px; 
        height: 300px;
      }
    }

  }
  .footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 2;
    font-size: 10pt;
    color: #14324F;
    font-weight: 400;
    text-align: center;
    ul{
      list-style-type: none;
    }
  }
}
</style>