<template>
    <section>
      <div class="ma-8">
        <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Demande Cotation</a></li>
            <li class="breadcrumb-item active" aria-current="page">Details Client Particulier</li>
          </ol>
        </nav>
  
        <div class="row">
          <div class="col-lg-4">
            <div class="card mb-4">
              <div class="card-body text-center">
                <!--<label class="btn-edit-img" for="file-input">
                  <i class="mdi mdi-image-edit-outline" style="font-size: 28px"></i>
                </label>-->
                <h5 class="my-3">{{ clientPDetail.raison }}</h5>
                <p class="text-muted mb-1">{{ clientPDetail.prenom }}  {{ clientPDetail.nom }}</p>
                <p class="text-muted mb-4">{{ clientPDetail.adresseEmail }}</p>
              </div>
            </div>
            <div class="card">
              <div class="card-body p-0">
                <ul class="list-group list-group-flush">
                  <!--<li class="list-group-item">
                    <button
                      @click="changeCardContent('info')"
                      class="btn btn-block text-left list-button-item"
                    >
                      <span class="icon">
                        <i
                          class="mdi mdi-information-variant-circle-outline mx-auto"
                          style="color: rgb(255, 255, 255)"
                        ></i>
                      </span>
                      <span class="text-button"> Plan</span>
                    </button>
                  </li>-->
                  <li class="list-group-item">
                    <button
                      @click="changeCardContent('info')"
                      class="btn btn-block text-left list-button-item"
                    >
                      <span class="icon">
                        <i
                          class="mdi mdi-information-variant-circle-outline mx-auto"
                          style="color: rgb(255, 255, 255)"
                        ></i>
                      </span>
                      <span class="text-button"> Informations</span>
                    </button>
                  </li>
                  <li class="list-group-item">
                    <button
                      @click="changeCardContent('cotation')"
                      class="btn btn-block text-left list-button-item"
                    >
                      <span class="icon">
                        <i
                          class="mdi mdi-currency-usd mx-auto"
                          style="color: rgb(255, 255, 255)"
                        ></i>
                      </span>
                      <span class="text-button"> Cotation</span>
                    </button>
                  </li>
                  <!--<li class="list-group-item">
                    <button
                      @click="changeCardContent('visite')"
                      class="btn btn-block text-left list-button-item"
                    >
                      <span class="icon">
                        <i
                          class="mdi mdi-toolbox-outline mx-auto"
                          style="color: rgb(255, 255, 255)"
                        ></i>
                      </span>
                      <span class="text-button">Visite technique</span>
                    </button>
                  </li>
                  <li class="list-group-item">
                    <button
                      @click="changeCardContent('installation')"
                      class="btn btn-block text-left list-button-item"
                    >
                      <span class="icon">
                        <i
                          class="mdi mdi-hammer-screwdriver mx-auto"
                          style="color: rgb(255, 255, 255)"
                        ></i>
                      </span>
                      <span class="text-button">Visite installation</span>
                    </button>
                  </li>-->
                  <li class="list-group-item">
                    <button
                      @click="changeCardContent('list-devis')"
                      class="btn btn-block text-left list-button-item"
                    >
                      <span class="icon">
                        <i
                          class="mdi mdi-receipt-text-outline mx-auto"
                          style="color: rgb(255, 255, 255)"
                        ></i>
                      </span>
                      <span class="text-button">Devis</span>
                    </button>
                  </li>
                  <li class="list-group-item">
                    <v-expansion-panels flat>
                      <v-expansion-panel>
                        <v-expansion-panel-header class="pa-0">
                          <button class="btn btn-block text-left list-button-item py-0">
                            <span class="icon">
                              <i
                                class="mdi mdi-file-document-multiple-outline mx-auto"
                                style="color: rgb(255, 255, 255)"
                              ></i>
                            </span>
                            <span class="text-button">Fichiers</span>
                          </button>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <ul class="list-group list-group-flush">
                            <li v-for="item in listFilePage" class="list-group-item">
                              <button
                                @click="changeCardContent(item.label)"
                                class="btn btn-block text-left list-button-item"
                              >
                                <span class="icon">
                                  <i
                                    class="mdi mdi-file-document-outline mx-auto"
                                    style="color: rgb(255, 255, 255)"
                                  ></i>
                                </span>
                                <span class="text-button"> {{ item.label }}</span>
                              </button>
                            </li>
                          </ul>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="pb-0 col-lg-8">
            <div v-if="filePage.CardSelected">
              <!--<Gallery  v-if="filePage.label != 'Photo'" />-->
              <GalleryImage :filesParentProps="[]" :label="filePage" :idClientP="id" />
            </div>
  
            <!-- CARD BODY IF THE INFO BUTTON CLICKED TO DISPLAY SOCIETE INFO -->
            <div v-if="infoCardSelected">
              <InfoClient
                @newListModel="recupNewList($event)"
                :listSelectProps="listSelect"
                :listModelProps="listModel"
                :dataProps="clientPDetail"
              />
            </div>
            <!-- END CARD BODY IF THE INFO  -->
  
            <!-- CARD BODY FOR COTATION SELECTED -->
            <div v-if="cotationCardSelected">
              <CotationClientP :id="id" />
            </div>
            <!-- END CARD BODY FOR COTATION SELECTED -->
  
            <!-- CARD BODY FOR VISITE SELECTED -->
            <div v-if="visiteCardSelected">
              <VisiteTechniqueSociete :id="id" />
            </div>
            <!-- END CARD BODY FOR VISITE SELECTED -->
  
            <!-- CARD BODY FOR VISITE INSTALLATION SELECTED -->
            <div v-if="installationCardSelected">
              <VisiteInstallationSociete :id="id" />
            </div>
            <!-- END CARD BODY FOR VISITE INSTALLATION SELECTED -->
  
            <!-- CARD BODY FOR LIST DEVIS SELECTED -->
            <div v-if="listDevisCardSelected">
              <ListDevisClientP :id="id" />
            </div>
            <!-- END CARD BODY FOR LIST DEVIS SELECTED -->
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import axios from "@/plugins/axios";
  //import LineChart from "./chartComponent/LineChart.vue";
  import Datatable from "../../components/dataTable/Datatable.vue";
  import Pagination from "../../components/dataTable/Pagination.vue";
  import noDataTable from "../../components/dataTable/no-data-table.vue";
  import progressCircular from "../../components/dataTable/progress-circular.vue";
  import InfoClient from "./components/InfoClient.vue";
  import CotationClientP from "./components/CotationClientP.vue";
  import VisiteTechniqueSociete from "./components/VisiteTechniqueSociete.vue";
  import VisiteInstallationSociete from "./components/VisiteInstallationSociete.vue";
  import ListDevisClientP from "./components/ListDevisClientP.vue";
  import ListValideDevisSociete from "./components/ListValideDevisSociete.vue";
  import Gallery from "./ClientPComponents/gallery.vue";
  import GalleryImage from "./ClientPComponents/galleryImage.vue";
  export default {
    components: {
      datatable: Datatable,
      pagination: Pagination,
      noDataTable,
      progressCircular,
      InfoClient,
      CotationClientP,
      VisiteTechniqueSociete,
      VisiteInstallationSociete,
      ListDevisClientP,
      ListValideDevisSociete,
      Gallery,
      GalleryImage,
    },
    name: "DetailClientP",
    props: ["id"],
    data() {
      return {
        filePage: {},
        listFilePage: [
          {
            label: "Plan",
            typeData: "files",
            CardSelected: false,
            url: "getListPlanProfilClientParticulier/",
            uploadUrl: "uploadPlanDocProfilClientParticulier/",
            hideFolder: true,
            categorie: "Plan",
          },
          // {
          //   label: "Demande",
          //   typeData: "files",
          //   CardSelected: false,
          //   url: "getListDemandeClient/",
          //   uploadUrl: "uploadDemandeClientDoc/",
          //   hideFolder: true,
          //   categorie: "DemandeClient",
          // },
          // {
          //   label: "Photo",
          //   typeData: "folder/images",
          //   CardSelected: false,
          //   url: "",
          //   uploadUrl: "uploadPhotoDocProfilClient/",
          //   hideFolder: false,
          //   categorie: "Photos",
          // },
          // {
          //   label: "DOE",
          //   typeData: "files",
          //   CardSelected: false,
          //   url: "getListDoeProfilClient/",
          //   uploadUrl: "uploadDoeDocProfilClient/",
          //   hideFolder: true,
          //   categorie: "Doe",
          // },
          // {
          //   label: "Piece",
          //   typeData: "files",
          //   CardSelected: false,
          //   url: "getListPieceAdministrativeProfilClient/",
          //   uploadUrl: "uploadPieceAdministrativeDocProfilClient/",
          //   hideFolder: true,
          //   categorie: "PieceAdministrative",
          // },
        ],
        fileDetailsSelected: {},
        imageUrl: "",
        selected: null,
        infoCardSelected: true,
        cotationCardSelected: false,
        visiteCardSelected: false,
        installationCardSelected: false,
        listDevisCardSelected: false,
        clientPDetail: {},
        listSelect: [
          "ChefProjet",
          "ResponsableInformatique",
          "ResponsableTechnique",
          "ContactComptabilite",
        ],
        listModel: {
          ChefProjet: {},
          ResponsableInformatique: {},
          ResponsableTechnique: {},
          ContactComptabilite: {},
        },
      };
    },
    async created() {
      await this.fetchSocieteDetails();
      this.listSelect.forEach(async (element) => {
        this.listModel[element] = await this.getInfoDetails(element);
      });
    },
    methods: {
      recupNewList(item) {
        console.log(item);
        this.listModel = { ...item };
        console.log(this.listModel);
      },
      async getInfoDetails(item) {
        // let res;
        // await axios({
        //   url: process.env.VUE_APP_URL_API_CLIENT + `get${item}ForSociete/`,
        //   method: "POST",
        //   data: {
        //     id: this.id,
        //     token: this.$cookies.get("token"),
        //   },
        // }).then((response) => {
        //   res = response.data;
        // });
        // return res;

        // const res = {
        //         "id": 1,
        //         "date": "",
        //         "nom": "wael",
        //         "prenom": "wael2",
        //         "adresseEmail": "waelbben@gmail.com",
        //         "numTele": "02222222",
        //         "adressePostal": "tunis",
        //         "codePostal": "22154",
        //         "commune": "tunis",
        //         "voie": "tunis",
        //         "civile": "MR."
        //     }
        // return res
      },
      // get client particulier from the route (voiture/getSocieteById) detail with post (id ,token) using axios
      async fetchSocieteDetails() {
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "getProfileParticulierById/",
          method: "POST",
          data: {
            id: this.id,
            token: this.$cookies.get("token"),
          },
        })
          .then((response) => {
            console.log(response)
            this.clientPDetail = response.data;
            this.imageUrl =
              process.env.VUE_APP_URL_API_CLIENT_BASE_URL + response.data.logo;
          })
          .catch((error) => {
            
          });
      },
      // change card content
      changeCardContent(card) {
        this.infoCardSelected = card === "info" ? true : false;
        this.cotationCardSelected = card === "cotation" ? true : false;
        this.visiteCardSelected = card === "visite" ? true : false;
        this.installationCardSelected = card === "installation" ? true : false;
        this.listDevisCardSelected = card === "list-devis" ? true : false;
        if (this.infoCardSelected) {
          this.listSelect.forEach(async (element) => {
            this.listModel[element] = await this.getInfoDetails(element);
          });
        }
        for (const element of this.listFilePage) {
          if (card == element.label) {
            element.CardSelected = true;
            this.filePage = { ...element };
            break; // Sortir de la boucle une fois que la valeur est trouvée
          } else {
            this.filePage = {};
            element.CardSelected = false;
          }
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .btn-edit-img {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 2px 8px;
    background-color: #f0f8ff;
    border-radius: 4px;
    box-shadow: 0px 4px 16px 8px rgba(114, 114, 114, 0.3);
    cursor: pointer;
  }
  .breadcrumb {
    background-color: #ffffff !important;
  }
  .containe_comp {
    background-color: #f2f2f2 !important;
    border-radius: 10px;
    padding: 50px 70px;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol;
    font-variant: tabular-nums;
    line-height: 1.5;
    font-feature-settings: "tnum";
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
  }
  .body-header {
    color: #192946;
  }
  .icon {
    width: 58px;
    height: 58px;
    top: 0;
    bottom: 0;
    right: 16px;
    margin: auto;
    background: linear-gradient(45deg, rgb(0, 121, 145) 0%, rgb(24, 174, 162) 68%)
      rgb(0, 121, 145);
    border-radius: 7px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.12);
    justify-content: center;
    align-items: center;
    padding: 1px 5px;
  }
  .text-button {
    color: #192946;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.02em;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 10px;
  }
  .text-button:hover {
    color: #1e3766;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.02em;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 10px;
    text-decoration: underline;
  }
  .bgc-primary {
    background-color: #396c99;
  }
  .bgc-primary:hover {
    background-color: #1e4c66;
  }
  .bgc-success {
    background-color: #2fb8ac;
  }
  .bgc-warning {
    background-color: #868685;
  }
  .card-title {
    padding: 0.45rem 0.825rem;
    margin-bottom: 10;
    background-color: #ffffff;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.02em;
    font-size: 1.25rem;
    color: #1d3258;
  }
  .card-header {
    background-color: #ffffff !important;
    border-bottom: 1px solid #e6e6f2 !important;
  }
  #table-validation-cotation th {
    background-color: #103a5e;
    color: white;
    font-weight: bold;
  }
  .v-application ul {
    padding-left: 10px;
  }
  .info-title {
    font-size: 15px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.02em;
    margin-bottom: 0;
  }
  
  @media only screen and (max-width: 400px) {
    .container-div {
      width: 125px !important;
      padding: 0px;
      margin: 0;
    }
  }
  .small-status {
    width: max-content;
  }
  </style>
  