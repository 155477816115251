<template>
  <v-card color="white" class="rounded-lg" flat>
    <v-dialog
      v-model="modalUpdateEventMonthly"
      persistent
      max-width="50%"
      style="z-index: 1028"
    >
      <v-card>
        <v-toolbar style="background-color: rgb(21, 191, 166)">
          <span class="text-h6 pl-0" style="color: whitesmoke !important"
            >Informations Visite</span
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="navigateToProfilVisite(editedEvent.id)"
            style="border: 1px solid rgb(211, 211, 211)"
          >
            <v-icon class="mr-2">mdi-hammer-screwdriver</v-icon>
            <span v-if="parent == 'visite-installation'">Visite Installation </span>
            <span v-if="parent == 'visite-technique'">Visite Technique</span>
          </v-btn>
        </v-toolbar>
        <div class="modal-body">
          <div class="row g-0">
            <div class="col-md-3 border-right mt-5">
              <div class="pt-1 text-center">
                <div class="profile">
                  <img
                    :src="require(`@/assets/images/electricien.png`)"
                    width="100"
                    class="rounded-circle img-thumbnail"
                  />
                  <span class="d-block mt-3 font-weight-bold">{{
                    editedEvent.owner
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-9">
              <div class="status p-3">
                <table class="table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <div class="d-flex flex-column">
                          <span class="heading d-block"
                            ><i class="fa-solid fa-star"></i> Titre Visite
                          </span>
                          <span class="subheadings mt-2">{{ editedEvent.title }}</span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex flex-column">
                          <span class="heading d-block">
                            <i class="fa-solid fa-calendar-days"></i> Date Debut
                          </span>
                          <span class="subheadings mt-2"
                            >{{ editedEvent.dateDebut }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex flex-column">
                          <span class="heading d-block"
                            ><i class="fa-solid fa-calendar-days"></i> Date Fin</span
                          >
                          <span class="subheadings mt-2">{{ editedEvent.dateFin }}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-column">
                          <span class="heading d-block">
                            <i class="fa-solid fa-clock"></i> Time Debut
                          </span>
                          <span class="subheadings mt-2"
                            >{{ editedEvent.timeStart }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex flex-column">
                          <span class="heading d-block"
                            ><i class="fa-solid fa-clock"></i> Time Fin</span
                          >
                          <span class="subheadings mt-2">{{ editedEvent.timeEnd }}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">
                        <div class="d-flex flex-column">
                          <span class="heading d-block"
                            ><i class="fa-solid fa-prescription-bottle"></i>
                            Description</span
                          >
                          <span class="d-flex flex-row subheadings">
                            {{ editedEvent.description }}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12" style="text-align: right">
              <v-btn color="error" @click="modalUpdateEventMonthly = false"
                >Annuler</v-btn
              >
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-sheet height="700" class="py-4 px-5">
      <FullCalendar ref="calendar" :options="calendarOptions" :events="globalEvents">
        <template v-slot:eventContent="arg">
          <i>
            <div class="fc-event-main-frame ">
              <div class="fc-event-title-container "
                :ref="'eventTitleContainer' + arg.event.id"
                @mouseover="showTooltip(`${arg.event.title} : ${arg.event.extendedProps['timeStart']} -> ${arg.event.extendedProps['timeEnd']}  `,arg.event.id)"
                @mouseleave="hideTooltip">
                  <span style="font-size:11px !important; margin-left:3px"><i class="fa-regular fa-clock" style="margin-right: 0px ;"></i> {{ arg.event.extendedProps['timeStart'] }} -</span>
                  <span style="font-size:11px !important;margin-right:7px !important;margin-left:3px !important"><i class="fa-regular fa-clock " style="margin-right: 3px ;"></i>{{ arg.event.extendedProps['timeEnd'] }}</span>
                  <span  style="font-size:12px;font-weight:bold !important;margin-right:3px">{{ arg.event.title }}</span>
                
                </div>
            </div>
        </i>
        </template>
      </FullCalendar>
    </v-sheet>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "@/utils/eventUtils.js";
import eventDragModal from "./modal/eventDragModal.vue";
import dayjs from "@/utils/dayjsConfig.js";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import { hideAll } from "tippy.js";

export default defineComponent({
  components: {
    FullCalendar,
    eventDragModal,
  },
  props: {
    globalEvents: {
      type: Array,
      default: () => [],
    },
    parent: {
      type: String,
    },
    techList: {},
    currentDate: null,
  },
  data() {
    return {
      type: "month",
      calendarApi: null,
      types: ["month", "week", "day", "4day"],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      dragEvent: null,
      dragStart: null,
      lastEvent: "",
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      isMounted: false,
      modalCreate: false,
      modalUpdateEventMonthly: false,
      startUpdateMonthly: false,
      ExtendEvent: null,
      isDraged: false,
      editedEvent: {
        id: "",
        title: "",
        dateDebut: "",
        dateFin: "",
        timeStart: "",
        timeEnd: "",
        index: "",
        start: "",
        end: "",
        description: "",
        owner: "",
        color: "",
      },
      dragedEvent: {
        id: "",
        title: "",
      },
      newEvent: {
        nameEvent: "",
        dateDebut: "",
        dateFin: "",
        start: "",
        end: "",
        description: "",
        owner: "",
        timeStart: "",
        timeEnd: "",
      },
      event_list: [
        { title: "event 1", date: "2023-05-01" },
        { title: "event 2", date: "2023-05-02" },
      ],
      //calendar options
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,today,next",
          center: "",
          right: "title",
        },
        customButtons: {
          prev: {
            text: "Previous",
            click: this.handlePrevButtonClick,
          },
          next: {
            text: "Next",
            click: this.handleNextButtonClick,
          },
        },
        initialView: "dayGridMonth",
        //initialEvents: this.globalEvents, // alternatively, use the `events` setting to fetch from a feed
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        initialDate: dayjs(this.currentDate).format("YYYY-MM-DD"),
        //select: this.OpenCreateNewEvent,
        eventClick: this.clickedEventInfos,
        eventsSet: this.handleEvents,
        events: this.globalEvents,
        height: 600,
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      currentEvents: [],
    };
  },
  async created() {},
  computed: {
    dateDebutLower() {
      if (new Date(this.editedEvent.dateDebut) > new Date(this.editedEvent.dateFin)) {
        return "La date de début doit être inférieure ou égale à la date de fin";
      }
      return true;
    },
    dateFinHigher() {
      if (new Date(this.editedEvent.dateDebut) > new Date(this.editedEvent.dateFin)) {
        return "La date de début doit être inférieure ou égale à la date de fin";
      }
      return true;
    },
    timeStartLower() {
      if (this.editedEvent.dateDebut == this.editedEvent.dateFin) {
        if (this.editedEvent.timeStart >= this.editedEvent.timeEnd) {
          return "L'heure de début doit être inférieure";
        }
        return true;
      }
    },
    timeEndHigher() {
      if (this.editedEvent.dateDebut == this.editedEvent.dateFin) {
        if (this.editedEvent.timeEnd <= this.editedEvent.timeStart) {
          return "L'heure de fin doit être supérieure";
        }
        return true;
      }
    },
  },
  mounted() {
    //init calendar;
    this.calendarOptions["events"] = this.globalEvents;
    this.calendarApi = this.$refs.calendar.getApi();
  },

  methods: {
    formatTime(time) {
      return dayjs(time).format("HH:mm");
    },

    handlePrevButtonClick() {
      this.$refs.calendar.getApi().prev();
      this.$emit("rechargeEvents", {
        dateDebut: this.$refs.calendar.getApi().view.currentStart,
        dateFin: this.$refs.calendar.getApi().view.currentEnd,
      });
    },
    handleNextButtonClick() {
      this.$refs.calendar.getApi().next();
      this.$emit("rechargeEvents", {
        dateDebut: this.$refs.calendar.getApi().view.currentStart,
        dateFin: this.$refs.calendar.getApi().view.currentEnd,
      });
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleDateSelect(selectInfo) {
      title = "prompt('Please enter a new title for your event')";
      let calendarApi = selectInfo.view.calendar;
      calendarApi.unselect(); // clear date selection

      if (title) {
        calendarApi.addEvent({
          id: createEventId(),
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay,
        });
      }
    },
    showTooltip(title, id) {
      const key = `eventTitleContainer${id}`;
      tippy(this.$refs[key], {
        content: title,
        theme: "light-border",
        placement: "top",
        offset: [0, 10],
        allowHTML: true,
      });
    },
    // hide tooltip
    hideTooltip() {
      hideAll();
    },
    createIdForEvent() {
      var maxId = this.globalEvents.reduce((max, event) => {
        return event.id > max ? event.id : max;
      }, 0);
      return maxId + 1;
    },
    OpenCreateNewEvent(info) {
      this.newEvent.title = "";
      this.newEvent.dateDebut = dayjs(info.start).format("YYYY-MM-DD");
      this.newEvent.dateFin = dayjs(info.end).format("YYYY-MM-DD");
      this.newEvent.start = dayjs(info.start).format("YYYY-MM-DD HH:mm");
      this.newEvent.end = dayjs(info.end).format("YYYY-MM-DD HH:mm");
      this.newEvent.timeStart = dayjs(info.start).format("HH:mm");
      this.newEvent.timeEnd = dayjs(info.end).format("HH:mm");
      this.modalCreate = true;
    },
    async CreateNewEvent() {
      var dateDebut = this.newEvent.dateDebut + " " + this.newEvent.timeStart;
      var dateFin = this.newEvent.dateFin + " " + this.newEvent.timeEnd;
      var event = {
        id: this.createIdForEvent(),
        title: this.newEvent.nameEvent,
        dateDebut: dayjs(new Date(dateDebut)).format("YYYY-MM-DD"),
        dateFin: dayjs(new Date(dateFin)).format("YYYY-MM-DD"),
        timeStart: this.newEvent.timeStart,
        timeEnd: this.newEvent.timeEnd,
        start: dayjs(new Date(dateDebut)).format("YYYY-MM-DD HH:mm"),
        end: dayjs(new Date(dateFin)).format("YYYY-MM-DD HH:mm"),
        description: this.newEvent.description,
        owner: this.newEvent.owner,
      };
      
      this.globalEvents.push(event);
      //refetchEvents after modifying the events global array
      //this.calendarApi.removeAllEvents();
      //this.calendarApi.addEventSource([event]);

      await this.updateListCalendar();
      this.modalCreate = false;
    },
    // click event on v-dialog
    clickedEventInfos(event) {
      this.editedEvent.id = event.event.id;
      this.editedEvent.title = event.event.title;
      this.editedEvent.dateDebut = event.event.extendedProps.dateDebut;
      this.editedEvent.dateFin = event.event.extendedProps.dateFin;
      this.editedEvent.timeStart = event.event.extendedProps.timeStart;
      this.editedEvent.timeEnd = event.event.extendedProps.timeEnd;
      this.editedEvent.start = dayjs(
        new Date(this.editedEvent.dateDebut + " " + this.editedEvent.timeStart)
      ).format("YYYY-MM-DD HH:mm");
      this.editedEvent.end = dayjs(
        new Date(this.editedEvent.dateFin + " " + this.editedEvent.timeEnd)
      ).format("YYYY-MM-DD HH:mm");
      this.editedEvent.owner = event.event.extendedProps.owner;
      this.editedEvent.description = event.event.extendedProps.description;
      this.modalUpdateEventMonthly = true;
      
    },
    openUpdateEventMonthly() {
      this.modalUpdateEventMonthly = true;
    },
    updateEventMonthly() {
      this.editedEvent.id = this.editedEvent.id;
      this.editedEvent.title = this.editedEvent.title;
      this.editedEvent.dateDebut = this.editedEvent.dateDebut;
      this.editedEvent.dateFin = this.editedEvent.dateFin;
      this.editedEvent.timeStart = this.editedEvent.timeStart;
      this.editedEvent.timeEnd = this.editedEvent.timeEnd;
      this.editedEvent.start = dayjs(
        new Date(this.editedEvent.dateDebut + " " + this.editedEvent.timeStart)
      ).format("YYYY-MM-DD HH:mm");
      this.editedEvent.end = dayjs(
        new Date(this.editedEvent.dateFin + " " + this.editedEvent.timeEnd)
      ).format("YYYY-MM-DD HH:mm");
      this.editedEvent.owner = this.editedEvent.owner;
      this.editedEvent.description = this.editedEvent.description;
      var event = {
        id: this.editedEvent.id,
        title: this.editedEvent.title,
        dateDebut: this.editedEvent.dateDebut,
        dateFin: this.editedEvent.dateFin,
        timeStart: this.editedEvent.timeStart,
        timeEnd: this.editedEvent.timeEnd,
        start: this.editedEvent.start,
        end: this.editedEvent.end,
        owner: this.editedEvent.owner,
        description: this.editedEvent.description,
      };
      this.$emit("miseAjourEffectuer", event);
      this.modalUpdateEventMonthly = false;
      //this.editEvent(event)
      //this.calendarOptions.events = this.globalEvents
      //this.calendarApi.refetchEvents()
      //this.calendarApi.addEventSource(this.globalEvents);
    },
    eventDraged(dEvent) {
      if (dEvent.event.allDay == true) {
        this.dragedEvent.start = dayjs(new Date(dEvent.event.start)).format("YYYY-MM-DD");
        this.dragedEvent.end = dayjs(new Date(dEvent.event.start)).format("YYYY-MM-DD");
      } else {
        this.dragedEvent.start = dayjs(new Date(dEvent.event.start)).format("YYYY-MM-DD");
        this.dragedEvent.end = dayjs(new Date(dEvent.event.end)).format("YYYY-MM-DD");
      }
      this.isDraged = true;
      this.dragedEvent.id = dEvent.event.id;
      this.dragedEvent.title = dEvent.event.title;
      this.dragedEvent.dateDebut = dEvent.event.extendedProps.dateDebut;
      this.dragedEvent.dateFin = dEvent.event.extendedProps.dateFin;
      this.dragedEvent.timeStart = dEvent.event.extendedProps.timeStart;
      this.dragedEvent.timeEnd = dEvent.event.extendedProps.timeEnd;
      this.dragedEvent.owner = dEvent.event.extendedProps.owner;
      this.dragedEvent.description = dEvent.event.extendedProps.description;
    },
    dragWellEditedEvent(editedEvent) {
      var event = {
        id: editedEvent.id,
        title: editedEvent.title,
        dateDebut: dayjs(new Date(editedEvent.start)).format("YYYY-MM-DD"),
        dateFin: dayjs(new Date(editedEvent.end)).format("YYYY-MM-DD"),
        timeStart: editedEvent.timeStart,
        timeEnd: editedEvent.timeEnd,
        start: editedEvent.start,
        end: editedEvent.end,
        owner: editedEvent.owner,
        description: editedEvent.description,
      };
      this.$emit("miseAjourEffectuer", event);
      //this.editEvent(event)
      //this.calendarOptions.events = this.globalEvents
      this.isDraged = false;
    },
    async closeEtAnnuler(editedEvent) {
      var event = {
        id: editedEvent.id,
        title: editedEvent.title,
        dateDebut: editedEvent.dateDebut,
        dateFin: editedEvent.dateFin,
        timeStart: editedEvent.timeStart,
        timeEnd: editedEvent.timeEnd,
        start: dayjs(
          new Date(editedEvent.dateDebut + " " + editedEvent.timeStart)
        ).format("YYYY-MM-DD HH:mm"),
        end: dayjs(new Date(editedEvent.dateFin + " " + editedEvent.timeEnd)).format(
          "YYYY-MM-DD HH:mm"
        ),
        owner: editedEvent.owner,
        description: editedEvent.description,
      };
      this.$emit("annulerLaModification");
      /**await this.removeItem(event)
      await this.ajouterItemApresDelete(event)
      await this.updateListCalendar()*/
      this.isDraged = false;
    },
    async removeItem(newEvent) {
      var index = this.globalEvents.findIndex((item) => item.id == newEvent.id);
      if (index != -1) {
        this.globalEvents.splice(index, 1);
      }
    },
    async ajouterItemApresDelete(newEvent) {
      var v = {
        id: newEvent.id,
        title: newEvent.title,
        dateDebut: newEvent.dateDebut,
        dateFin: newEvent.dateFin,
        timeStart: newEvent.timeStart,
        timeEnd: newEvent.timeEnd,
        start: dayjs(new Date(newEvent.dateDebut + " " + newEvent.timeStart)).format(
          "YYYY-MM-DD HH:mm"
        ),
        end: dayjs(new Date(newEvent.dateFin + " " + newEvent.timeEnd)).format(
          "YYYY-MM-DD HH:mm"
        ),
        owner: newEvent.owner,
        description: newEvent.description,
      };
      this.globalEvents.push(v);
    },
    async updateListCalendar() {
      this.calendarOptions.events = this.globalEvents;
      //this.$refs.calendar.setOptions(this.calendarOptions)
      this.calendarApi.refetchEvents();
    },
    async editEvent(newEvent) {
      var index = this.globalEvents.findIndex((item) => item.id == newEvent.id);
      if (index != -1) {
        this.globalEvents[index].title = newEvent.title;
        this.globalEvents[index].dateDebut = newEvent.dateDebut;
        this.globalEvents[index].dateFin = newEvent.dateFin;
        this.globalEvents[index].timeStart = newEvent.timeStart;
        this.globalEvents[index].timeEnd = newEvent.timeEnd;
        this.globalEvents[index].start = dayjs(
          new Date(newEvent.dateDebut + " " + newEvent.timeStart)
        ).format("YYYY-MM-DD HH:mm");
        this.globalEvents[index].end = dayjs(
          new Date(newEvent.dateFin + " " + newEvent.timeEnd)
        ).format("YYYY-MM-DD HH:mm");
        this.globalEvents[index].owner = newEvent.owner;
        this.globalEvents[index].description = newEvent.description;
      }
    },
    navigateToProfilVisite(id) {
      if (this.parent == "visite-technique")
        this.$router.push({ path: `/visite-technique/profil/${id}` });
      else if (this.parent == "visite-installation") {
        this.$router.push({ path: `/visite-installation/profil/${id}` });
      }
    },
  },
});
</script>
<style lang="css">
.fc-toolbar-chunk h2.fc-toolbar-title {
  font-size: 32px;
  color: #020f47;
  text-align: right;
  width: 100% !important;
}
.fc-popover-body .fc-daygrid-event-harness {
  margin: 2px 4px !important;
  padding: 4px !important;
}
.fc-popover-body .fc-daygrid-event-harness a {
  padding: 2px 4px !important;
  border-radius: 6px !important;
  box-shadow: 0px 4px 16px -4px rgba(194, 194, 194, 1);
}
.close:focus {
  outline: 1px dotted #fff !important;
}

.fa-close {
  color: #fff;
}

.heading {
  font-weight: 500 !important;
}

.subheadings {
  font-size: 14px;
  color: #a0a0a0;
}

.dots {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.gallery img {
  margin-right: 10px;
}

.fs-9 {
  font-size: 9px;
}
</style>

<style scoped>
.modal-body {
}

.modal-title {
  color: #fff;
}

.modal-header {
  background: #aec7b6;
  color: #fff !important;
}
</style>
