<template>
    <div class="mr-2 ml-3 p-3">
        <div class="shadow-sm ">
            <breadcrumb :listNavs="listNavs"/>
        </div>
        <v-card class="mt-3 pa-2 shadow-sm" elevation="11">
            <v-dialog v-model="ouvrireDialogeOffre" max-width="700px">
                <v-card style="overflow: hidden;">
                    <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir supprimer cette vente ?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="fermerDialogeSupprimerOffre"
                        >Annuler</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteOffre"
                        >Confirmer</v-btn
                    >
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-data-iterator
                :items="allDonner"
                :items-per-page.sync="itemsPerPage"
                :page.sync="page"
                :search="search"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                hide-default-footer
                class="p-4"
              >
                <template v-slot:header>
                    <div class="form-group has-search">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" class="form-control" placeholder="Chercher un contrat">
                    </div>
                </template>
                <template v-slot:no-data>
                    <div class="d-flex flex-column align-items-center justify-content-center w-100" style="padding: 80px;">
                        <img src="@/assets/images/nofileicon.png" alt="nofileicon" style="width: 100px; height: 100px; margin-bottom: 40px;" />
                        <p style="font-size: 28px;font-weight:400;color: #ccc;">Aucune contract trouvez!!</p>
                    </div>
                </template>
                <!-- <template v-slot:default="props">
                    <v-row>
                    <v-col
                        v-for="item in props.items"
                        :key="item.id"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                    >
                        <v-row>
                            <v-col cols='12' sm='2' md='1' lg='1' xl='1'
                                v-for="(key, index) in filteredKeys"
                                :key="index"
                                v-if='key=="Date"'
                                :class="{ 'blue--text': sortBy === key }">
                                <span class="text-body-1 grey--text  mb-2"> {{ item['date'] }}</span>
                            </v-col>
                            <v-col cols='12' sm='2' md='2' lg='2' xl='2'
                              v-else-if='key=="Infomation"'
                              :class="{ 'blue--text': sortBy === key }">
                                <p><span class="text-body-1 grey--text  mb-2">Nom: </span><span class="text-body-1 black--text  mb-2">{{ item['nom'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">Prenom: </span><span class="text-body-1 black--text  mb-2">{{ item['prenom'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">Nom de Jeune Fille: </span><span class="text-body-1 black--text  mb-2">{{ item['nomJeuneFille'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">N° Enfant </span><span class="text-body-1 black--text  mb-2">{{ item['nbrEnfant'] }} </span></p>
                            </v-col>
                            <v-col cols='12' sm='3' md='2' lg='2' xl='2'
                              v-else-if='key=="Contact"'
                              :class="{ 'blue--text': sortBy === key }">
                                <p><span class="text-body-1 grey--text  mb-2">E-mail: </span><span class="text-body-1 black--text  mb-2">{{ item['email'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">N° télé: </span><span class="text-body-1 black--text  mb-2">{{ item['num_Tele'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">Adresse Postale: </span><span class="text-body-1 black--text  mb-2">{{ item['adresse'] }} </span></p>
                            </v-col>
                            <v-col cols='12' sm='2' md='2' lg='2' xl='2'
                              v-else-if='key=="Naissance"'
                              :class="{ 'blue--text': sortBy === key }">
                                <p><span class="text-body-1 grey--text  mb-2">Date Nais: </span><span class="text-body-1 black--text  mb-2">{{ item['dateNais'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">ville Nais: </span><span class="text-body-1 black--text  mb-2">{{ item['villeNais'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">dep Nais: </span><span class="text-body-1 black--text  mb-2">{{ item['departementNais'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">Pays Nais: </span><span class="text-body-1 black--text  mb-2">{{ item['paysNais'] }} </span></p>
                            </v-col>
                            <v-col cols='12' sm='2' md='2' lg='2' xl='2'
                              v-else-if='key=="Situation"'
                              :class="{ 'blue--text': sortBy === key }">
                                <p><span class="text-body-1 grey--text  mb-2">Nationalité: </span><span class="text-body-1 black--text  mb-2">{{ item['nationalite'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">Situation: </span><span class="text-body-1 black--text  mb-2">{{ item['situation'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">numéro SS: </span><span class="text-body-1 black--text  mb-2">{{ item['numSS'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">Clé: </span><span class="text-body-1 black--text  mb-2">{{ item['cle'] }} </span></p>
                            </v-col>
                            <v-col cols='12' sm='2' md='1' lg='1' xl='1'
                              v-else-if='key=="Attachments"'
                              :class="{ 'blue--text': sortBy === key }">
                                <div v-if="item['cinPath'] != ''">
                                    <a style="text-decoration:none" :href="item['Cin']" target="_blank">
                                        <span class="text-body-2 blue--text mb-2">CIN</span>
                                    </a>
                                </div>
                            
                                <div v-if="item['ribPath'] != ''">
                                    <a style="text-decoration:none" :href="item['Rib']" target="_blank">
                                        <span class="text-body-2 blue--text mb-2">RIB</span>
                                    </a>
                                </div>
                            </v-col>
                            <v-col cols='12' sm='1' md='1' lg='1' xl='1'
                              v-else-if='key=="Action"'
                              :class="{ 'blue--text': sortBy === key }">
                                <v-btn @click="ouvrireDialogeSupprimerOffre(item['id'])" color="error">
                                    Supprimer
                                </v-btn>
                            </v-col>
                        </v-row>
                    <hr>
                    </v-col>
                    </v-row>
                </template> -->
                <template v-slot:default="props">
                    <v-row class="my-3">
                    <v-col
                        v-for="item in props.items"
                        :key="item.id"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                         >
                         <v-row>
                         <v-col cols='12'
                                v-for="(key, index) in filteredKeys"
                                :key="index"
                                v-if='key=="Date"'
                                :class="{ 'blue--text': sortBy === key }">
                                <div class="card shadow-sm border rounded-5">
                                    <div class="card-body pt-0 px-4 pb-1">                       
                                        <div class="row">
                                        <div class="col-1 d-flex justify-content-center align-items-center ">
                                            <!-- <div class="bg-image hover-zoom ripple rounded ripple-surface">
                                            <img src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/E-commerce/Products/img%20(4).webp"
                                               style="width: 100%; height: 100px;" />
                                            <a href="#!">
                                                <div class="hover-overlay">
                                                <div class="mask" style="background-color: rgba(253, 253, 253, 0.15);"></div>
                                                </div>
                                            </a>
                                            </div> -->

                                           <span class="text-body-1  mb-2 item-date">{{ item['date'] }} </span>

                                        </div>
                                        <div class="col-9" >
                                            <table class="table table-borderless mt-0 mb-0">
                                                <thead class="border-bottom">
                                                <tr class="pt-0">
                                                    <th scope="col" class="header-text">Infos</th>
                                                    <th scope="col" class="header-text">Contact</th>
                                                    <th scope="col" class="header-text">Naissance</th>
                                                    <th scope="col" class="header-text">Situation</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div class="mb-2">
                                                                <span class="item-title">Nom: </span> 
                                                                <span class="item-value"> {{ item['nom'] }} </span> 
                                                            </div>
                                                            <div class="mb-2">
                                                            <span class="item-title">Prenom: </span> 
                                                            <span class="item-value"> {{ item['prenom'] }} </span> 
                                                            </div>
                                                            <div class="mb-2">
                                                                <span class="item-title">Nom Jeune Fille: </span> <span class="item-value"> {{ item['nomJeuneFille'] }} </span> 
                                                            </div>
                                                            <div class="mb-2">
                                                                <span class="item-title">N Enfant: </span> <span class="item-value"> {{ item['nbrEnfant'] }} </span>             
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div class="mb-2">
                                                                <span class="item-title">Email: </span> 
                                                                <span class="item-value"> {{ item['email'] }} </span> 
                                                            </div>
                                                            <div class="mb-2">
                                                                <span class="item-title">Telephone: </span> <span class="item-value"> {{ item['num_Tele'] }} </span> 
                                                            </div>
                                                            <div class="mb-2">
                                                                <span class="item-title">Addresse: </span> <span class="item-value"> {{ item['adresse'] }} </span> 
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="mb-2">
                                                                <span class="item-title text-muted">date: </span> 
                                                                <span class="item-value"> {{ item['dateNais'] }} </span> 
                                                            </div>
                                                            <div class="mb-2">
                                                                <span class="item-title">ville: </span> 
                                                                <span class="item-value"> {{ item['villeNais'] }} </span> 
                                                            </div>
                                                            <div class="mb-2">
                                                                <span class="item-title">departement: </span> 
                                                                <span class="item-value" > {{ item['departementNais'] }} </span> 
                                                            </div>
                                                            <div class="mb-2">

                                                                <span class="item-title">Pays: </span> 
                                                                <span class="item-value"> {{ item['paysNais'] }} </span>   
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="mb-2">
                                                                <span class="item-title">nationalite: </span> 
                                                                <span class="item-value"> {{ item['nationalite'] }} </span> 
                                                            </div>
                                                            <div class="mb-2">
                                                                <span class="item-title">situation: </span> 
                                                                <span class="item-value"> {{ item['situation'] }} </span> 
                                                             </div>
                                                             <div class="mb-2">
                                                                <span class="item-title">numéro SS: </span> 
                                                                <span class="item-value"> {{ item['numSS'] }} </span> 
                                                             </div>
                                                             <div class="mb-2">
                                                                <span class="item-title">Clé: </span> 
                                                                <span class="item-value"> {{ item['cle'] }} </span> 
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>                                                                          
                                        </div>
                                        <div class="col-2 border-sm-start-none border-start">
                                        
                                            <div class="d-flex flex-column mt-4">
                                                <a :href="item['Cin']" class="btn bg_cin btn-sm mb-2 " target="_blank" type="button">
                                                    CIN
                                                </a>
                                                <a :href="item['Rib']" class="btn bg_silver btn-sm mb-2" target="_blank"  type="button">
                                                    RIB
                                                </a>
                                                <button @click="ouvrireDialogeSupprimerOffre(item['id'])" class="btn btn-danger btn-sm" type="button"> Supprimer</button>
                                                <button class="btn bg_sortie btn-sm text-white mt-2" type="button"> Sortir le contrat</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                         </v-col>
                    </v-row>
                </v-col>
                <hr>
            </v-row>
         </template>
                
                <template v-slot:footer>
                        <v-row
                            class="ma-0 align-center justify-center"
                        >
                            <span class="ml-5">Éléments par page</span>
                            <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                
                                text
                                :color="$colors[0]"
                                class="ml-2"
                                v-bind="attrs"
                                v-on="on"
                                >
                                {{ itemsPerPage }}
                                <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                v-for="(number, index) in itemsPerPageArray"
                                :key="index"
                                @click="updateItemsPerPage(number)"
                                >
                                <v-list-item-title>{{ number }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                            </v-menu>
                
                            <v-spacer></v-spacer>
                
                            <span
                            class="mr-4"
                            >
                            Page {{ page }} of {{ numberOfPages }}
                            </span>
                            <v-btn
                            fab
                            dark
                            color="bg_green darken-3"
                            class="mr-1 my-1 "
                            style="padding: 3px; min-width: 0px; height: 40px !important; width: 40px !important;"

                            >
                            <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn
                            fab
                            dark
                            color="bg_green darken-3"
                            class="ml-1 mr-1 my-1 "
                            style="padding: 3px; min-width: 0px; height: 40px !important; width: 40px !important;"
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-row>
                </template>
            </v-data-iterator>
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'
    import breadcrumb from "@/components/breadcrumb.vue";

    export default {
        name: 'ListeContratEmbauche',
        components: {
            breadcrumb
        },
        data: () => ({
            status : "",
            search: '',
            idOffreDeleted: '',
            listNavs : [
                {
                    name: "contrat d'embauche", 
                    link: "#",
                },
                {
                    name: "List contrat d'embauche",
                    link: "#",
                },
            ],
            ouvrireDialogeOffre: false,
            headers: [
                {text: 'Date', align: 'start', sortable: true, value: 'date'},
                { text: 'Infomation', value: 'nom', sortable: true },
                { text: 'Contact', value: 'email', sortable: true },
                { text: 'Securite sociale', value: 'numSS', sortable: false },
                { text: 'Naissance', value: 'dateNais', sortable: false },
                { text: 'Situation', value: 'nationalite', sortable: true },
                { text: 'Attachments', value:'attachments', sortable: false},
                { text: 'Action', value:"Action", sortable: false},
            ],
            allDonner: [],    
            itemsPerPageArray: [10, 15, 20, 30],
            search: '',
            filter: {},
            sortDesc: true,
            page: 1,
            itemsPerPage: 10,
            sortBy: '',
            keys: [
                'Date',
                'Infomation',
                'Contact',
                'Securite sociale',
                'Naissance',
                'Situation',
                'Attachments',
                'Action',
            ],
        }),
        
        computed: {
            numberOfPages () {
            return Math.ceil(this.allDonner.length / this.itemsPerPage)
            },
            filteredKeys () {
            return this.keys
            },
            filteredKeysSorted () {
            return this.keys.filter(key => key !== 'Action')
            },
        },
        created() {
            //this.verifyApplicationDataSecurity()
            this.getAllDonnerEmployeur()
        },
        methods: {
            
            ouvrireDialogeSupprimerOffre(id){
                this.idOffreDeleted = id
                this.$swal({
                    title: 'Êtes-vous sûr?',
                    text: "Vous ne pourrez pas revenir en arrière!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Annuler',
                    confirmButtonText: 'Oui, supprimez-le'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteOffre()
                    }
                })
            },
            fermerDialogeSupprimerOffre(){
                this.idOffreDeleted = ''
                this.ouvrireDialogeOffre = false
            },
            splitedList(str){
                
                if(str !== '' && str != null){
                    return str.split(',')
                }else{
                    return null
                }
            },
            deleteOffre(id){
                var formData = new FormData()
                formData.append('id', this.idOffreDeleted)
                formData.append('token', this.$cookies.get("token"))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'deleteNewEmployeur/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    if(res.data.result == true){
                        this.getAllDonnerEmployeur()
                    }else if(res.data.result == false){
                        this.getAllDonnerEmployeur()
                    }else{
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                
                this.ouvrireDialogeOffre = false
            },
            verifyApplicationDataSecurity() {
               if (this.$cookies.get('token')) {
                    var formData = new FormData()
                    formData.append('type', localStorage.getItem('typeUser'))
                    formData.append('vueAfficher', 'admin')
                    formData.append('token', this.$cookies.get('token'))
                    axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT +
                        'verifyApplicationDataSecurity.php',
                        method: 'POST',
                        data: formData,
                        
                    })
                    .then((res) => {
                    var data = JSON.parse(JSON.stringify(res.data))
                    if (data[0].result === 'notLogin') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        this.$cookies.remove('token')
                        this.$router.push('/login')
                    } else if (data[0].result === 'accessDenied') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        this.$cookies.remove('token')
                        this.$router.push('/login')
                    }
                    })
                    .catch((err) => {
                    
                    })
                }else{
                    this.$router.push('/login')
                }
            },
            getAllDonnerEmployeur() {
                let self = this
                var formData = new FormData()
                formData.append('token', this.$cookies.get("token"))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllNewEmployer/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    if(res.data.result == true){
                        self.allDonner = res.data.data
                        console.log(self.allDonner)
                    }else if(res.data.result == false){
                        alert('une erreur est survenue')
                    }else{
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }

                })
            },
        
        }
    }
</script>
<style scoped>
.sub-title{
    font-size: 15px;
    font-weight: 600;
    color: #000000;
}
.text-desc{
    font-size: 14px;
    font-weight: 400;
    color: #383838;
}
.header-text{
    font-size: 16px;
    font-weight: 700;
    color: #13c0a3;
}
.custom-search-bar {
    height: 32px; /* Adjust the height as per your requirement */
    padding: 2px 8px; /* Adjust the padding as per your requirement */
    border:  solid 1px black !important;
  }
.search-input{
    width: 450px !important;
    height: 49px !important;
    border-radius: 3px !important;
}

.bg_green{
    background-color: #13c0a3 !important;
    color: #fff !important;
}
.bg_silver{
    background-color: #c7cece !important;
    color: #060825 !important;
}
.bg_cin{
    background-color: #0d6fa8 !important;
    color: #fff !important;
}
.bg_sortie{
    background-color: #ffb235 !important;
    color: #fff !important;
}
.bg_search{
    background-color: #ebebeb !important;
    color: #fff !important;
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
    max-width: 380px;
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.item-title{
    font-size: 14px;
    font-weight: 400;
    color : #a5abbb;
}
.item-value{
    font-size: 14px;
    font-weight: 400;
    color : #000;
}

.item-date{
    font-size: 16px;
    font-weight: 400;
    color : #000;
}


</style>