<template>
    <div class="wrapper">
        <v-card color="white" class="rounded-lg" flat> 
        <v-sheet height="700" class="py-1 px-5">
          <div class="header-container" style="display: flex; flex-direction: row; align-items: center;">
          <div class="arrow-btns-wrapper" style="text-align: left;">
            <button class="nav" @click="goToPrevDay"><span class="mdi mdi-chevron-left"></span></button>
            <button class="nav" @click="goToNextDay"><span class="mdi mdi-chevron-right"></span></button>
          </div>
          <h2 class="vt-title">{{ formatDate(currentDate) }}</h2>
        </div>
          <div id="visualization"></div>
          <div id="calendar">
          </div>
        </v-sheet>
    </v-card>
    <!-- the drag edit modal  -->
    <eventDragModal :parent="parent" :isDraged="isDragEvent" :eventDraged="dragedEvent" @dragEditedEvent="dragWellEditedEvent" @closeModalDragEvent="closeEtAnnuler"/>
    <!--end  the drag edit modal  -->
    </div>
  </template>
  
  <script>
  import { Timeline } from 'vis-timeline/standalone';
  import dayjs from "@/utils/dayjsConfig";
  import eventDragModal from './modal/eventDragModal.vue'
  export default {
    name: 'timeLineDay',
    components: {
      eventDragModal,
    },
    props:{
        events: {
            type: Array,
            required: true,
        },
        groups: {
            type: Array,
            required: true,
        },
        selectDay: {
            type: String,
            required: true,
        },
        parent:{
          type: String,
        }
    },
    data() {
      return {
        isDragEvent: false,
        modifiedEvents: [],
        modifiedGroups: [],
        divCluster: null,
        currentDate: dayjs(this.selectDay).format('YYYY-MM-DD'),
        initialeDate : this.selectDay,
        dragedEvent: {
            id: "",
            title: "",
            oldStart: "",
            oldEnd: "",
            newStart: "",
            newEnd: "",
        },
        dateoption :[
            '2023-06-01',
            '2023-06-02',
            '2023-06-03',
        ],
        listGroups: [
          { id: 0, content: 'Group 1' },
          { id: 1, content: 'Group 2' },
        ],
        
        // timeline options
        options: {
          orientation: 'top',
          itemsAlwaysDraggable: true,
          template:(itemData, element, data) => {
              if (data.isCluster) {
                return `<span class="cluster-header"></span><div>Voir ${data.items.length} + </div>`;
              } else {
                return `<div>${data.content}</div>`;
              }
            },
          timeAxis: { 
            scale: 'hour', 
            step: 1 ,
            
             },
             margin: {
                item: {
                  horizontal: 20,
                  vertical: 0
                }
              },
              showTooltips: true,
          cluster:{
            maxItems: 1,
            clusterCriteria: function (item) {
              return item.group;
            },
            fitOnDoubleClick: true,
            showStipes: true,
            titleTemplate: '{count} visites',

          },
          stack: true,
          zoomMax: 3600000 * 26, //3600000 zoom hour
          // initiale:
          zoomMin : 3600000 * 20,
          minHeight: '600px',
          start: this.selectDay,
          end: dayjs(this.selectDay).add(24,'hour').format('YYYY-MM-DD HH:mm'),
          showCurrentTime: true,
          groupHeightMode	: 'auto',
        },
        dataLoaded: null,
        timeline: null,
      };
    },
    computed: {
     getUser(){
      return this.$store.getters.getUser;
     }
    },
    methods: {
      
      onSelect(properties) {
            var item = this.events.find(item => item.id == properties.items)
            this.isDragEvent = true;
            this.dragedEvent.id = item.id
            this.dragedEvent.group = item.group
            this.dragedEvent.title = item.title
            this.dragedEvent.dateDebut= item.dateDebut
            this.dragedEvent.dateFin= item.dateFin
            this.dragedEvent.timeStart= dayjs(item.start).format('HH:mm')
            this.dragedEvent.timeEnd= dayjs(item.end).format('HH:mm')
            this.dragedEvent.start= dayjs(item.start).format('YYYY-MM-DD')
            this.dragedEvent.end= dayjs(item.end).format('YYYY-MM-DD')
            this.dragedEvent.owner= item.owner
            this.dragedEvent.description= item.description
            this.dragedEvent.color= item.color
            this.dragedEvent.allDay= item.allDay      
        },
        async closeEtAnnuler(){
          this.$emit('annulerLaModification');
          this.timeline.setItems(this.modifyEvents());
          
          this.isDragEvent = false;
        },
        
        async dragWellEditedEvent(editedEvent){
          var event ={
              id: editedEvent.id,
              title: editedEvent.title,
              dateDebut: dayjs(new Date(editedEvent.start)).format('YYYY-MM-DD'),
              dateFin: dayjs(new Date(editedEvent.end)).format('YYYY-MM-DD'),
              timeStart: editedEvent.timeStart,
              timeEnd: editedEvent.timeEnd,
              start:  editedEvent.start,
              end:  editedEvent.end,
              owner: editedEvent.owner,
              description: editedEvent.description,
              group: editedEvent.group,
              color: editedEvent.color,
              allDay: editedEvent.allDay,
          };
          this.$emit('miseAjourEffectuer', event);
          //await this.editEvent(event)
          this.isDragEvent = false;
        },
        async editEvent(newEvent){
          var index = this.events.findIndex(item => item.id == newEvent.id)
          if (index != -1){
            this.events[index].title = newEvent.title
            this.events[index].dateDebut= newEvent.dateDebut
            this.events[index].dateFin= newEvent.dateFin
            this.events[index].timeStart= newEvent.timeStart
            this.events[index].timeEnd= newEvent.timeEnd
            this.events[index].start= dayjs(new Date(newEvent.dateDebut + " "+ newEvent.timeStart)).format('YYYY-MM-DD HH:mm')
            this.events[index].end= dayjs(new Date(newEvent.dateFin + " "+ newEvent.timeEnd)).format('YYYY-MM-DD HH:mm')
            this.events[index].owner= newEvent.owner
            this.events[index].description= newEvent.description
            this.events[index].group= newEvent.group
            this.events[index].color= newEvent.color
            this.events[index].allDay= newEvent.allDay
          }
          this.timeline.setItems(this.modifyEvents());
        },
        goToNextDay() {
            const nextDay = new Date(this.currentDate);
            this.currentDate = dayjs(nextDay.setDate(nextDay.getDate() + 1)).format('YYYY-MM-DD HH:mm');
            this.options.start= this.currentDate
            this.options.end= dayjs(this.currentDate).add(15,'hour').format('YYYY-MM-DD HH:mm')
            this.timeline.setOptions(this.options);         
            var dateDebut = dayjs(this.currentDate).format('YYYY-MM-01')
            var dateFin = dayjs(dateDebut).add(1, 'month').format('YYYY-MM-DD')
            this.$emit('rechargeEvents', {dateDebut:dateDebut, dateFin:dateFin});
            
        },
        goToPrevDay() {
            const nextDay = new Date(this.currentDate);
            this.currentDate = dayjs(nextDay.setDate(nextDay.getDate() - 1)).format('YYYY-MM-DD HH:mm');
            this.options.start= this.currentDate
            this.options.end= dayjs(this.currentDate).add(15,'hour').format('YYYY-MM-DD HH:mm')
            this.timeline.setOptions(this.options);
            var dateDebut = dayjs(this.currentDate).format('YYYY-MM-01')
            var dateFin = dayjs(dateDebut).add(1, 'month').format('YYYY-MM-DD')
            this.$emit('rechargeEvents', {dateDebut:dateDebut, dateFin:dateFin});
        },
        initDay(){
          const nextDay = new Date(this.currentDate);
            this.currentDate = dayjs(nextDay.setDate(nextDay.getDate())).format('YYYY-MM-DD HH:mm');
            this.options.start= this.currentDate
            this.options.end= dayjs(this.currentDate).add(15,'hour').format('YYYY-MM-DD HH:mm')
            this.timeline.setOptions(this.options);
            var dateDebut = dayjs(this.currentDate).format('YYYY-MM-01')
            var dateFin = dayjs(dateDebut).add(1, 'month').format('YYYY-MM-DD')
            this.$emit('rechargeEvents', {dateDebut:dateDebut, dateFin:dateFin});
            
          },
      
        updateTimeline() {
          //destroy timeline
          this.timeline.destroy();
          // Create a Timeline
          this.timeline = new Timeline(document.getElementById('visualization'));
          this.timeline.setOptions(this.options);
          this.timeline.setGroups(this.groups);
        
         // this.timeline.setItems(this.items);
        },
        async handleMouseOut(event){
          
          await this.closeEtAnnuler()
        },
        modifyEvents() {
          const modifiedEvents = this.events.map((event, index) => {
              const idgroup = this.getGroupId(event.owner);
              return {
                id: event.id,
                group: idgroup,
                owner: event.owner,
                content: event.title,
                title: event.title,
                dateDebut: event.dateDebut,
                dateFin: event.dateFin,
                timeStart: event.timeStart,
                timeEnd: event.timeEnd,
                start: event.start,
                end: event.end,
                description: event.description,
                color: event.color,
                allDay: event.allDay,
                style: "background-color: "+event.color+";",
              };
          });
          return modifiedEvents;
        },
    
        modifyGroups() {
           return this.modifiedGroups = this.groups.map((group, index) => {
            return {
                id: group.id,
                content: group.name,
                className: 'vis-h0',
                };
            });
        },
        getGroupId(groupName) {
            const group = this.groups.find(group => group.name === groupName);
            return group ? group.id : null;
        },
        handleDragEvent(item) {
            this.isDragEvent = true;
            
        },
        handleClick(event) {
          console.log("click")
          this.options['cluster'] = false
          this.timeline.setOptions(this.options);
        },
        formatDate(date) {
            
            // in langage french
            dayjs.locale('fr');
            return dayjs(date).format('MMMM D, YYYY');
       
        },         
    },
     mounted() {
        this.dataLoaded;
        // Create a Timeline
        this.timeline = new Timeline(document.getElementById('visualization'));       
        this.timeline.setItems(this.modifyEvents());
        this.timeline.setGroups(null);
        this.timeline.setOptions(this.options);
         // Set the initial start and end dates for the timeline
        this.timeline.setWindow(this.options.start, this.options.end, {
          animation: false,
        });

        // Adjust the zoom level to the maximum zoom (zoomMax)
        this.timeline.setOptions({
          zoomMin: this.options.zoomMax, // Set the minimum zoom level to the maximum zoom level
        });
        this.timeline.on('select', this.onSelect);
        this.initDay();   
    },
    watch: {
        //update the modified events went events change
        events: function (newEvents) {
          this.timeline.setGroups(null);
          this.timeline.setItems(this.modifyEvents());
          let divCluster = document.querySelector('.vis-cluster');
          if(divCluster){
            divCluster.addEventListener('click', this.handleClick);
          }
        },
        //update the modified groups went groups change
        groups: function (newGroups) {
            this.timeline.setGroups(null);       
        },
        options: function (newOptions) {
         
        },
        currentDate: function (newDate) {
          let divCluster = document.querySelector('.vis-cluster');
          if(divCluster){
            divCluster.addEventListener('click', this.handleClick);
          }
        },
             
    },
  };
  </script>
  
  <style>
    .vis-time-axis .vis-grid.vis-odd {
      background: #f5f5f5c9;
      border-collapse: separate;
      border-spacing: 0;
      border-right: 0.01rem solid #aeafb100 !important;
      border-top: 0px !important ;
      border-bottom: 0px;
    }
    .vis-time-axis .vis-grid.vis-saturday,
    .vis-time-axis .vis-grid.vis-sunday {
      background: rgb(29, 201, 135) !important;
    }
    .vis-time-axis .vis-text.vis-saturday,
    .vis-time-axis .vis-text.vis-sunday {
      color: rgb(206, 211, 214) !important;
      
    }
    .vis-time-axis .vis-text{
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: .995rem !important;
      font-weight: 500 !important;
      padding: 0.70rem;
      border-top: #8d8d8d2c 1px solid;
      border-bottom: #8d8d8d2c 1px solid; 
    }
    .vis-item {
      background-color: #d6e6ff07;
      border-color: #c6c7c900;
      color: #464646;
      font-weight: 500;
      opacity: 1.8;
      font-size: 13px;
      height: 35px;
      border-radius: 9px !important;
      border: 3px solid #ffffff;
    }
  
    .vis-cluster {
      display: flex;
      align-items: center;
      background-color: #3f8d9b;
      color: #ffffff;
      font-weight: 500;
      font-size: 13px;
      border-radius: 9px !important;
      height: 25px;

      justify-content: center;
}
    .vis-h0 .vis-h01 .vis-h15 .vis-h16 {
      color: #2c3e50 !important;
      height: 100px;
      text-align: center;
      font-weight: bold;
    }
    .vis-text {
      color: #7f8a8a !important;
      text-align: center;

    }
    .vis-label {
      color: #286657 !important;
      text-align: center;

    }
    .vis-text .vis-major .vis-h15  .vis-odd{
      color: #7f8a8a !important;
      text-align: center;     
    }
    .vis-timeline {
      margin-top: 3px;
      background-color: #ffffffce;
      border-collapse: separate;
      border-spacing: 0;
      border: 0.1rem solid #b9b9b941 !important;
      border-radius: 0.2rem;      
    }
    .vis-time-axis .vis-foreground{
      background:linear-gradient(45deg, rgb(0, 121, 145) 0%, rgb(24, 174, 162) 68%) rgb(0, 121, 145) !important;
    }
    .vis-major{
      
      border-bottom: 0px !important;
      border-top: 0px !important;

    }
    .vis-inner{
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
      font-size: .975rem !important;
      font-weight: 500 !important;
      
      
    }
 
    .vt-title{
      font-family: Work Sans,sans-serif;
      font-weight: 500;
      margin: 0;
      line-height: 1.5;
      letter-spacing: -.02em;
      color: #1b2734;
      font-size: 2.074rem;
    }
    .vis-panel.vis-center, .vis-panel.vis-left, .vis-panel.vis-right, .vis-panel.vis-top, .vis-panel.vis-bottom {
      border: 0.5px #e7e7e7 solid !important;
    }

  </style>
  