<template>
  <div class="page-finale">
    <img class="img-bg" src="../images/PageFinale.png" />
    <div class="content">
      <div class="mb-6">
        <p class="text-para">Eneffic</p>
        <p class="text-para">2 All du Levant</p>
        <p class="text-para">34970 Lattes</p>
      </div>
      <div class="">
        <p class="text-para">33 (0)4 65 84 38 76</p>
        <p class="text-para">contact@eneffic.fr</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFinale",
  data() {
    return {
      
    }
  },
};
</script>

<style lang="scss" scoped>
.page-finale{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  overflow: hidden;

  .img-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .content{
    position: relative;
    width: 100%;
    padding: 32px;
    margin-top: 50px;
    z-index: 2;
    
    .text-para{
      font-size: 16pt;
      font-weight: 500;
      text-align: center;
      color:#e4eaec;
      margin-bottom: 0px;
      z-index: 2;
    }

  }
}
</style>