<template>
  <v-card>
    <Loading
      v-if="loadingGlobal"
      :dialogProps="dialogLoading"
      :messageDialogeProps="messageDialog"
      :loadingProps="loadingGlobal"
      :errorProps="erreurProps"
    />
    <Dossiers
      v-if="this.label.typeData == 'folder/images'"
      @back="goBack()"
      @next="goNext($event)"
      :filesImages="filesPhotos"
      :ListFolder="listFolder"
      :hideFolderProps="hideFolder"
      :previousDossierOrder="previousDossierOrder"
    />
    <Gallery
      v-if="this.label.typeData != 'folder/images' && hideFolder == true"
      :filesParentProps="filesPhotos"
      :addImagesBool="addImage"
      :fileDetails="this.label"
      :idSociete = "idSociete"
      @updatePagination="updatePaginationImages"
      :onlyImage="false"
      :listLength="pagination.length"
    />
    <listeImages
      v-if="this.label.typeData == 'folder/images' && hideFolder == true"
      :photoSociete="photoSociete"
      :idSociete="idSociete"
      :fileDetails="this.label"
      @updateList="handelUpdateListImagesSociete()"
      :filesImages="filesPhotos"/>
    
    <PaginationFiles
      v-if="showPagination"
      :pagination="pagination"
      @selectNumberOfRecords="changeLength"
      @next="getData(pagination.nextPageUrl)"
      @first="getData(pagination.firstPage)"
      @last="getData(pagination.lastPageUrl)"
      @prev="getData(pagination.prevPageUrl)"
      @current="getData(pagination.currentPage)"
    >
    </PaginationFiles>
    <p></p>
  </v-card>
</template>
<script>
import axios from "axios";
import Dossier from "./Dossier.vue";
import Dossiers from "./Dossiers.vue";
import Gallery from "./gallery.vue";
import listeImages from "./listeImages.vue"
import Loading from "@/components/Loading.vue";
import PaginationFiles from "./PaginationFiles.vue";
import {
  getVisiteTechniqueFiles,
  getVisiteInstallationFiles,
  getImagesVisiteInstallation,
  getImagesSociete,
  getVisiteTechnique,
} from "@/services/galleryImage.js";
import { f } from "vue-plotly";
export default {
  name: "galleryImage",
  props: ["idSociete", "filesParentProps", "label"],
  components: { Dossier, Dossiers, Gallery, Loading, PaginationFiles, listeImages },
  data() {
    return {
      listeTitle: ["documentPhotos", "visiteInstallationFiles", "visiteTechniqueFiles"],
      loadingGlobal: false,
      dialogLoading: false,
      messageDialog: "",
      erreurProps: false,
      showPagination: false,
      addImage: false,
      hideFolder: true,
      listFolder: {},
      filesParent: [],
      filesPhotos: [],
      locationPath: null,
      pagination: {
        perPage: ["9", "16", "32"],
        firstPage: 1,
        lastPage: "",
        currentPage: 1,
        total: 0,
        length: 9,
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: 0,
        to: 0,
      },
      photoSociete:false,
      urlAPIPagination: "",
      visiteTechnique: {},
      firstThreeFolder: [
        {
          id: null,
          name: "visiteTechniqueFiles",
        },
        {
          id: null,
          name: "visiteInstallationFiles",
        },
        {
          id: null,
          name: "documentPhotos",
        }
      ],
      previousDossierOrder: [],
      jsonTest: {
        status: true,
        data: {
          visiteTechniqueFiles: {},
          visiteInstallationFiles: {},
          documentPhotos: [],
        },
      },
    };
  },
  async created() {
    this.listFolder = this.firstThreeFolder
    this.urlAPIPagination = process.env.VUE_APP_URL_API_CLIENT + this.label.url
    this.hideFolder = this.label.hideFolder
    await this.getData(1)
   },
  watch: {
    label: async function (newValue) {
        this.urlAPIPagination =process.env.VUE_APP_URL_API_CLIENT +  this.label.url
        this.hideFolder = this.label.hideFolder
        if(newValue.url != ""){
          await this.getData(1)
        }else{
          this.listFolder = this.firstThreeFolder
          this.filesPhotos = []
          this.showPagination = false
        }
       
       
    },

  },
  methods: {
    async changeLength({type, key}){
      this.pagination.length = key
      await this.getData(this.pagination.currentPage)
    },
    async getData(pageNumber) {
      // this.loadingGlobal = true;
      // this.dialogLoading = true;
      // determiner si la pagination est une visite technique ou pas
      if (this.visiteTechnique.id) {
        await axios({
          url: this.urlAPIPagination, // a remplacer apres test
          method: "POST",
          data: {
            id_societe: this.idSociete,
            id_visite_technique: this.visiteTechnique.id,
            type_question: this.visiteTechnique.name,
            pageNumber: pageNumber,
            length: this.pagination.length,
            token:this.$store.getters.getUserToken,
          },
        }).then((res) => {
          if (res.data.status) {
            this.updatePagination(res.data.data.pagination)
            // check if folder is hidden or not
            if(this.hideFolder){
              this.filesPhotos = [...res.data.data.data];
            }else{
              this.listFolder = [...res.data.data.data];
            }
            this.showPagination = true;
            this.loadingGlobal = false;
            this.dialogLoading = false;
          }
        });
      }else if(this.urlAPIPagination != ""){
        await axios({
          url: this.urlAPIPagination, // a remplacer apres test
          method: "POST",
          data: {
            id_societe: this.idSociete,
            id_visite_technique: this.visiteTechnique.id,
            type_question: this.visiteTechnique.name,
            pageNumber: pageNumber,
            length: this.pagination.length,
            token:this.$store.getters.getUserToken,
          },
        }).then((res) => {
          if (res.data.status) {
            this.updatePagination(res.data.data.pagination)
            if(this.hideFolder){
              this.filesPhotos = [...res.data.data.data];
            }else{
              this.listFolder = [...res.data.data.data];
            }
            this.showPagination = true;
            this.loadingGlobal = false;
            this.dialogLoading = false;
          }
        });
      }
    },
    async goNext(event) {
      let response;
     
      
      if ((event?.name == "visiteTechnique" && event?.name !=null) || (event?.name == "cheminement"&& event?.name !=null)) {
        this.photoSociete = false;
        this.hideFolder = true;
        this.showPagination = false;
        // si dossier = visiteTechnique ou cheminement
        const objectRequest = {
          id: this.idSociete,
          pageNumber: this.pagination.currentPage,
          length: this.pagination.length,
          id_vt: event.id,
          name: event.name,
        };
        this.loadingGlobal = true;
        this.dialogLoading = true;
        this.visiteTechnique = { ...event };
        this.urlAPIPagination = process.env.VUE_APP_URL_API_CLIENT + "getListImageVisiteTechnique/";
        response = await getVisiteTechnique(objectRequest);
        this.photoSociete = false;
        let arrayTemp = [];
        response.data.forEach((element) => {
          if (!!element) {
            // test si y il a des element non null dans la reponse
            arrayTemp.push(element);
          }
        });
        this.filesPhotos = [...arrayTemp];
        if (this.filesPhotos.length > 0) {
          this.showPagination = true;
        }
        this.updatePagination(response.pagination)
        this.loadingGlobal = false;
        this.dialogLoading = false;
        this.locationPath = event
      } else if (this.listeTitle.includes(event.name)) {
        // si dossier egale visite technique files, visite installation etc ...
       
        this.loadingGlobal = true;
        this.dialogLoading = true;
        const objectRequest = {
          id: this.idSociete,
          pageNumber: this.pagination.currentPage,
          length: this.pagination.length,
        };
        if (event.name == "visiteTechniqueFiles") {
          this.urlAPIPagination = process.env.VUE_APP_URL_API_CLIENT + "getListVisteTechniqueProfilClient/";
          response = await getVisiteTechniqueFiles(objectRequest);
          //gestion 404
          if (response.status == 404) {
            this.loadingGlobal = false;
            this.erreurProps = true;
            this.messageDialog = "Introuvable";
            this.showPagination = false;

          } else {
            let arrayTemp = [];
            response.data.forEach((element) => {
              if (!!element) {
                arrayTemp.push(element);
              }
            });
            this.listFolder = [...arrayTemp];
            this.showPagination = true;
            this.loadingGlobal = false;
            this.locationPath = event
          }
        }
        if (event.name == "visiteInstallationFiles") {
          this.urlAPIPagination = process.env.VUE_APP_URL_API_CLIENT + "getListVisiteInstallationBySocieteForProfilClient/";
          const objectRequest = {
            id: this.idSociete,
            pageNumber: this.pagination.currentPage,
            length: this.pagination.length,
          };
          response = await getVisiteInstallationFiles(objectRequest);
          let arrayTemp = [];
          response.data.forEach((element) => {
            if (!!element) {
              arrayTemp.push(element);
            }
          });
          this.listFolder = [...arrayTemp];
          this.showPagination = true;
          this.locationPath = event
        }
        if (event.name == "documentPhotos") {
          this.photoSociete = true;
          this.showPagination = true;
          this.urlAPIPagination = process.env.VUE_APP_URL_API_CLIENT + "getListSocietePhotoProfilClient/";
          response = await getImagesSociete(objectRequest);
          this.filesPhotos = [...response.data];
          this.listFolder = [];
          this.hideFolder = true;
        }
        this.updatePagination(response.pagination)

        this.loadingGlobal = false;
        this.dialogLoading = false;
      
      } else {
        // si dossier egale visite technique files, visite installation etc ...
        this.photoSociete = false;
        this.showPagination = false;
        if(event.typeDossier != 'VI'){
          let varTemp = [];
          for (const element of this.listFolder) {
            if (element.id == event.id) {
              for (const key of Object.keys(element)) {
                if (key != "id" && key != "name" && key!= 'typeDossier') {
                  Object.keys(element[`${key}`]).forEach((subElement) => {
                    varTemp.push({ name: subElement, id: element.id });
                  });
                }
              }
              break; // Sortir de la boucle une fois que l'élément est trouvé.
            }
          }
          this.listFolder = { ...varTemp };
          this.locationPath = event
        }else if(event.typeDossier == 'VI'){
          this.photoSociete = false;
          this.hideFolder = true;
          const objectRequest = {
            id: this.idSociete,
            pageNumber: this.pagination.currentPage,
            length: this.pagination.length,
            id_visite_installation: event.id,
            name: event.name,
          };
          this.loadingGlobal = true;
          this.dialogLoading = true;
          this.urlAPIPagination = process.env.VUE_APP_URL_API_CLIENT + "getListImageVisiteInstallation/";
          response = await getImagesVisiteInstallation(objectRequest);
          let arrayTemp = [];
          this.photoSociete = false; 
          response.data.forEach((element) => {
            if (!!element) {
              // test si y il a des element non null dans la reponse
              arrayTemp.push(element);
            }
          });
          this.filesPhotos = [...arrayTemp];
          if (this.filesPhotos.length > 0) {
            
            this.showPagination = true;
          }
          this.updatePagination(response.pagination)
          this.loadingGlobal = false;
          this.dialogLoading = false;
        }
      }
      this.previousDossierOrder.push(event)
    },
    async goBack() {
      this.previousDossierOrder.splice((this.previousDossierOrder.length-1),1);
      this.addImage = false;
      this.hideFolder = false;
      this.filesPhotos = [];
      if (this.previousDossierOrder.length == 0) {
        this.listFolder = [ ...this.firstThreeFolder ];
        this.showPagination = false;
      } else if(this.previousDossierOrder.length==1){
        let current = this.previousDossierOrder[0].name
        let response;
        const objectRequest = {
          id: this.idSociete,
          pageNumber: this.pagination.currentPage,
          length: this.pagination.length,
        };
        this.loadingGlobal = true;
        this.dialogLoading = true;
        if (current != "documentPhotos") {
          if (current == "visiteTechniqueFiles") {
            this.urlAPIPagination = process.env.VUE_APP_URL_API_CLIENT + "getListVisteTechniqueProfilClient/";
            response = await getVisiteTechniqueFiles(objectRequest);
            let arrayTemp = [];
            response.data.forEach((element) => {
              if (!!element) {
                arrayTemp.push(element);
              }
            });
            this.listFolder = [...arrayTemp];
            this.showPagination = true;
          } else if (current == "visiteInstallationFiles") {
          this.urlAPIPagination = process.env.VUE_APP_URL_API_CLIENT + "getListVisiteInstallationBySocieteForProfilClient/";
            response = await getVisiteInstallationFiles(objectRequest);
            this.listFolder = response.data;
            this.showPagination = true;
          }
        } else if (current == "documentPhotos") {
          this.urlAPIPagination = process.env.VUE_APP_URL_API_CLIENT + "getListSocietePhotoProfilClient/";
          response = await getVisiteInstallationFiles(objectRequest);
          this.filesPhotos = [...response.data];
          this.listFolder = [];
        }
        this.updatePagination(response.pagination)
        this.loadingGlobal = false;
        this.dialogLoading = false;
      }else {
        var current = this.previousDossierOrder[this.previousDossierOrder.length-1]
        this.showPagination = false;
        let varTemp = [];
        for (const element of this.listFolder) {
          if (element.id == current.id) {
            for (const key of Object.keys(element)) {
              if (key != "id" && key != "name" && key!= 'typeDossier') {
                Object.keys(element[`${key}`]).forEach((subElement) => {
                  varTemp.push({ name: subElement, id: element.id });
                });
              }
            }
            break; // Sortir de la boucle une fois que l'élément est trouvé.
          }
        }
        this.listFolder = { ...varTemp };
      }
    },
    async updatePagination(currentPagination){
        this.pagination.lastPage= currentPagination.lastPage
        this.pagination.currentPage= currentPagination.currentPage
        this.pagination.total= currentPagination.total
        this.pagination.lastPageUrl= currentPagination.lastPageUrl
        this.pagination.nextPageUrl= currentPagination.nextPageUrl
        this.pagination.prevPageUrl= currentPagination.prevPageUrl
        this.pagination.from= currentPagination.from
        this.pagination.to= currentPagination.to

    },
    async handelUpdateListImagesSociete(){
      this.urlAPIPagination = process.env.VUE_APP_URL_API_CLIENT + "getListSocietePhotoProfilClient/";
      const objectRequest = {
            id: this.idSociete,
            pageNumber: this.pagination.currentPage,
            length: this.pagination.length,
            
          };
      let response = await getImagesSociete(objectRequest);
      this.filesPhotos = [...response.data];
      this.updatePagination(response.pagination)
      

    },
    async updatePaginationImages(value){
     this.updatePagination(value)
    }
  },
};
</script>
