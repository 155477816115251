<template>
  <div>
    <v-dialog width="60%" v-if="openDialogPhoto" v-model="openDialogPhoto">
      <v-card>
        <v-img :src="imageBaseURL + pathImage"> </v-img>
      </v-card>
    </v-dialog>
    <v-dialog width="60%" v-model="pdfDialog">
      <v-card>
          <vue-pdf-app style="height: 100vh;" :pdf="pathPdf" :page-scale="50" v-if="pdfDialog"></vue-pdf-app>
      </v-card>
    </v-dialog>
    <v-row class="px-4 mb-8" justify="center">

      <v-col
        v-for="(file, index) in files"
        :key="index"
        class="d-flex child-flex"
        
       
        cols="3">
        <v-tooltip v-if="file?.extention !=='pdf'" bottom >
          <template v-slot:activator="{ on, attrs }">
            <v-card v-bind="attrs" v-on="on" class="d-flex flex-row" >
              <v-icon
                v-if="photoSociete"
                color="black"
                @click.stop="removeElement(index,file.id)"
                style="position: absolute; top: 3px; right: 3px; z-index: 15"
                >mdi-close-box-outline</v-icon>
              <v-img
                
                @click="openDialogShowPhoto(file.path)"
                style="cursor: pointer"
                height="125px"
                
                :src="imageBaseURL + file.path">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            
            </v-card>
          </template>
          <span>{{ file.name }}</span>
        </v-tooltip>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-card @click="openDialogShowPdf(file.path)" v-bind="attrs" v-on="on" class="d-flex flex-row" style="min-height: 125px; background-color: #fafafa">
              <v-icon
                v-if="photoSociete"
                color="black"
                @click.stop="removeElement(index,file.id)"
                style="position: absolute; top: 3px; right: 3px; z-index: 15"
                >mdi-close-box-outline</v-icon>
                <div class="icon mx-auto my-auto">
                  <v-icon
                    style="font-size: 65px; color: #f44336">
                    mdi mdi-file-pdf-box
                  </v-icon>
                </div>
            </v-card>
            </template>
            </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>
<script>

import VuePdfApp from "vue-pdf-app";
import axios from "axios";
export default {
  name: "Photo",
  props: ["files","photoSociete","idSociete"],
  components: { VuePdfApp },
  data() {
    return {
      pathImage: "",
      pdfDialog: false,
      pathPdf: "",
      openDialogPhoto: false,
      imageBaseURL: process.env.VUE_APP_URL_API_CLIENT_BASE_URL,
      imageExtention: ["png", "jpg", "jpeg", "gif", "bmp", "svg", "webp"],
    };
  },
  methods: {
    openDialogShowPhoto(path) {
      this.openDialogPhoto = true;
      this.pathImage = path;
    },
    openDialogShowPdf(path) {
  
        path= process.env.VUE_APP_URL_API_CLIENT_BASE_URL + path;
        this.pdfDialog = true;
        this.pathPdf = path;
        console.log(path);
      },
    removeElement(index,id) {
      this.files.splice(index, 1);
      this.deleteFileServerSide(id);
      },
    
    async deleteFileServerSide(file_id){
      // delete file from server
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "deleteDocumentProfilClient/",
        method: "POST",
        data:{
          id_societe: this.idSociete,
          token: this.$store.getters.getUserToken,
          id_profil_client: file_id,

        },
      }).then((res) => {
        if (res.data.status) {
          this.$emit("updateList");
        }
      });
      
    },
  
  },
};
</script>
