var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedInstallation == _vm.installationId || _vm.showAll),expression:"selectedInstallation == installationId || showAll"}],staticClass:"outer-container",attrs:{"data-id":_vm.installationId,"id":'installation' + _vm.installationId},on:{"mousedown":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isHidden),expression:"!isHidden"}],staticClass:"hide-grid",style:({ height: _vm.height * 0.07 + 'px' }),on:{"click":() => {
        this.isActive = !this.isActive;
      }}},[_c('img',{staticStyle:{"height":"80%","width":"80%"},attrs:{"src":"/hidden.png"}})]),_c('div',{staticClass:"grid-container",style:({
      transform: `rotate(${_vm.angle}deg)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    })},[_vm._l((_vm.grid),function(cell){return _c('div',{key:`${cell.x}-${cell.y}`,class:_vm.getCellClasses(cell),style:(_vm.getCellStyle(cell)),on:{"mousedown":function($event){$event.preventDefault();_vm.isActive && _vm.handleMouseDown(cell)},"mouseup":function($event){$event.preventDefault();return _vm.handleMouseUp.apply(null, arguments)},"mousemove":function($event){$event.preventDefault();_vm.isActive && _vm.handleMouseMove(cell)}}},[(cell.type === 'selected')?_c('img',{staticClass:"cell-image",attrs:{"src":require('@/assets/solar_module_small.jpg')}}):_vm._e()])}),(_vm.isActive)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isHidden),expression:"!isHidden"}],staticClass:"rotation-arrow",style:(_vm.getArrowStyle),on:{"mousedown":function($event){$event.preventDefault();return _vm.startRotation.apply(null, arguments)}}},[_c('img',{style:({ height: _vm.height * 0.095 + 'px' }),attrs:{"src":"/iconRotateZ.png"}})]):_vm._e(),(_vm.isActive)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isHidden),expression:"!isHidden"}],staticClass:"drag-handle",style:({ height: _vm.height * 0.095 + 'px' }),attrs:{"src":"/iconTranslateXY.png"},on:{"mousedown":function($event){$event.preventDefault();return _vm.startDrag.apply(null, arguments)}}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }