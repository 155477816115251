<template>
  <div class="left-items">
    <div class="tech_list">
      <v-dialog
        v-model="UnaffectedEventModal"
        persistent
        width="600"
        style="z-index: 1050"
      >
        <v-card>
          <v-toolbar :style="{ background: $styles.background }">
            <span class="Poppins-Bold pl-0" style="color: whitesmoke"
              >Affecter un evenement</span
            >
          </v-toolbar>
          <v-form ref="form" class="pa-4">
            <div class="ma-0 pa-0">
              <div class="d-flex flex-row">
                <div style="width: 50%" class="ma-0 pa-0 mx-1">
                  <v-text-field
                    dense
                    v-model="editedEvent.title"
                    placeholder="25 rue Baltimore 1234"
                    prepend-inner-icon="mdi-map-search-outline"
                    label="Nom"
                    outlined
                    :rules="[rules.required, rules.isString]"
                  />
                </div>
                <div style="width: 50%" class="ma-0 pa-0 mx-1">
                  <v-autocomplete
                    outlined
                    dense
                    label="Choisir technicien(s)"
                    :items="techList"
                    :item-text="'name'"
                    :item-value="'id'"
                    v-model="editedEvent.Ownerid"
                    :rules="[rules.required]"
                  >
                  </v-autocomplete>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div style="width: 50%" class="ma-0 pa-0 mx-1">
                  <v-text-field
                    dense
                    type="date"
                    v-model="editedEvent.dateDebut"
                    placeholder="25 rue Baltimore 1234"
                    prepend-inner-icon="mdi-map-search-outline"
                    label="Date Debut"
                    outlined
                    :rules="[dateDebutLower]"
                  />
                </div>
                <div style="width: 50%" class="ma-0 pa-0 mx-1">
                  <v-text-field
                    dense
                    type="date"
                    v-model="editedEvent.dateFin"
                    placeholder="25 rue Baltimore 1234"
                    prepend-inner-icon="mdi-map-search-outline"
                    label="Date Fin"
                    outlined
                    :rules="[dateFinHigher]"
                  />
                </div>
              </div>
              <div class="d-flex flex-row">
                <div style="width: 50%" class="ma-0 pa-0 mx-1">
                  <v-text-field
                    dense
                    type="time"
                    v-model="editedEvent.timeStart"
                    placeholder="25 rue Baltimore 1234"
                    prepend-inner-icon="mdi-map-search-outline"
                    label="Heurs Debut"
                    outlined
                    :rules="[timeStartLower]"
                  />
                </div>
                <div style="width: 50%" class="ma-0 pa-0 mx-1">
                  <v-text-field
                    dense
                    type="time"
                    v-model="editedEvent.timeEnd"
                    placeholder="25 rue Baltimore 1234"
                    prepend-inner-icon="mdi-map-search-outline"
                    label="Heurs Fin"
                    outlined
                    :rules="[timeEndHigher]"
                  />
                </div>
              </div>
              <div class="d-flex flex-row">
                <v-textarea
                  dense
                  v-model="editedEvent.description"
                  placeholder="description"
                  label="description"
                  no-resize
                  outlined
                />
              </div>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-0">
                <v-row justify="end">
                  <div class="d-flex justify-end py-1">
                    <v-btn outlined color="error" @click="closeModal" class="mx-2">
                      Fermer
                    </v-btn>
                    <v-btn
                      :color="$colors[0]"
                      @click="affectEvent"
                      class="white--text"
                      :disabled="isUpdateButtonDisabled"
                    >
                      Créer
                    </v-btn>
                  </div>
                </v-row>
              </v-col>
            </div>
          </v-form>
        </v-card>
      </v-dialog>
      <div class="tech-card">
        <v-autocomplete
          label="Choisir technicien(s)"
          outlined
          clearable
          dense
          v-model="selectedItems"
          :items="techList"
          item-text="name"
          item-value="id"
          multiple
          return-object
          @input="updateSelection"
        >
          <!-- <template v-slot:selection="data">
                        <v-chip v-bind="data.attrs" :input-value="data.item" @click="data.select">{{ data.item.name }}</v-chip>
                    </template> -->
          <!-- <template v-slot:item="data">
                    <v-chip v-bind="data.attrs" :input-value="data.item" @click="data.select">{{ data.item.name }}</v-chip>
                </template> -->
        </v-autocomplete>
        <div class="mb-3">
          <span
            :style="{ color: $colors[1] }"
            class="ml-1 card-title Poppins-Bold"
            v-if="roleUser === 'chefIngenieur'"
          >
            Liste des Ingenieurs
          </span>
          <span :style="{ color: $colors[1] }" class="ml-1 card-title Poppins-Bold" v-else
            >Liste des Techniciens</span
          >
        </div>
        <v-list
          style="height: 250px; padding: 0px; overflow-y: auto"
          v-if="roleUser === 'chefIngenieur'"
        >
          <v-list-item
            v-for="(item, i) in techList"
            :key="i"
            :value="item"
            active-color="primary"
            rounded="shaped"
            class="tech_item"
            :class="{ selected: SelectedTech === item.name }"
            style="
              padding: 2px 16px !important;
              margin-left: 0px;
              margin-right: 0px;
              margin-bottom: 8px;
              margin-top: 0px;
            "
            :style="{
              'background-color': techNameToColor(i, item.name) + ' !important',
              color: techNameToColorText(i, item.name) + ' !important',
              fontWeight: '600 !important',
            }"
          >
            <v-list-item-title
              v-text="item.name"
              style="font-size: small !important"
            ></v-list-item-title>
            <input
              type="checkbox"
              class="rounded-checkbox"
              v-model="item.selected"
              :style="{
                'background-color': item.selected
                  ? techNameToColor(i, item.name) + ' !important'
                  : 'white !important',
                color: item.selected
                  ? techcolorFromArrayText(i, item.name) + ' !important'
                  : 'black !important',
              }"
              @change="updateSelection(item)"
            />
          </v-list-item>
        </v-list>
        <v-list style="height: 250px; padding: 0px; overflow-y: auto" v-else>
          <v-list-item
            v-for="(item, i) in techList"
            :key="i"
            :value="item"
            active-color="primary"
            rounded="shaped"
            class="tech_item"
            :class="{ selected: SelectedTech === item.name }"
            style="
              padding: 2px 16px !important;
              margin-left: 0px;
              margin-right: 0px;
              margin-bottom: 8px;
              margin-top: 0px;
            "
            :style="{
              'background-color': techNameToColor(i, item.name) + ' !important',
              color: techNameToColorText(i, item.name) + ' !important',
              fontWeight: '600 !important',
            }"
          >
            <v-list-item-title
              v-text="item.name"
              style="font-size: small !important"
            ></v-list-item-title>
            <input
              type="checkbox"
              class="rounded-checkbox"
              v-model="item.selected"
              :style="{
                'background-color': item.selected
                  ? techNameToColorText(i, item.name) + ' !important'
                  : 'white !important',
                color: item.selected
                  ? techcolorFromArrayText(i, item.name) + ' !important'
                  : 'black !important',
              }"
              @change="updateSelection(item)"
            />
          </v-list-item>
        </v-list>
      </div>
    </div>

    <div
      class="color_events"
      v-if="
        UnaffectedEvents?.length > 0 && $store.getters.hasPermission('VT', 'affecter')
      "
    >
      <div class="mt-4 non-affected-card">
        <div class="justify-left">
          <span :style="{ color: $colors[1] }" class="card-title ml-1 Poppins-Bold"
            >Visite non effectuée</span
          >
        </div>
        <v-list
          class="non-affected-card mt-2"
          style="max-height: 400px; overflow-y: auto"
        >
          <v-list-item-group>
            <v-list-item
              v-for="(item, i) in UnaffectedEvents"
              :key="i"
              class="mb-2 list-item-shadow"
              :value="item"
              active-color="primary"
              @click="openEvent(item)"
            >
              <div class="d-flex flex-row justify-content-between">
                <div class="card-body">
                  <span
                    class="badge badge-affected Poppins-Bold"
                    style="position: absolute; top: 0px; right: 0px; margin: 0px"
                    >{{ item.timeStart }} - {{ item.timeEnd }}</span
                  >
                  <h5 class="card-date pt-4">
                    {{ dateFormat(item.start) }} - {{ dateFormat(item.end) }}
                  </h5>
                  <span class="card-title Poppins-Bold">{{ item.title }}</span>
                  <div class="card-text">
                    <p class="text-muted text-truncate-two-lines mb-0">
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </div>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TechLeftMenu",
  props: {
    techList: {
      type: Array,
      required: true,
    },
    UnaffectedEvents: {
      type: Array,
      required: true,
    },
    globalEvents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      SelectedTech: null,
      selectedItems: [],
      searchIcon: "mdi-magnify",
      isCheckbox: true,
      UnaffectedEventModal: false,
      colorArray: [
        "rgba(103,177,115,.18)",
        "rgba(41,186,219,.18)",
        "rgba(255,200,75,.18)",
        "rgba(252,53,53,.18)",
        "rgba(128,0,0,.18)",
        "rgba(0,128,0,.18)",
        "rgba(0,0,128,.18)",
        "rgba(128,128,0,.18)",
        "rgba(128,0,128,.18)",
        "rgba(0,128,128,.18)",
        "rgba(255,128,0,.18)",
        "rgba(0,255,128,.18)",
        "rgba(128,255,0,.18)",
        "rgba(128,0,255,.18)",
        "rgba(255,128,128,.18)",
        "rgba(128,255,128,.18)",
        "rgba(128,128,255,.18)",
        "rgba(192,192,192,.18)",
      ],
      editedEvent: {
        id: "",
        title: "",
        dateDebut: "",
        dateFin: "",
        dateDebut: "",
        dateFin: "",
        timeStart: "",
        timeEnd: "",
        start: "",
        end: "",
        owner: "",
        Ownerid: "",
      },
      rules: {
        required: (value) => !!value || "Champ requis.",
        isString: (value) => typeof value === "string" || "Champ requis.",
      },
    };
  },
  mounted() {},
  computed: {
    roleUser() {
      return this.$store.getters.getUserRole;
    },
    dateDebutLower() {
      if (new Date(this.editedEvent.dateDebut) > new Date(this.editedEvent.dateFin)) {
        return "La date de début doit être inférieure ou égale à la date de fin";
      }
      return true;
    },
    dateFinHigher() {
      if (new Date(this.editedEvent.dateDebut) > new Date(this.editedEvent.dateFin)) {
        return "La date de début doit être inférieure ou égale à la date de fin";
      }
      return true;
    },
    timeStartLower() {
      if (this.editedEvent.dateDebut == this.editedEvent.dateFin) {
        if (this.editedEvent.timeStart >= this.editedEvent.timeEnd) {
          return "L'heure de début doit être inférieure";
        }
        return true;
      }
    },
    timeEndHigher() {
      if (this.editedEvent.dateDebut == this.editedEvent.dateFin) {
        if (this.editedEvent.timeEnd <= this.editedEvent.timeStart) {
          return "L'heure de fin doit être supérieure";
        }
        return true;
      }
    },

    isUpdateButtonDisabled() {
      const dateDebutLowerValid = this.dateDebutLower === true;
      const dateFinHigherValid = this.dateFinHigher === true;
      const timeStartLowerValid = this.timeStartLower === true;
      const timeEndHigherValid = this.timeEndHigher === true;

      if (this.editedEvent.dateDebut == this.editedEvent.dateFin) {
        return !(
          dateDebutLowerValid &&
          dateFinHigherValid &&
          timeStartLowerValid &&
          timeEndHigherValid
        );
      } else {
        return !(dateDebutLowerValid && dateFinHigherValid);
      }
    },
  },
  methods: {
    updateSelection() {
      this.techList.forEach((item) => {
        if (this.selectedItems.find((selectedItem) => selectedItem.id === item.id)) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
      this.$emit("Selected_tech");
    },
    select_tech(tech) {
      this.techList.forEach((item) => {
        if (tech.id === item.id) {
          item.selected = tech.selected;
          if (!this.selectedItems.find((selectedItem) => selectedItem.id === item.id)) {
            this.selectedItems.push(item);
          } else {
            var index = this.selectedItems.findIndex(
              (selectedItem) => selectedItem.id === item.id
            );
            this.selectedItems.splice(index, 1);
          }
        }
      });
      this.$emit("Selected_tech");
    },
    openEvent(item) {
      this.UnaffectedEventModal = true;
      this.editedEvent.id = item.id;
      this.editedEvent.title = item.title;
      this.editedEvent.start = item.start;
      this.editedEvent.end = item.end;
      this.editedEvent.dateDebut = item.dateDebut;
      this.editedEvent.dateFin = item.dateFin;
      this.editedEvent.timeStart = item.timeStart;
      this.editedEvent.timeEnd = item.timeEnd;
      this.editedEvent.owner = item.owner;
      this.editedEvent.description = item.description;
    },
    hasAnyEmptyProperty(obj) {
      return Object.values(obj).some((x) => x === null || x === "");
    },

    closeModal() {
      this.UnaffectedEventModal = false;
      // clear onwer dropdown
      this.editedEvent.Ownerid = "";
      this.editedEvent.owner = "";
    },
    async affectEvent() {
      const index = this.techList.findIndex(
        (item) => item.id === this.editedEvent.Ownerid
      );
      /*this.globalEvents.filter((e)=>{
                if(e.id == this.editedEvent.id){
                    e.title = this.editedEvent.title;
                    e.start = dayjs(this.editedEvent.dateDebut +' '+this.editedEvent.timeStart).format('YYYY-MM-DD H:m')
                    e.end = dayjs(this.editedEvent.dateFin +' '+this.editedEvent.timeEnd).format('YYYY-MM-DD H:m')
                    e.dateDebut=this.editedEvent.dateDebut;
                    e.dateFin = this.editedEvent.dateFin;
                    e.timeStart = this.editedEvent.timeStart;
                    e.timeEnd = this.editedEvent.timeEnd;
                    e.owner=this.techList[index].name;
                }else{
                }
            })*/
      if (index != -1) {
        if (this.$refs.form.validate()) {
          this.$emit("effectuerUneDemandeAuTech", {
            event: this.editedEvent,
            idTech: this.techList[index].id,
          });
          //this.$emit('refreshEvents', {events:this.globalEvents});
          this.UnaffectedEventModal = false;
          this.editedEvent.Ownerid = "";
          this.editedEvent.owner = "";
        }
      }
    },

    techNameToColor(index) {
      // generate color from name like this "rgba(41,186,219,.18)"
      let [r, g, b] = this.techList[index].color;
      // Generate the RGBA color string
      let color = `rgba(${r},${g},${b},0.18)`;
      return color;
    },
    techNameToColorText(index) {
      // generate color from name like this "rgba(41,186,219,.18)"
      let [r, g, b] = this.techList[index].color;
      // Generate the RGBA color string
      let color = `rgba(${r},${g},${b},1)`;
      return color;
    },
    techcolorFromArray(index, name) {
      if (index > this.colorArray.length) {
        return this.techNameToColor(name);
      } else {
        return this.colorArray[index % this.colorArray.length];
      }
    },
    techcolorFromArrayText(index, name) {
      if (index > this.colorArray.length) {
        return this.techNameToColorText(name);
      } else {
        // the colorArray bolder
        let color = this.colorArray[index % this.colorArray.length];
        return this.changeAlpha(color);
      }
    },
    changeAlpha(colorCode) {
      const matches = colorCode.match(/(\d+),(\d+),(\d+),(.+)/);
      const r = matches[1];
      const g = matches[2];
      const b = matches[3];
      // Generate the updated RGBA color string with alpha 1
      const updatedColorCode = `rgba(${r},${g},${b},1)`;
      return updatedColorCode;
    },
    dateFormat(date) {
      return this.$dayjs(date).format("DD/MM/YYYY");
    },
  },
};
</script>
<style scoped>
.selected {
  background-color: #e0e0e0;
}
h4 {
  font-weight: 600;
  font-size: 18px !important;
  text-transform: capitalize;
  color: #020f47;
  font-family: sans-serif;
  padding: 0px;
  margin: 0px;
}
h5.card-title {
  font-weight: 600;
  font-size: 18px !important;
  text-transform: capitalize;
  color: #020f47;
  font-family: sans-serif;
  margin-bottom: 10px;
}

.icon-circle {
  color: #3ba0ff;
  border-radius: 50%;
  padding: 5px;
  font-size: 16px;
}
.bg-light {
  background-color: #eafcff;
}
.card_color {
  background-color: #ffffff !important;
  border-color: #f5f5f5 !important;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2) !important;
  border-radius: 12px;
  width: 100%;
  margin: 0px 0;
  padding: 5px;
  cursor: pointer;
}
.card-date {
  font-weight: 400;
  font-size: 14px !important;
  text-transform: uppercase;
  color: #a5a5a5;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-bottom: 20px;
}
.non-affected-card {
  background-color: transparent !important;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2) !important;
  border-radius: 12px;
  width: 100%;
  margin: 0px;
  padding: 10px 2px;
  cursor: pointer;
}
.tech-card {
  background-color: #ffffff !important;
  border-color: #f5f5f5 !important;
  border-radius: 6px;
  box-shadow: 0px 4px 16px -4px rgba(194, 194, 194, 1);
  width: 100%;
  margin: 0px 0;
  padding: 32px 15px !important;
}
.list-item-shadow {
  box-shadow: #dbdada85 0px 0px 0px 1px;
  background-color: #fffefe;
  border-radius: 2px;
  margin: 0px;
  padding: 0px 2px;
}
.d-flex {
  display: inline-flex;
}
.item-content {
  margin-left: 10px;
  margin-top: 10px;
}
.text-bold {
  font-weight: bold;
}
.task-box {
  position: relative;
  border-radius: 12px;
  width: 100%;
  margin: 20px 0;
  padding: 16px;
  cursor: pointer;
  box-shadow: 2px 2px 4px 0px rgba(235, 235, 235, 1);
}
.tech_item {
  text-align: left;
  padding: 6px 12px;
  margin-top: 10px;
  margin-right: 16px;
  border-radius: 6px !important;
  border: none !important;
  cursor: move !important;
  font-size: 0.125rem !important;
  color: #29bada !important;
  font-weight: 500 !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
}
.time {
  margin-bottom: 6px;
  opacity: 0.4;
  font-size: 10px;
}
.badge-affected {
  background-color: #cff5ff;
  color: rgb(0, 121, 145);
  font-size: 12px;
  padding: 4px;
}
.description-task {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.task-name {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.6;
}

.color_events {
  margin-top: 20px;
}
.blue_box {
  background-color: #d3e6ff;
}
.rounded-checkbox input[type="checkbox"] {
  border-radius: 50%;
}

.rounded-checkbox .v-input--selection-controls__ripple {
  border-radius: 50%;
}
.left-items {
  margin-top: 0px;
}

/* Rounded Checkbox */
.rounded-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
}

.rounded-checkbox:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.rounded-checkbox::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-top: none;
  border-right: none;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.rounded-checkbox:hover::before {
  opacity: 1;
}

.rounded-checkbox:checked::before {
  opacity: 1;
}

.rounded-checkbox::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #dbdada;
}
.text-truncate-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New";
  font-size: 14px;
}
::v-deep .v-icon.notranslate.fas.fa-caret-down.theme--light {
  font-size: initial !important;
}
</style>
