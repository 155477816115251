<template>
  <div>
    <v-app-bar flat app style="background-color: #fff; border-bottom: 1px solid #ddd">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        "
      >
        <v-app-bar-nav-icon
          v-if="$store.getters.getUserToken"
          style="color: #18aea2"
          @click="drawer = !drawer"
        >
        </v-app-bar-nav-icon>
        <div style="display: flex; justify-content: space-between; align-items: center">
          <!-- <div class="dropdown-notif">
          
          <a id="dLabel" role="button" data-toggle="dropdown" class="dropdown-notif-btn">
            <i class="mdi mdi-bell-outline notif-icon"></i>
            <span class="notif-icon-badge">12</span>
          </a>
          
          <ul class="dropdown-menu notifications" role="menu" aria-labelledby="dLabel">
            <div class="notification-heading">
              <h6 class="menu-title">Notifications</h6>
            </div>
            <div class="notifications-wrapper">
              <a class="content" href="#">
                <div class="notification-item">
                  <h4 class="item-title">Evaluation Deadline 1 · day ago</h4>
                  <p class="item-info">Marketing 101, Video Assignment</p>
                </div>
              </a>
              <a class="content" href="#">
                <div class="notification-item">
                  <h4 class="item-title">Evaluation Deadline 1 · day ago</h4>
                  <p class="item-info">Marketing 101, Video Assignment</p>
                </div>
              </a>
              <a class="content" href="#">
                <div class="notification-item">
                  <h4 class="item-title">Evaluation Deadline 1 • day ago</h4>
                  <p class="item-info">Marketing 101, Video Assignment</p>
                </div>
              </a>
              <a class="content" href="#">
                <div class="notification-item">
                  <h4 class="item-title">Evaluation Deadline 1 • day ago</h4>
                  <p class="item-info">Marketing 101, Video Assignment</p>
                </div>

              </a>
              <a class="content" href="#">
                <div class="notification-item">
                  <h4 class="item-title">Evaluation Deadline 1 • day ago</h4>
                  <p class="item-info">Marketing 101, Video Assignment</p>
                </div>
              </a>
              <a class="content" href="#">
                <div class="notification-item">
                  <h4 class="item-title">Evaluation Deadline 1 • day ago</h4>
                  <p class="item-info">Marketing 101, Video Assignment</p>
                </div>
              </a>

            </div>
            <div class="notification-footer">
              <a href="/notifications">Voir Tous</a>
            </div>
          </ul>
          
        </div> -->

          <!-- user info button -->
          <div class="dropdown-user-info">
            <a
              id="user-info-dropdown"
              role="button"
              data-toggle="dropdown"
              class="dropdown-user-info-toggler"
            >
              <div style="display: flex; flex-direction: column">
                <span
                  style="font-size: 12px; font-weight: 600; text-align: right"
                  :title="user?.email"
                  >{{ user?.nom + " " + user?.prenom }}</span
                >
                <span style="font-size: 12px; font-weight: 400; text-align: right">{{
                  translateUserRole(roleUser)
                }}</span>
              </div>
              <div
                style="
                  width: 40px;
                  height: 40px;
                  background-color: #bbcfce;
                  border-radius: 50%;
                  margin-left: 12px;
                "
              >
                <img
                  :src="require(`@/assets/avatars/avatar.png`)"
                  style="width: 100%; height: 100%"
                />
              </div>
            </a>
            <ul
              class="dropdown-menu user-info"
              role="menu"
              aria-labelledby="user-info-dropdown"
            >
              <div class="user-info-heading">
                <div class="info-wrapper">
                  <div class="info-circle">
                    <img
                      :src="require(`@/assets/avatars/avatar.png`)"
                      style="width: 100%; height: 100%"
                    />
                  </div>
                  <div class="info-content">
                    <p class="username">{{ user?.nom + " " + user?.prenom }}</p>
                    <p class="email">{{ user?.email }}</p>
                  </div>
                </div>
                <p class="role">{{ translateUserRole(roleUser) }}</p>
              </div>
              <div class="devider"></div>
              <div class="user-info-wrapper">
                <!-- <div class="user-info-item">
                <i class="mdi mdi-account-outline user-info-item-icon"></i>
                <p class="user-info-item-title">Account</p>
              </div>
              <div class="user-info-item">
                <i class="mdi mdi-bell-outline user-info-item-icon"></i>
                <p class="user-info-item-title">Notifications</p>
              </div> -->
                <div class="user-info-item" @click="logout">
                  <i class="mdi mdi-power user-info-item-icon"></i>
                  <p class="user-info-item-title">Déconnexion</p>
                </div>
                <div class="devider"></div>
                <a class="user-info-item" :href="appUrl" title="Télécharger APK">
                  <!-- <i class="mdi mdi-download user-info-item-icon"></i> -->
                  <div
                    style="
                      width: 24px;
                      height: 24px;
                      margin-right: 10px;
                      margin-top: 8px;
                      margin-bottom: 8px;
                    "
                  >
                    <img
                      :src="require(`@/assets/images/android.png`)"
                      style="width: 100%; height: 100%"
                    />
                  </div>
                  <p class="user-info-item-title">Android Application</p>
                </a>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" flat app>
      <router-link to="/" class="d-flex flex-row justify-center pa-4">
        <v-img
          alt="Gaz-Elec-MoinCher Logo"
          class="shrink ma-2 text-center"
          contain
          src="../assets/logo/ENEFFIC-logo.png"
          transition="scale-transition"
          width="150"
        />
      </router-link>
      <v-list nav dense class="pa-0 mt-5">
        <v-list-item-group class="px-0">
          <v-list-item class="pa-0" v-for="(route, index) in filteredRoutes" :key="index">
            <!-- side bar item without sub routes -->
            <v-col class="pa-0 hover" v-if="!route.children">
              <router-link :to="`${route.path}`" class="d-flex flex-row link">
                <v-list-item-icon class="pl-2">
                  <v-icon size="20" class="link-icon">{{ route?.meta.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="link-text">{{
                  (route?.name == "List Devis") & (roleUser == "Prospecteur")
                    ? "Mes Devis"
                    : route?.name
                }}</v-list-item-title>
              </router-link>
            </v-col>
            <!-- side bar item with sub routes -->
            <div style="width: 100%" v-else>
              <v-list-group id="list-children" style="width: 100%">
                <template v-slot:activator>
                  <v-list-item-icon class="pl-2">
                    <v-icon size="20" class="link-icon">{{ route?.meta.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="Poppins link-text">{{
                    route?.name
                  }}</v-list-item-title>
                </template>
                <v-list-item
                  class="d-flex flex-row hover"
                  style="
                    margin: 0px !important;
                    border-radius: 0;
                    border-left: 3px solid #007991;
                    margin-left: 15px !important;
                    width: 100%;
                  "
                  v-for="(subroute, index) in filteredChildrenRoutes(route.children)"
                  :key="index"
                >
                  <router-link
                    :to="`${route.path}/${subroute.path}`"
                    style="width: 100%"
                    class="d-flex flex-row py-0 link"
                  >
                    <v-list-item-icon class="pl-2 mr-2">
                      <v-icon size="20" class="link-icon">{{
                        subroute?.meta.icon
                      }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="link-text">
                        {{ subroute?.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </router-link>
                </v-list-item>
              </v-list-group>
            </div>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <!-- download and logout btns -->
      <!-- <template v-slot:append>
        <div class="mt-10">
          <div class="download-app">
            <a class="btn btn-google" :href="appUrl" title="Télécharger APK">Télécharger APK</a>
          </div>
          <div class="d-flex flex-row justify-center" :style="{ background: $styles.background }">
            <v-btn plain class="white--text" elevation="0" depressed @click="logout">
              <span class="Poppins-Bold">Déconnexion</span>
              <v-icon size="20" class="mx-2">mdi-logout-variant</v-icon>
            </v-btn>
          </div>
        </div>
      </template> -->
    </v-navigation-drawer>
  </div>
</template>
<script>
import axios from "@/plugins/axios";

export default {
  name: "app-bar",
  components: {},
  data() {
    return {
      drawer: true,
      appUrl: "",
      _user: null,
    };
  },
  async mounted() {
    await this.downloadApk();
  },
  computed: {
    user() {
      const compte = this.$store.getters.getCompte;
      return JSON.parse(compte);
    },
    roleUser() {
      const roles = this.$store.getters.getUserRole;
      return roles;
    },
    translateUserRole: function () {
      return (role) => {
        const COMPTE_TYPE_STR = {
          superadmin: "Super administrateur",
          admin: "Administrateur",
          ADV: "Administrateur de vente",
          "Chef équipe": "Chef d'équipe",
          chefIngenieur: "Chef ingénieur",
          Prospecteur: "Prospecteur",
          ingenieur: "Ingénieur",
          technicien: "Technicien",
        };
        return COMPTE_TYPE_STR[role];
      };
    },
    filteredRoutes() {
      console.log(this.roleUser);
      const filtered = this.$router.options.routes.filter(
        (route) =>
          route?.meta?.roles?.includes(this.roleUser) && route?.meta?.isLeftMenuItem
      );
      return filtered;
    },
  },
  methods: {
    navigateTo(routePath) {
      const currentRoutePath = this.$router.currentRoute.path;
      if (currentRoutePath !== routePath) {
        this.$router.push(routePath);
      }
    },
    filteredChildrenRoutes(ArrayChildren) {
      const filtered = ArrayChildren.filter((route) => {
        if (!route.path.includes(":")) {
          return (
            route?.meta?.roles?.includes(this.roleUser) && route?.meta?.isLeftMenuItem
          );
        }
      });
      return filtered;
    },
    async logout() {
      this.$cookies.remove("token");
      this.$store.dispatch("logout");
    },
    async downloadApk() {
      try {
        const res = await axios.get(process.env.VUE_APP_URL_API_CLIENT + "getAppUrl/");
        if (res?.status == 200 && res?.data) {
          this.appUrl = res.data.app_url;
        }
      } catch (error) {
        
      }
    },
  },
};
</script>
<style scoped>
.link {
  text-decoration: none;
}
.hover {
  --c: #007991; /* the color */

  background: linear-gradient(90deg, #fff 50%, var(--c) 0) calc(100% - var(--_p, 0%)) /
      200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) 100% no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: 0.5s;
}
.hover:hover {
  --_p: 100%;
}

.link-icon,
.link-text {
  color: #007991 !important;
}
.hover:hover .link-text,
.hover:hover .link-icon {
  color: #fff !important;
}

::v-deep .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  padding: 0px;
}
::v-deep .theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0;
}

::v-deep
  .v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  justify-content: initial !important;
}

/* button playstore */
.download-app {
  text-align: center;
}
.download-app a {
  color: #222 !important;
}
.download-app a.btn-google {
  color: #fff;
}
.download-app .btn {
  padding: 10px 16px;
  margin: 8px;
  font-size: 14px;
  line-height: 1.3333333;
  border-radius: 50px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  outline: 0;
}
.download-app .btn:active:focus,
.btn:focus {
  outline: 0;
}
.download-app .btn:focus,
.btn:hover {
  text-decoration: none;
  outline: 0;
}
.download-app .btn:active {
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.download-app .btn-google {
  color: #fff;
  background-color: #ffffff;
  border-color: #047e4f;
  padding: 12px 16px 12px 40px;
  position: relative;
  font-weight: 600;
}
.download-app .btn-google:focus {
  color: #fff;
  background-color: #f5f5f5;
}
.download-app .btn-google:active,
.btn-google:hover {
  color: #fff;
  background-color: #f0f0f0;
}
.download-app .btn-google:before {
  content: "";
  background-image: url("../assets/images/android.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 6px;
  top: 50%;
  margin-top: -15px;
}

.user-circle {
  width: 40px;
  height: 40px;
  background-color: #bbcfce;
  border-radius: 50%;
  margin-left: 12px;
}

/****************************** */
/* user info dropdown */
/****************************** */
.dropdown-user-info {
  display: inline-block;
  padding: 8px;
}

.dropdown-user-info-toggler {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: #000;
  user-select: none;
}
.user-info-heading {
  display: flex;
  flex-direction: column;
  padding: 16px;
}
.info-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.info-circle {
  width: 35px;
  height: 35px;
  background-color: #bbcfce;
  border-radius: 50%;
}
.info-content {
  margin-left: 10px;
}
.info-content .username {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  margin: 0px;
}
.info-content .email {
  font-size: 12px;
  font-weight: 400;
  color: #686868;
  margin: 0px;
}
.role {
  font-size: 14px;
  font-weight: 400;
  color: #686868;
  margin: 0px;
}
.user-info {
  padding: 0px;
  width: 250px;
}
.user-info-wrapper {
  padding: 0px;
}
.user-info-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 16px;
  text-decoration: none;
  cursor: pointer;
}
.user-info-item:hover {
  background-color: #e6e6e6;
}
.user-info-item-title {
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  text-transform: capitalize;
  color: #000;
  margin-bottom: 0px;
}
.user-info-item-icon {
  font-size: 24px;
  color: #000000;
  margin-right: 10px;
}
.devider {
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
}
/****************************** */
/* notifications */
/****************************** */

.dropdown-notif {
  display: inline-block;
  margin-right: 20px;
  padding: 8px;
}

.dropdown-notif-btn {
  position: relative;
  padding: 8px;
}

.notif-icon {
  font-size: 28px;
}

.notif-icon-badge {
  font-family: work-Sans, sans-serif;
  font-size: 11px;
  font-weight: 700;
  background: #f73164;
  color: #fff;
  padding: 2px 4px;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  top: -3px;
}
.notifications {
  padding: 0px;
  width: 375px;
}

.notification-heading {
  display: inline-block;
  border-bottom: 1px solid hsla(256, 5%, 58%, 0.3);
  padding: 16px;
  width: 100%;
}
.notification-heading .menu-title {
  color: #12817c;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}

.notification-footer {
  border-top: 1px solid hsla(256, 5%, 58%, 0.3);
  padding: 10px;
  text-align: center;
  width: 100%;
}

.notification-footer a {
  font-size: 14px;
  text-align: center;
  color: #12817c !important;
  text-decoration: none;
  margin-bottom: 0px;
}

.notifications-wrapper {
  overflow: auto;
  max-height: 250px;
  background: #fafafa;
}

/* width */
.notifications-wrapper::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.notifications-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.notifications-wrapper::-webkit-scrollbar-thumb {
  background: #03af9e;
  border-radius: 10px;
}

/* Handle on hover */
.notifications-wrapper::-webkit-scrollbar-thumb:hover {
  background: #014d46;
}

.notifications a.content {
  text-decoration: none;
}

.notification-item {
  padding: 16px;
  background: #ffffff;
  border-bottom: 1px solid hsla(256, 5%, 58%, 0.3);
}

.item-title {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  letter-spacing: 0.3px;
  margin-bottom: 0;
}

.item-info {
  font-size: 12px;
  font-weight: 400;
  color: #a0a0a0;
  letter-spacing: 0.3px;
  margin-bottom: 0;
}
</style>
