/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import * as DOMPurify from "dompurify";
import VueCookies from "vue-cookies-reactive";
import vuetify from "./plugins/vuetify";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import router from "./router/index.js";
import cookie from "@/services/cookie";
import store from "@/store/index";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
const options = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

dayjs.extend(utc);
dayjs.extend(timezone);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueCookies);
Vue.use(Toast, options);
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
Vue.$router = router;
Vue.prototype.$store = store;
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$DOMPurify = DOMPurify;
Vue.prototype.$colors = [
  "#007991",
  "#002542",
  "#FDFEFE",
  "#01f6bb",
  "#00e676",
  "#FBC02D",
  "#000000",
];
Vue.prototype.$styles = {
  background: "#007991 linear-gradient(45deg, #007991 0%, #18aea2 68%)",
};
Vue.prototype.$cookies = VueCookies;
Vue.prototype.$cookieService = cookie;
Vue.prototype.$userRole = "";

Vue.prototype.$rules = {
  isCompteurSolar: (value) => {
    // Split the input into individual pairs
    const pairs = value.split(";");

    // Check each pair
    for (const pair of pairs) {
      // Split the pair into counter number and type
      const [counterNumber, type] = pair.split(",");

      // Your validation logic for counter number and type
      // Example: Check if the counter number has 14 digits and the type is either 'auto', 'consommation', or 'production'
      if (
        !/^\d{14}$/.test(counterNumber) ||
        (type != "autoCollective" &&
          type != "autoCollective&Individuelle" &&
          type != "consommation" &&
          type != "production")
      ) {
        return "PDL number contain 14 digits and the type is either 'autoCollective', 'autoCollective&Individuelle', 'consommation', or 'production' (pdl1,auto;pdl2,production)";
      }
    }

    // All pairs are valid
    return true;
  },
  isSanitize: (value) =>
    !/(\b)(on\S+)(\s*)=|javascript|<(|\/|[^\/>][^>]+|\/[^>][^>]+)>/.test(
      value
    ) || "Caractères invalides",
  isEmail: (value) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) ||
    "email invalide",
  required(fieldName) {
    return (value) =>
      !!value || value?.length > 0 || `Veuillez saisir ${fieldName}`;
  },
  requiredNumber(fieldName) {
    // should be digit float or integer or zero
    return (value) => {
      const isNumber = /^-?\d+(\.\d+)?$/.test(value);
      return isNumber || value === 0 || `Veuillez saisir un ${fieldName} valide`;
    }
  },
  requiredItem(fieldName) {
    return (value) =>
      ("" + value).length > 0 ||
      (("" + value).length > 0 && value >= 0) ||
      `Veuillez saisir ${fieldName}`;
  },
  siretLength: (v) => {
    if (v != null) {
      if (v.length == 14) {
        return true;
      } else {
        return "Le Siret est une suite de 14 chiffres";
      }
    } else {
      return "Le Siret est une suite de 14 chiffres";
    }
  },
  compteurElecLength: (v) => {
    if (v != null) {
      if (v.length == 14) {
        return true;
      } else {
        return "Le RAE / PDL est une suite de 14 chiffres";
      }
    } else {
      return "Le RAE / PDL est une suite de 14 chiffres";
    }
  },
  compteurSolarType: (v) => {
    if (v != null) {
      if (v == "autoCollective" || v == "autoCollective&Individuelle" || v == "consommation" || v == "production") {
        return true;
      } else {
        return "Type de compteur invalide";
      }
    } else {
      return "Type de compteur invalide";
    }
  },
  rangeValideValue: (v, minValue, maxValue) =>
    (v >= minValue && v <= maxValue) ||
    `ce champ doit etre compris entre ${minValue} et ${maxValue}`,
  dateRules: (value) =>
    /^\d{4}-\d{2}-\d{2}$/.test(value) || "Format de date invalide",
  timeRule: (value) => /^\d{2}:\d{2}$/.test(value) || "Format d'heure invalide",
  isNumber: (v) =>
    /^[0-9]+$/.test(v) || "Ce champ n'accepte que des nombres entiers",
  isFloat: (v) =>
    /^-?[0-9]+(\.[0-9]+)?$/.test(v) || "Ce champ n'accepte que des nombres",
  replaceDotForZero: (value) => value.replace(/^\./, "0."),
  greaterThanZero(fieldName) {
    return (value) => value > 0 || `${fieldName} doit etre supérieur à zéro !`;
  },
  onlySpace: (value) =>
    value.replace(/\s/g, "").length > 0 || "Ce champs doit avoir du texte !",
  hasPostalCode: (value) =>
    /\b\d{5}(?:[-\s]\d{4})?\b/.test(value) ||
    "Code postal manquant ou incorrect",
  formatEmail: (value) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) ||
    "email invalide",
  injection_sql: (value) =>
    !/(;|'|"|\/\*|\*\/|SELECT|INSERT|UPDATE|DELETE|UNION|EXEC|CREATE|DROP|ALTER|TRUNCATE|RENAME|GRANT|REVOKE|--)/.test(
      value?.toUpperCase()
    ) || "contenu incorrect",
  formatPassword: (value) =>
    /^(?=.*\d)(?=.*[A-Z])(?=.*[^\w\s]).{12,}$/.test(value) ||
    "mot de passe trop faible",
};
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
